.ProfileTherapist {
  height: 100%;
  color: #9097a2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  a {
    text-decoration: none !important;
  }

  p {
    margin: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__innerDiv {
    height: 75vh !important;
    margin-bottom: 5%;

    .parentDiv {
      height: 100%;
      overflow-y: auto;

      .accountActivate {
        font-size: 20px;

        text-transform: uppercase;
        font-family: "L-Regular";
        padding: 10px 0 10px 0;
        border: none;
        outline: none;
        background: transparent;
        transition: all 0.3s ease-out;

        &:hover {
          letter-spacing: 1.1px;
        }
      }

      .approveBtn {
        padding: 3px;
      }

      .approveProfile {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 5px 0;
      }

      &::-webkit-scrollbar {
        display: none !important;
      }
    }

    .therapistMainTitle {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
    }

    .mainTitle {
      margin: 0;
      font-size: 21px;
      font-family: "L-Regular";
      background: #62bfae;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 30px;
      height: 50px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    .backToSearch {
      float: right;
      cursor: pointer;
      text-transform: capitalize;
      margin-right: 10px;
    }

    .basicInfo {
      margin-bottom: 15px;

      &__content {
        padding: 3%;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .firstRow {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #b5bfd0;
          padding-bottom: 2%;

          &__colOne {
            width: 70%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .profileDiv {
              display: flex;
              align-items: center;

              .therapistProfile {
                width: 80px;
                height: 80px;
                border: 1px solid #62bfae;
                border-radius: 50%;
                padding: 2px;

                img {
                  width: 100%;
                  height: 100%;
                  object-position: center;
                  object-fit: cover;
                }
              }

              .nameDiv {
                padding-left: 20px;

                .nameText {
                  font-size: 30px;
                  font-family: "L-Regular";
                  line-height: 110%;
                  text-transform: capitalize;
                }

                .jobTitle {
                  font-size: 19px;
                  font-family: "L-Regular";
                }
              }
            }

            .addressDiv {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .addressText {
                font-size: 19px;
                font-family: "L-Regular";
                text-transform: capitalize;
                display: flex;
                align-items: center;

                svg {
                  margin-left: 10px;
                }
              }
            }
          }

          &__colTwo {
            width: 25%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            justify-content: flex-start;

            .bookSessionLink {
              width: 250px;
              height: 50px;
              background: white;
              border: 1.5px solid #62bfae;
              margin-bottom: 10px;
              color: #62bfae;
              display: flex;
              justify-content: center;
              align-items: center;
              text-transform: uppercase;
              font-size: 20px;
              font-family: "L-Regular";
              border-radius: 0.35vh;
              transition: all 0.2s linear;

              &:hover {
                background: #62bfae;
                color: white;
                letter-spacing: 1.3px;
              }
            }

            .sessionMessage {
              width: 250px;
              height: 50px;
              background: white;
              border: 1.5px solid #62bfae;
              outline: none;
              color: #62bfae;
              text-transform: uppercase;
              font-size: 20px;
              font-family: "L-Regular";
              border-radius: 0.35vh;
              transition: all 0.2s linear;

              &:hover {
                background: #62bfae;
                color: white;
                letter-spacing: 1.3px;
              }
            }
          }
        }

        .secondRow {
          padding: 10px 0;

          &__header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .socialLinks {
              display: flex;
              width: 20%;
              justify-content: flex-end;
              align-items: center;

              .socialLinkItem {
                margin-left: 15px;
                .socialLinkDiv {
                  width: 28px;
                  height: 28px;
                  background: #62bfae;
                  border-radius: 50%;
                  position: relative;

                  .socialIcon {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                  }
                }
              }
            }

            .BioText {
              font-size: 19px;
              font-family: "L-Regular";
              color: #62bfae;
              margin: 0;
              text-transform: uppercase;
            }
          }

          &__body {
            padding: 15px;
            .bio {
              font-size: 15px;
              font-family: "L-Regular";
              white-space: pre-line;
            }
          }
        }
      }
    }

    .skills {
      margin-bottom: 15px;
      .changeBg {
        background: #f3ab8e;
      }

      &__content {
        padding: 3%;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .skillsDiv {
          border-bottom: 1px solid #b5bfd0;
          padding-bottom: 3%;

          .skillsTitle {
            font-size: 19px;
            font-family: "L-Regular";
            color: #f3ab8e;
            text-transform: uppercase;
            margin: 0;
          }

          .skillsDataDiv {
            padding: 15px;
            display: grid;
            grid-template-columns: auto auto;
            grid-column-gap: 20px;
            align-items: center;
            flex-wrap: wrap;

            .skillsData {
              font-size: 19px;
              font-family: "L-Regular";
            }
          }
        }

        .qualificationsDiv {
          padding-top: 3%;

          .qualificationsData {
            padding: 15px;
            font-size: 16px;
            font-family: "L-Regular";
            white-space: pre-line;
          }
        }

        .membershipData {
          font-size: 19px;
          font-family: "L-Regular";
          padding: 15px;
        }

        .gridColumn {
          display: grid;
          grid-column-gap: 20px;
          grid-template-columns: auto auto;
        }

        .therapyOffered {
          padding-top: 3%;
        }
      }
    }

    .preferences {
      .changeBgBlue {
        background: #548df7;
      }

      &__content {
        padding: 3% 3% 5% 3%;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .sessionTypes {
          border-bottom: 1px solid #b5bfd0;
          padding-bottom: 2%;

          .sessionTypesTitle {
            font-size: 19px;
            margin: 0;
            font-family: "L-Regular";
            color: #548df7;
            text-transform: uppercase;
          }

          .sessionDataDiv {
            padding: 15px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .sessionData {
              font-size: 19px;
              font-family: "L-Regular";
              margin-right: 5%;
              margin-bottom: 10px;

              .individualSessFee {
                font-size: 16px;
                padding-left: 50px;
              }
            }
          }

          .availDataDiv {
            flex-direction: column;
            align-items: flex-start;
          }

          .rowFlex {
            display: flex;
            width: 50%;
            justify-content: space-between;
            align-items: flex-start;
          }

          .weekDaysLabel {
            font-size: 19px;
            font-family: "L-Regular";
          }

          .selectedTimesDiv {
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            justify-content: space-between;

            .selectedTimes {
              margin: 0;
              color: #9097a2;
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: flex-start;
              text-transform: uppercase;

              :first-child {
                margin-right: 10px;
                border: 1px solid #9097a2;
                width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                font-size: 13px;
                border-radius: 3px;
                background: rgb(255, 255, 255);
              }

              :nth-child(2) {
                margin-right: 10px;
                border: 1px solid #9097a2;
                width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                font-size: 13px;
                border-radius: 3px;
                background: rgb(255, 255, 255);
              }
            }
          }
        }

        .clientServed {
          padding-top: 2%;
        }

        .availability {
          padding-top: 2%;
        }

        .footerBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10% 0;

          .bookSessionLink {
            width: 250px;
            height: 50px;
            background: #548df7;
            color: white;
            font-size: 20px;
            font-family: "L-Regular";
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5%;
            border-radius: 0.35vh;
            text-transform: uppercase;
            transition: all 0.2s linear;

            &:hover {
              letter-spacing: 1.3px;
              background: white;
              color: #548df7;
              border: 1.5px solid #548df7;
            }
          }

          .sessionMessage {
            width: 250px;
            height: 50px;
            background: #548df7;
            color: white;
            font-size: 20px;
            font-family: "L-Regular";
            outline: none;
            border: none;
            margin: 0 5%;
            border-radius: 0.35vh;
            text-transform: uppercase;
            transition: all 0.2s linear;

            &:hover {
              letter-spacing: 1.3px;
              background: white;
              color: #548df7;
              border: 1.5px solid #548df7;
            }
          }
        }
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .ProfileTherapist {
    &__innerDiv {
      .parentDiv {
        .accountActivate {
          font-size: 16px;
        }
      }

      .mainTitle {
        font-size: 18px;

        .backToSearch {
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .basicInfo {
        &__content {
          .firstRow {
            &__colOne {
              .profileDiv {
                .nameDiv {
                  .nameText {
                    font-size: 24px;
                    line-height: 100%;
                  }

                  .jobTitle {
                    font-size: 14px;
                  }
                }
              }

              .addressDiv {
                .addressText {
                  font-size: 14px;
                }
              }
            }

            &__colTwo {
              .bookSessionLink {
                width: 180px;
                height: 40px;
                font-size: 16px;
              }

              .sessionMessage {
                width: 180px;
                height: 40px;
                font-size: 16px;
              }
            }
          }

          .secondRow {
            &__header {
              .BioText {
                font-size: 16px;
              }
            }
          }
        }
      }

      .skills {
        &__content {
          .skillsDiv {
            .skillsTitle {
              font-size: 16px;
            }

            .skillsDataDiv {
              .skillsData {
                font-size: 15px;
              }
            }
          }

          .qualificationsDiv {
            .qualificationsData {
              font-size: 15px;
            }
          }

          .membershipData {
            font-size: 15px;
          }
        }
      }

      .preferences {
        &__content {
          .sessionTypes {
            .sessionTypesTitle {
              font-size: 16px;
            }

            .sessionDataDiv {
              .sessionData {
                font-size: 15px;
              }
            }
          }

          .clientServed {
            padding-top: 2%;
          }

          .availability {
            padding-top: 2%;

            .weekDaysLabel {
              font-size: 15px;
            }
          }

          .footerBtn {
            .bookSessionLink {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }

            .sessionMessage {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .ProfileTherapist {
    height: 100%;
    justify-content: center;

    &__innerDiv {
      min-height: 65vh !important;
      height: 65vh !important;
      margin-bottom: 7%;

      .mainTitle {
        font-size: 18px;
      }

      .basicInfo {
        &__content {
          .firstRow {
            &__colOne {
              flex-direction: column;
              .profileDiv {
                .nameDiv {
                  .nameText {
                    font-size: 24px;
                    line-height: 100%;
                  }

                  .jobTitle {
                    font-size: 14px;
                  }
                }
              }

              .addressDiv {
                margin-left: 80px;
                .addressText {
                  font-size: 14px;
                }
              }
            }

            &__colTwo {
              .bookSessionLink {
                width: 180px;
                height: 40px;
                font-size: 16px;

                &:hover {
                  letter-spacing: 0px;
                }
              }

              .sessionMessage {
                width: 180px;
                height: 40px;
                font-size: 16px;
              }
            }
          }

          .secondRow {
            &__header {
              .BioText {
                font-size: 16px;
              }
            }

            &__body {
              padding: 15px;
            }
          }
        }
      }

      .skills {
        &__content {
          .skillsDiv {
            .skillsTitle {
              font-size: 16px;
            }

            .skillsDataDiv {
              .skillsData {
                font-size: 15px;
              }
            }
          }

          .qualificationsDiv {
            .qualificationsData {
              font-size: 15px;
            }
          }

          .membershipData {
            font-size: 15px;
          }
        }
      }

      .preferences {
        &__content {
          .sessionTypes {
            .sessionTypesTitle {
              font-size: 16px;
            }

            .sessionDataDiv {
              .sessionData {
                font-size: 15px;
              }
            }
          }

          .clientServed {
            padding-top: 2%;
          }

          .availability {
            padding-top: 2%;
          }

          .footerBtn {
            .bookSessionLink {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }

            .sessionMessage {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .ProfileTherapist {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;

    &__innerDiv {
      .parentDiv {
        height: auto;
        padding-bottom: 130px !important;
      }

      .mainTitle {
        font-size: 17px;
        padding: 0 10px;
      }

      .backToSearch {
        float: right;
        font-size: 12px;
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
          text-decoration: underline;
        }
      }

      .basicInfo {
        &__content {
          .firstRow {
            flex-direction: column;
            padding-bottom: 20px;

            &__colOne {
              width: 100%;
              flex-direction: column;

              .profileDiv {
                width: 100%;
                flex-direction: column;
                justify-content: center;
                text-align: center;

                .nameDiv {
                  padding: 0;
                  margin-top: 10px;
                  .nameText {
                    font-size: 24px;
                    line-height: 100%;
                  }

                  .jobTitle {
                    font-size: 14px;
                  }
                }
              }

              .addressDiv {
                display: flex;
                justify-content: center;
                width: 100%;
                padding-bottom: 10px;

                .addressText {
                  font-size: 14px;
                  text-transform: capitalize;
                  text-align: center;
                  line-height: 120%;
                  margin-top: 5px;
                }
              }
            }

            &__colTwo {
              width: 100%;
              padding-top: 10px;
              align-items: center;
              justify-content: space-evenly;
              flex-direction: row;

              .bookSessionLink {
                width: 130px;
                height: 35px;
                font-size: 14px;
                margin-bottom: 0;
                border: 1px solid #62bfae;
              }

              .sessionMessage {
                width: 130px;
                height: 35px;
                font-size: 14px;
                border: 1px solid #62bfae;
              }
            }
          }

          .secondRow {
            &__header {
              .socialLinks {
                .socialLinkItem {
                  margin-left: 10px;
                  img {
                    width: 40px;
                  }
                }
              }

              .BioText {
                font-size: 14px;
              }
            }

            &__body {
              padding: 10px;

              .bio {
                font-size: 13px;
              }
            }
          }
        }
      }

      .skills {
        &__content {
          .skillsDiv {
            .skillsTitle {
              font-size: 14px;
            }

            .skillsDataDiv {
              padding: 10px;
              grid-template-columns: auto;
              .skillsData {
                font-size: 13px;
              }
            }
          }

          .qualificationsDiv {
            .qualificationsData {
              padding: 10px;
              font-size: 13px;
            }
          }

          .membershipData {
            padding: 10px;
            font-size: 13px;
          }

          .gridColumn {
            grid-template-columns: auto;
          }
        }
      }

      .preferences {
        padding-bottom: 10px;
        &__content {
          .sessionTypes {
            .sessionTypesTitle {
              font-size: 14px;
            }

            .sessionDataDiv {
              padding: 10px;
              .sessionData {
                font-size: 13px;
              }
            }

            .rowFlex {
              flex-direction: column;

              .weekDaysLabel {
                font-size: 13px;
              }
            }
          }

          .clientServed {
            padding-top: 2%;
          }

          .availability {
            padding-top: 2%;
          }

          .footerBtn {
            justify-content: space-evenly;
            .bookSessionLink {
              width: 130px;
              height: 35px;
              font-size: 14px;
              margin: 0;
            }

            .sessionMessage {
              width: 130px;
              height: 35px;
              font-size: 14px;
              margin: 0;
            }
          }
        }
      }
    }

    &__footer {
      display: none;
    }
  }
}
