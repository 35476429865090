.reports {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__innerDiv {
    height: 75vh;
    margin-bottom: 5%;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .reports {
    height: 100%;
    justify-content: center;

    &__innerDiv {
      height: 70vh;
    }

    &__footer {
      bottom: 3%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .reports {
    height: auto;
    justify-content: center;
    padding-bottom: 50px;

    &__innerDiv {
      height: auto !important;
    }

    &__footer {
      display: none;
    }
  }
}
