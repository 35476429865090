.verifyEmailLogo {
  height: 80px;
  padding: 25px;
}

.verifyEmail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 80px);
  flex-direction: column;
  padding-bottom: 3%;

  .verifyEmailTop {
    padding-top: 10%;

    .verifyImgDiv {
      padding: 6% 0%;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }

    .verifyEmailText {
      font-size: 40px;
      margin-bottom: 30px;
      text-align: center;
      padding: 2% 0;
      font-family: "L-Regular";
    }

    .errorMsg {
      color: #fff !important;
      padding: 10px 10px !important;
      background: #ff5f5f !important;
      border-radius: 0.35vh;
      font-size: 18px;
      font-family: "L-Medium";
    }

    .successMsg {
      color: #fff !important;
      padding: 10px 10px !important;
      background: #62bfae !important;
      border-radius: 0.35vh;
      font-size: 18px;
      font-family: "L-Medium";
    }

    .verifyEmailBtn {
      background: #62bfae;
      outline: none;
      border: none;
      color: white;
      margin: 5% auto;
      padding: 15px;
      width: 100%;
      border-radius: 0.35vh;
      font-size: 20px;
      font-family: "L-Regular";
    }

    .goHomeBtn {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #62bfae;
      outline: none;
      border: none;
      color: white;
      margin: 5% auto;
      border-radius: 0.35vh;
      font-size: 18px;
      font-family: "L-Regular";

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .verifyEmail {
    .verifyEmailTop {
      padding-top: 10%;

      .verifyImgDiv {
        img {
          width: 200px;
          object-fit: cover;
          object-position: center;
        }
      }

      .verifyEmailText {
        font-size: 28px;
      }

      .verifyEmailBtn {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .verifyEmail {
    .verifyEmailTop {
      padding-top: 15%;

      .verifyImgDiv {
        img {
          width: 200px;
          object-fit: cover;
          object-position: center;
        }
      }

      .verifyEmailText {
        font-size: 28px;
      }

      .verifyEmailBtn {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .verifyEmail {
    padding: 5% 6%;
    .verifyEmailTop {
      padding-top: 10%;

      .verifyImgDiv {
        img {
          width: 200px;
          object-fit: cover;
          object-position: center;
        }
      }

      .verifyEmailText {
        font-size: 28px;
        margin-bottom: 20px;
      }

      .verifyEmailBtn {
        padding: 10px;
        font-size: 16px;
      }
    }
  }
}
