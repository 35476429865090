// Header Tab

.topFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topTabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  width: 100%;
  height: 70px;

  .navPills {
    .navPillsList {
      margin: 0 20px;
      color: white;

      .navLink {
        color: white;
        font-size: 20px;
        font-family: "L-Regular";
        text-transform: uppercase;
        padding: 10px 5px;

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}

.qna {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__innerDiv {
    margin-bottom: 5%;
    height: 75vh;

    .selectDates {
      padding: 2% 0 0 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .to {
        margin: 0;
      }

      .GetReports {
        border: none;
        outline: none;
        background: #62bfae;
        color: white;
        font-size: 16px;
        font-family: "L-Regular";
        text-transform: uppercase;
        height: 40px;
        border: 1px solid transparent;
        border-radius: 0.35vh;
        margin: 0 10px;
        transition: all 0.2s linear;

        &:hover {
          background: white;
          color: #62bfae;
        }
      }

      .firstCalender,
      .secondCalender {
        background: rgb(246, 243, 243);
        line-height: 1.7rem;
        font-size: 12px;
      }

      .firstInput,
      .secondInput {
        margin: 0 25px;
        border: 1px solid #62bfae;
        height: 40px;
        padding: 0 10px;
        outline: none;
      }
    }

    .contentDiv {
      height: 70vh;
      margin-top: 20px;

      .contentBody {
        background: white;
        border-radius: 23px;
        overflow: hidden;
        // padding-bottom: 4%;
      }
    }
  }

  .footerLink {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// Popup

.popupDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .closePopup {
    margin-bottom: 15px;
    cursor: pointer;
  }

  .innerDiv {
    width: 976px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2%;

    .mainDiv {
      width: 100%;
      height: 100%;
      position: relative;

      .topBorder {
        width: 140px;
        margin: 5px 0 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5px;

        &__left {
          width: 50%;
          height: 5px;
        }

        &__right {
          width: 50%;
          height: 5px;
        }
      }

      &__question {
        font-size: 40px;
        text-align: left;
        line-height: 120%;
        font-family: "L-Bold";
        margin: 10px 0;
      }

      .clientDetails {
        margin: 5% 0 3% 0;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #62bfae;

        .clientName {
          font-size: 21px;
          font-family: "L-Regular";
          color: #62bfae;
          text-transform: capitalize;
          margin: 0;
          border-left: 2px solid #62bfae;
          line-height: 80%;
          display: flex;
          align-items: center;
          padding: 0 10px;

          &:first-child {
            border-left: none;
            padding-left: 0;
          }
        }
      }

      &__textArea {
        textarea {
          width: 100%;
          padding: 10px;
          min-height: 270px;
          max-height: 500px;
          border: none;
          font-size: 24px;
          font-family: "L-Regular";
          line-height: 130%;
          color: #919191;
          outline: none;
          background: #eaeef5;
        }
      }

      &__btnDiv {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 20px 0 0 0;

        .submitBtn {
          width: 143px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          background: #62bfae;
          outline: none;
          border: none;
          text-transform: uppercase;
          border-radius: 1.5px;
          font-size: 20px;
          font-family: "L-Regular";
          transition: all 0.2s linear;

          &:hover {
            letter-spacing: 1.2px;
          }

          svg {
            margin-left: 8px;
            width: 16px;
          }
        }

        .cancelBtn {
          background: #9097a2;
          margin-right: 20px;
        }
      }
      .loaderOverlay {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .loader {
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &__spin {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          border: 3px solid;
          border-color: #62bfae #62bfae transparent transparent;
          animation: rotation 1s linear infinite;

          &:after,
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border: 3px solid;
            border-color: transparent transparent #f3ab8e #f3ab8e;
            width: 42px - 8px;
            height: 42px - 8px;
            border-radius: 50%;
            animation: rotationBack 0.5s linear infinite;
            transform-origin: center center;
          }
          &:before {
            width: 42px - 16px;
            height: 42px - 16px;
            border-color: #fff #fff transparent transparent;
            animation: rotation 1.5s linear infinite;
          }
          @keyframes rotationBack {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(-360deg);
            }
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .topTabs {
    height: 50px;

    .navPills {
      .navPillsList {
        .navLink {
          font-size: 14px;
        }
      }
    }
  }

  // Popup

  .popupDiv {
    .closePopup {
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .innerDiv {
      width: 750px;

      .mainDiv {
        &__question {
          font-size: 24px;
        }

        .clientDetails {
          .clientName {
            font-size: 16px;
            padding: 0 10px;
          }
        }

        &__textArea {
          textarea {
            font-size: 18px;
          }
        }

        &__btnDiv {
          .submitBtn {
            width: 120px;
            height: 40px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  // Popup

  .popupDiv {
    .innerDiv {
      width: 80vw;

      .mainDiv {
        &__question {
          font-size: 20px;
        }

        .clientDetails {
          .clientName {
            font-size: 15px;
            padding: 0 7px;
          }
        }

        &__textArea {
          textarea {
            font-size: 15px;
          }
        }

        &__btnDiv {
          .submitBtn {
            width: 110px;
            height: 35px;
            font-size: 12px;

            svg {
              width: 14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 601px) {
  .topTabs {
    width: 100%;
    height: auto;

    .navPills {
      width: 100%;

      justify-content: space-between;

      .navPillsList {
        margin: 5px;

        color: white;

        .navLink {
          color: white;
          padding: 5px 2px;
          font-size: 12px;
        }
      }
    }
  }

  .qna {
    &__innerDiv {
      .selectDates {
        flex-direction: column;
        padding-top: 20px;

        .firstCalender,
        .secondCalender {
          width: 100%;
          padding: 0 5px;
          display: flex;
          line-height: 1.5rem;
          margin-bottom: 0px;
          align-items: center;
          justify-content: center;
        }

        .firstInput,
        .secondInput {
          margin: 15px 0;
          border: 1px solid #62bfae;
          height: 40px;
          padding: 0 10px;
          outline: none;
          width: 200px;
        }

        .GetReports {
          margin: 20px 0 20px 0;
        }
      }
    }

    .footerLink {
      display: none;
    }
  }

  // Popup

  .popupDiv {
    .closePopup {
      svg {
        width: 28px;
        height: 28px;
      }
    }

    .innerDiv {
      // width: 300px;
      width: 80vw;

      .mainDiv {
        &__question {
          font-size: 18px;
        }

        .clientDetails {
          flex-wrap: wrap;
          .clientName {
            font-size: 12px;
            padding: 0 6px;
            margin-bottom: 5px;
            border-left: 1px solid #62bfae;
          }
        }

        &__textArea {
          textarea {
            font-size: 13px;
          }
        }

        &__btnDiv {
          .submitBtn {
            width: 80px;
            height: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
