.container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, #f3ab8e85, #62bfae85, #548df785);
  animation: Gradient 20s ease infinite;
  background-size: 300%;

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .layoutFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
