.forgotPasswordLogo {
  height: 80px;
  padding: 25px;
}

.forgotPassword {
  display: flex;
  align-items: center;

  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 80px);

  width: 100%;
  position: relative;
  padding-bottom: 3%;

  .topComp {
    padding-top: 12%;

    .fpHeader {
      font-size: 40px;
      margin-bottom: 40px;
      text-align: center;
    }

    .svgImgDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .svgImg {
        margin: 2% 0%;
      }

      .checkMail {
        margin: 30px 0px;
        font-size: 30px;
        font-family: "L-Regular";
        color: #2d364f;
      }

      .paraText {
        text-align: center;
        margin-bottom: 0;
        font-size: 30px;
        font-family: "L-Regular";
        color: #2d364f;
        line-height: 130%;
      }
    }
  }

  .forgotPasswordForm {
    padding: 7% 0%;
    text-align: center;

    .resetInput {
      width: 500px;
      height: 60px;
      border: none;
      background: #f5f8fd;
      padding-left: 10px;
      font-size: 26px;
      outline: none;
      font-family: "L-Light";
      margin-bottom: 30px;
      color: black;

      &::placeholder {
        color: #b4c0d3;
      }
    }

    .resetPasswordBtn {
      background: #62bfae;
      border: none;
      font-size: 20px;
      font-family: "L-Regular";
      width: 250px;
      height: 50px;
      text-transform: uppercase;
      color: white;
      letter-spacing: 0.5px;
      transition: all 0.2s linear;

      &:hover {
        letter-spacing: 1.5px;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .forgotPassword {
    .topComp {
      padding-top: 10%;

      .fpHeader {
        font-size: 28px;
        margin-bottom: 40px;
        text-align: center;
      }

      .svgImgDiv {
        .svgImg {
          width: 200px;
          height: 200px;
        }

        .paraText {
          font-size: 18px;
        }
        .checkMail {
          margin: 30px 0px;
          font-size: 24px;
          font-family: "L-Regular";
          color: #2d364f;
        }
      }
    }

    .forgotPasswordForm {
      padding: 7% 0%;
      text-align: center;

      .resetInput {
        width: 450px;
        height: 45px;
        font-size: 18px;
        margin-bottom: 30px;

        &::placeholder {
          color: #b4c0d3;
        }
      }

      .resetPasswordBtn {
        width: 200px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .forgotPassword {
    padding-bottom: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .forgotPassword {
    justify-content: space-between;
    padding: 0% 5% 5% 5%;

    .topComp {
      padding-top: 5%;

      .fpHeader {
        font-size: 22px;
        margin-bottom: 40px;
        margin-top: 10px;
      }

      .svgImgDiv {
        .svgImg {
          width: 130px;
          height: 130px;
        }

        .paraText {
          font-size: 18px;
        }
        .checkMail {
          margin: 30px 0px;
          font-size: 24px;
          font-family: "L-Regular";
          color: #2d364f;
        }
      }
    }

    .bottomComp {
      padding-bottom: 5%;
    }

    .forgotPasswordForm {
      padding: 7% 0%;
      text-align: center;

      .resetInput {
        width: 300px !important;
        height: 45px;
        font-size: 18px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;

        &::placeholder {
          color: #b4c0d3;
        }
      }

      .resetPasswordBtn {
        width: 200px;
        height: 40px;
        font-size: 16px;
      }
    }
  }
}
