@import "src/assets/scss/variables";

.Sidebar {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 100px;
  padding: 30% 0%;
  width: 120px;
  border: 2px solid rgba(255, 255, 255, 0.315);
  margin-bottom: 40px;

  .SidebarItem {
    text-align: center;
  }

  .SidebarLink {
    color: #b4c0d3;
    text-decoration: none;
    display: block;
    padding: 0.2rem 0;
  }

  .SidebarLink:hover {
    color: #3c3c3c;
    cursor: pointer;
  }

  .SidebarItem.active .SidebarLink {
    color: $primary;
  }

  .SidebarItemIcon {
    font-size: 40px;
    fill: #000;
  }

  .bts {
    padding: 8px 0;
    text-align: center;

    &:hover {
      .btsText {
        color: #3c3c3c !important;
      }
    }

    .btsLink {
      text-align: center;
      padding: 0.5rem 0;

      &:hover {
        text-decoration: none;
      }

      .btsText {
        color: #828993;
        line-height: 110%;
        margin: 0.25rem 0 0 0;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .Sidebar {
    width: 100px;

    .bts {
      .btsLink {
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .Sidebar {
    background: #f7f7f7;
    display: flex;
    border-radius: 0px;
    padding: 0% 5%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    overflow: hidden;
    z-index: 902 !important;

    .bts {
      .btsLink {
        img {
          width: 28px;
          height: 28px;
        }

        .btsText {
          font-size: 14px;
        }
      }
    }

    .SidebarItem {
      text-align: center;
    }

    .SidebarLink {
      color: #b4c0d3;
      text-decoration: none;
      display: block;
      padding: 0.2rem 0;
    }

    .SidebarItem.active .SidebarLink {
      color: $primary;
    }

    .SidebarItemIcon {
      font-size: 40px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .Sidebar {
    background: #f7f7f7;
    display: flex;
    border-radius: 0px;
    padding: 0% 4%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    z-index: 200 !important;

    .SidebarItem {
      text-align: center;
    }

    .SidebarLink {
      color: #b4c0d3;
      text-decoration: none;
      display: block;
      padding: 0.2rem 0;
    }

    .SidebarLink:hover {
      color: #3c3c3c;
      cursor: pointer;
    }

    .SidebarItem.active .SidebarLink {
      color: $primary;
    }

    .SidebarItemIcon {
      font-size: 40px;
    }
  }

  .logout {
    display: none;
  }

  .bts {
    display: none;
  }
}
