body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  color: white;
  width: 2px;
}
::-webkit-scrollbar-thumb {
  background: #62bfae;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table thead tr th {
  border-top: 0px;
  border-bottom: 0px;
}

.table tbody tr td {
  vertical-align: middle;
}

table tbody tr {
  cursor: pointer;
}

table tbody tr:hover {
  background-color: #f8f9fa;
}

.btn-group-toggle .active {
  background: #62bfae !important;
  color: white !important;
}

.btn-group-toggle .active > .activeType {
  color: #62bfae !important;
}

.btn-group-toggle:disabled {
  background: transparent !important;
}

.disableSaveBtn {
  background: white;
  color: #62bfae;
  border: 1px solid #62bfae;
}

.disableSaveBtn:enabled {
  background: #62bfae !important;
  color: white;
}

.errorTxt {
  color: #ff5f5f;
  margin: 0;
  font-size: 16px;
  font-family: "L-Medium";
}

.activeLink {
  color: #62bfae !important;
  border-bottom: 1px solid #62bfae !important;
  font-size: 18px;
  font-family: "L-Medium";
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #62bfae !important;
}

.nav-tabs {
  border: none !important;
  border-bottom: 1px solid #62bfae !important;
}

.nav-tabs .nav-link {
  color: black !important;
  font-size: 17px;
  font-family: "L-Bold";
  text-transform: uppercase;
  border: none !important;
  border-bottom: 1px solid #62bfae !important;
}

.nav-tabs .nav-link.active {
  color: #62bfae !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  background-color: #62bfae !important;
  font-size: 18px !important;
  width: 24px !important;
  height: 24px !important;
  padding-bottom: 4px !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  padding: 0 !important;
  border: none !important;
}

.react-datepicker__navigation {
  top: 16px !important;
  outline: none !important;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__portal .react-datepicker__navigation {
  border-top-width: 0.5rem !important;
  border-right-width: 0.5rem !important;
  border-left-width: 0.5rem !important;
  border-bottom-width: 0.5rem !important;
}

.react-datepicker__navigation--next {
  border-left-color: white !important;
}

.react-datepicker__navigation--previous {
  border-right-color: white !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  background: #62bfae !important;
  color: white !important;
  padding: 10px 0 !important;
  height: 48px !important;
  text-transform: uppercase;
  font-family: "L-Regular" !important;
  font-size: 19px !important;
  font-weight: lighter !important;
  position: relative;
}

.react-datepicker__day-names {
  background: white;
  color: #b4c0d3 !important;
}

.react-datepicker__day-name {
  color: #b4c0d3 !important;
}

.react-datepicker__day,
.react-datepicker__day .react-datepicker__day--weekend {
  color: #B4C0D3 !important;
  background: white !important;
  border: 0.5px solid #B4C0D3 !important;
  height: 1.7rem !important;
}
.react-datepicker__day:hover {
  background: #62bfae !important;
  color: white !important;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #B4C0D3 !important;
  font-family: "L-Light" !important;
  border: none !important;
}

.react-datepicker__day--disabled:hover {
  background: white !important;
  color: #c6cbd3 !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  /* border: 1px solid #cfd2d6 !important; */
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  border: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__year-text--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected {
  background: #62bfae !important;
  border-radius: 50% !important;
  color: white !important;
  outline: none !important;
}

.react-datepicker__day,
.react-datepicker__year-text,
.react-datepicker__month-text,
.react-datepicker__quarter-text:hover {
  border-radius: 50% !important;
}

..react-datepicker__day {
  width: 1.7rem;
  height: 1.7rem !important;
  border: 1px solid #D9E1ED !important;
}

li.react-datepicker__time-list-item--disabled {
  color: #b4c0d3 !important;
}

li.react-datepicker__time-list-item--selected {
  background: #62bfae !important;
  border-radius: 0.35vh !important;
  color: white !important;
  outline: none !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #ebe8e88f !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  color: #9097a2;
}

.slick-track {
  margin-left: 0 !important;
}

.welcomeBack {
  text-align: center;
  font-size: 50px;
  font-family: "L-Light";
  color: #838080de;
  line-height: 100%;
  padding: 1% 0%;
  margin-bottom: 0;
  transition: all 0.5s linear;
}

.bcg {
  background: rgba(255, 255, 255, 0.6);
  padding: 2%;
  border-radius: 20px;
  margin-top: 5px;
  border: 2px solid rgba(255, 255, 255, 0.315);
}

.settingsHead {
  display: flex;
  width: 500px;
  align-items: center;
  justify-content: space-between;
  background: white;
  margin: auto;
  height: 50px;
  border-radius: 25px;
}

.singleNavItem {
  width: 250px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navItemSingle {
  width: 100% !important;
}

.navItem {
  background: white;
  color: #62bfae;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 25px;
  transition: all 0.3s linear;
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-size: 17px;
  font-family: "L-Bold";
  text-transform: uppercase;
  border-radius: 25px;
}

.navLink:hover {
  text-decoration: none;
  color: #62bfae;
}

.activeTab {
  background: #62bfae;
  color: white;
  border-radius: 25px;
  text-decoration: none;
}

.activeTab:hover {
  text-decoration: none;
  color: white;
}

.adminActiveTab {
  border-bottom: 1px solid white;
}

.activeAcc {
  background: #62bfae !important;
  color: white !important;
  transition: all 0.3s ease;
}

.switch {
  display: inline-block;
  height: 3px;
  position: relative;
  width: 30px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #9097a2;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
}

.slider:before {
  background-color: #e1e6ed;
  bottom: 4px;
  content: "";
  height: 20px;
  left: -2px;
  top: -10px;
  position: absolute;
  transition: 0.4s;
  width: 20px;
}

input:checked + .slider {
  background-color: #49aa98;
}

input:checked + .slider:before {
  transform: translateX(15px);
  background-color: #49aa98;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  background-color: #e1e6ed;
}

.activeChat {
  background: #62bfae;
  color: white !important;
}

.fullloader {
  border: 6px solid #ffffff;
  border-radius: 50%;
  border-top: 6px solid #62bfae;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.super-app-theme--header {
  background: #62bfae;
  color: white;
  font-family: "L-Bold";
  text-transform: uppercase;
  font-size: 14px;
}

.super-app-theme--cell {
  text-align: center !important;
  justify-content: center !important;
  background: white;
  cursor: pointer;
  font-size: 14px;
  outline: none !important;
  border-top: 5px solid rgba(158, 215, 241, 0.521);
  border-bottom: 5px solid rgba(158, 215, 241, 0.521);
  overflow: hidden !important;
}

.super-app-theme--admin-header {
  background: #000;
  color: white;
  font-size: 18px;
  height: 45px;
  font-family: "L-Regular";
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  min-height: 45px !important;
  max-height: 45px !important;
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: #62bfae;
  border-bottom: 1px solid white;
  padding: 0 2% !important;
}

.MuiButton-textPrimary {
  color: white !important;
}

.MuiDataGrid-root
  .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover
  .MuiDataGrid-sortIcon {
  opacity: 1 !important;
}

.MuiDataGrid-sortIcon {
  color: #fff !important;
}

.loadMoreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #62bfae;
  width: 180px;
  min-height: 45px !important;
  color: white;
  font-family: "L-Bold";
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 0.35vh;
  margin: 10px auto;
  cursor: pointer;
}

.questionClient {
  line-height: 100% !important;
  text-align: center;
}

.questionClient p {
  margin: 0;
}

.questionClientName {
  font-size: 18px;
  font-family: "L-Regular";
  text-transform: capitalize;
}

.questionClientEmail {
  font-size: 16px;
  font-family: "L-Regular";
  color: #9097a2;
}

.questionClientCity {
  font-size: 18px;
  font-family: "L-Regular";
  color: #428dff;
  margin: 0;
  text-transform: capitalize;
}

.questionClientStatus {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "L-Regular";
  margin: 0;
}

.questionClientPublished {
  color: #62bfae;
}

.questionClientNotPub {
  color: #ff5f5f;
}

.adminSessionClient {
  font-size: 16px;
  font-family: "L-Regular";
  text-transform: capitalize;
}

.adminSessionType {
  font-size: 15px;
  font-family: "L-Regular";
  color: #9097a2;
  text-transform: uppercase;
}

.adminSessionFee {
  color: #000;
  font-size: 16px;
  font-family: "L-Regular";
}

.adminSessionStatus {
  text-transform: capitalize;
  color: #000;
  font-size: 16px;
  font-family: "L-Regular";
}

.MuiRating-iconFilled .ratingSvgPath {
  fill: #ffda00 !important;
}

.MuiRating-iconFilled .ratingSvgStroke {
  fill: transparent;
  stroke: #000;
}

.MuiRating-iconFilled {
  transform: scale(1.2);
}

.MuiRating-iconEmpty .ratingSvgPath {
  fill: #eff2f8 !important;
}

@media only screen and (max-width: 1600px) {
  .welcomeBack {
    font-size: 28px;
  }

  .nav-tabs .nav-link {
    font-size: 13px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 16px !important;
  }

  .settingsHead {
    width: 350px;
    height: 40px;
  }

  .navLink {
    font-size: 14px;
  }

  .loadMoreBtn {
    width: 120px;
    min-height: 35px !important;
    font-size: 14px;
  }

  .super-app-theme--admin-header {
    font-size: 14px;
  }

  .MuiDataGrid-root .MuiDataGrid-toolbarContainer {
    width: 100%;
    height: 50px;
  }

  .super-app-theme--cell {
    font-size: 12px;
  }

  .questionClientName {
    font-size: 16px;
  }

  .questionClientEmail {
    font-size: 13px;
  }

  .questionClientCity {
    font-size: 14px;
  }

  .questionClientStatus {
    font-size: 12px;
  }

  .adminSessionClient {
    font-size: 12px;
  }

  .adminSessionType {
    font-size: 11px;
  }

  .adminSessionFee {
    font-size: 12px;
  }

  .adminSessionStatus {
    font-size: 12px;
  }
}

@media only screen and (min-device-width: 601px) and (max-device-width: 1024px) {
  .welcomeBack {
    font-size: 28px;
    padding: 2% 0%;
  }

  .super-app-theme--cell {
    font-size: 12px;
  }

  .questionClientName {
    font-size: 14px;
  }

  .questionClientEmail {
    font-size: 11px;
  }

  .questionClientCity {
    font-size: 12px;
  }

  .questionClientStatus {
    font-size: 11px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .welcomeBack {
    display: none;
  }

  .bcg {
    padding: 0%;
    background: transparent !important;
    border: none;
  }

  .errorTxt {
    font-size: 14px;
  }

  .slick-dots {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    overflow-x: auto;
  }

  .slick-dots li {
    width: 20px !important;
    height: 20px !important;
    margin: 0 !important;
  }

  .settingsHead {
    width: 95%;
    height: 35px;
    margin: 2% auto;
  }

  .singleNavItem {
    /* width: 180px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navItemSingle {
    height: 35px;
  }

  .navLink {
    font-size: 12px;
  }

  /* .react-datepicker {
    font-size: 12px !important;
  } */

  .react-datepicker__day--selected,
  .react-datepicker__year-text--selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__quarter-text--selected {
    background: #62bfae !important;
    border-radius: 50% !important;
    color: white !important;
  }

  .react-datepicker__portal .react-datepicker__month-container {
    width: 100% !important;
  }

  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 1.6rem !important;
    line-height: 1.6rem !important;
  }

  .react-datepicker__month {
    margin: 0 !important;
  }

  .react-datepicker__navigation {
    top: 13px !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: white !important;
    padding: 8px 0 !important;
    height: 40px !important;
    font-size: 14px !important;
  }

  .react-datepicker__day-names {
    background: white;
    color: #b4c0d3 !important;
  }

  .react-datepicker__day-name {
    color: #b4c0d3 !important;
  }

  li.react-datepicker__time-list-item--selected {
    background: #62bfae !important;
    border-radius: 0.35vh !important;
    color: white !important;
  }

  .super-app-theme--header {
    font-size: 12px;
  }

  .MuiDataGrid-root .MuiDataGrid-toolbarContainer {
    /* justify-content: flex-end; */
    display: flex;
    align-items: flex-end !important;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #62bfae;
    border-bottom: 1px solid white;
    padding: 2% 2% !important;
  }

  .MuiButton-textPrimary {
    display: flex !important;
    font-size: 12px !important;
  }

  .super-app-theme--cell {
    font-size: 10px;
  }

  .questionClientName {
    font-size: 13px;
  }

  .questionClientEmail {
    font-size: 11px;
  }

  .questionClientCity {
    font-size: 11px;
  }

  .questionClientStatus {
    font-size: 11px;
  }
}
