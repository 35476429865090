.settings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;

  .bcgSettings {
    min-height: 70vh !important;
    margin-bottom: 5%;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      display: block;
    }
  }

  .settingsFooter {
    display: flex;
    width: 100%;
    justify-content: center;

    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .settings {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .bcgSettings {
      min-height: 60vh !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .settings {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;

    .bcgSettings {
      display: block;

      overflow-y: auto;
      min-height: auto !important;

      .resHeight {
        height: auto !important;
        overflow-y: auto;
        // padding-bottom: 120px;
      }
    }

    .settingsFooter {
      display: none;
    }
  }
}
