.navHeight {
  height: 80px !important;
  position: fixed;
  width: 100%;
  z-index: 900;

  .navFlex {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navListRight {
      display: flex;
      align-items: center;

      li {
        margin: 0px 10px;
      }
    }

    .menuDiv {
      display: none;
    }

    .searchIconDiv {
      width: 334px;
      height: 45px;
      background: white;
      position: relative;
      transition: all 0.2s linear;
      display: flex;
      align-items: center;
      border-radius: 50px;
      cursor: text;

      .searchText {
        color: #b4c0d3;
        font-size: 20px;
        font-family: "L-Light";
        padding-left: 20px;
      }

      .searchIcon {
        width: 20px;
        height: 20px;
        fill: #f9ddd2 !important;
        position: absolute;
        right: 3%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      // &:hover {
      //   background: #62bfae;

      //   .searchIcon {
      //     fill: #fff !important;
      //   }
      // }
    }

    .profileDiv {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background: white;
      border: 2px solid white;
      position: relative;
      overflow: hidden;

      .navProfileImage {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
  }

  .bellIconDiv {
    background: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;

    .bellIcon {
      object-position: center;
      object-fit: cover;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.navLogo {
  margin: 15px;

  .mobileLogo {
    display: none;
  }
}

@media only screen and (max-width: 1600px) {
  .navHeight {
    height: 80px !important;
    position: fixed;
    width: 100%;

    .navFlex {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .searchIconDiv {
        width: 250px;
        height: 40px;
        .searchText {
          font-size: 14px;
        }

        .searchIcon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .bellIconDiv {
      background: white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: relative;

      .bellIcon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .navHeight {
    height: 70px !important;
    position: fixed;
    width: 100%;
    z-index: 900;

    background: transparent;
    overflow: hidden;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .navHeight {
    height: 55px !important;
    position: fixed;
    width: 100%;
    z-index: 900;

    background: transparent;
    overflow: hidden;

    .navFlex {
      width: 100%;
      height: 90%;
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 0.5rem !important;

      .navListRight {
        li {
          margin: 0;
        }

        .searchIconDiv {
          width: 30px;
          height: 30px;
          position: relative;

          .searchText {
            display: none;
          }

          .searchIcon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 16px;
            height: 16px;
          }
        }
      }

      .menuDiv {
        padding: 0 5px;
        display: flex;
        align-items: center;

        .openedMenu {
          width: 100%;
          height: 100vh;
          position: fixed;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 40000;

          .firstRow {
            height: 35vh;
            background: #62bfae;
            border-bottom: 3px solid white;

            .openProfileDiv {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 100%;
              justify-content: center;

              .openedProfile {
                padding-top: 2%;
                display: block !important;
                text-align: center;
                font-size: 24px;
                color: white;
                font-family: "L-Light";
              }

              .logoutBtn {
                color: #62bfae;
                padding: 2px 10px;
                font-size: 16px;
                text-transform: uppercase;
                margin-top: 20px;
                font-family: "L-Regular";
                background: white;
                border-radius: 0.35vh;
              }
            }
          }

          .secondRow {
            width: 100%;
            height: 65vh;
            background: #f3ab8e;

            .pageLinks {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding-top: 12%;

              .footerLink2 {
                color: white;
                padding: 2% 0;
                text-transform: uppercase;
                font-size: 20px;
                font-family: "L-Regular";
                text-decoration: none;
              }
            }

            .closeMenuIcon {
              position: absolute;
              left: 50%;
              bottom: 15%;
              transform: translateX(-50%);
            }
          }
        }

        .hamIconDiv {
          width: 30px;
          height: 30px;
          background: transparent;
          border-radius: 50%;
          position: relative;

          .hamIcon {
            width: 20px;
            height: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .profileDiv {
        display: none;
      }
    }

    .bellIconDiv {
      background: white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: relative;

      .bellIcon {
        object-position: center;
        object-fit: cover;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .navLogo {
    margin: 15px;

    .ImgFluid {
      display: none;
    }

    .mobileLogo {
      display: block;
      height: 30px;
    }
  }
}
