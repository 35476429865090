.searchCard {
  width: 370px;
  height: 320px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 9px;
  padding: 20px;
  margin: 2%;
  position: relative;
  // cursor: pointer;

  &:hover {
    text-decoration: none;
    background: #54b4a2;
    color: #fff;

    .searchCard__topDetails {
      border-bottom: 1px solid #fff !important;
    }

    .profileImgDiv {
      border-radius: 50%;
      padding: 2px;
      border: 1px solid #fff;
    }

    .typeSessionItem {
      color: #fff !important;
    }

    .name {
      color: #fff !important;
    }

    .job {
      color: #fff !important;
    }

    .city_town {
      color: #fff !important;
    }

    .sessionFee {
      color: #fff;

      svg path {
        fill: #fff;
      }
    }

    .consellingArea__title {
      color: #fff !important;
    }

    .consellingArea__dataItem {
      color: #fff !important;
    }

    .viewProfile {
      color: #fff !important;
      border-bottom: 1px solid #fff !important;
    }

    .cardBookSess {
      background-color: #fff !important;
      color: #54b4a2 !important;
    }
  }

  &__topDetails {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #54b4a2;
    padding-bottom: 5%;

    .profileImgDiv {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      padding: 2px;
      border: 1px solid #54b4a2;

      .ProfileImg {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .profileName {
      width: 73%;
      word-wrap: break-word;

      .name {
        font-size: 20px;
        font-family: "L-Medium";
        color: #000;
        text-transform: capitalize;
        margin-bottom: 3px;
        white-space: pre-line;
      }

      .job {
        margin: 0;
        font-size: 13px;
        color: #5f646b;
        font-family: "L-Regular";
        line-height: 125%;
        text-transform: uppercase;
      }

      .city_town {
        margin: 0;
        font-size: 16px;
        text-transform: capitalize;
        color: #54b4a2;
      }
    }
  }

  &__middleDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5% 0;

    .sessionFee {
      color: #54b4a2;
      font-size: 15px;
      font-family: "L-Medium";
      margin: 0;
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }

  .typeSession {
    padding-top: 10px !important;

    .typeSessionList {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: 6px;
      flex-wrap: wrap;

      .typeSessionItem {
        color: #5f646b;
        // width: 24%;
        font-size: 14px;
        margin-right: 20px;
        display: flex;
        align-items: center;

        span {
          font-size: 20px;
          margin-right: 2px;
        }
      }
    }
  }

  .consellingArea {
    margin: 0;
    line-height: 100%;
    padding-top: 1%;

    &__title {
      font-size: 16px;
      font-family: "L-Medium";
      color: #000;
    }

    &__dataItem {
      color: #5f646b;
      font-size: 14px;
      font-family: "L-Regular";
    }
  }

  .cardBtnDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);

    a {
      &:hover {
        text-decoration: none;
      }
    }

    p {
      margin: 0;
    }

    .cardBookSess {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #54b4a2;
      color: #fff;
      // width: 120px;
      padding: 0 8px;
      height: 35px;
      margin: 0 10px;
      border-radius: 4px;
      text-decoration: none;
      transition: all 0.3s ease-out;

      &:hover {
        text-decoration: none;
        // letter-spacing: 0.6px;
      }
    }

    .viewProfile {
      font-size: 12px;
      font-family: "L-Bold";
      color: #54b4a2;
      text-transform: uppercase;
      border-bottom: 1px solid #54b4a2;
      margin: 0 10px;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        text-decoration: none;
        // letter-spacing: 0.1px;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .searchCard {
    margin: 1%;
  }
}
