.therapistPayment {
  background: #62bfae;
  width: 100%;

  min-height: 65vh;
  border-top: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto !important;
}

.errorPopup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__innerDiv {
    width: 500px;
    height: 300px;
    background: #f3ab8e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 11px;
    position: relative;

    .crossIconDiv {
      cursor: pointer;
      position: absolute;
      top: 5%;
      right: 5%;
    }

    .errorText {
      font-size: 26px;
      font-family: "L-Regular";
      color: #fff;
      padding: 5% 5% 3% 5%;
      margin-top: 2%;
      text-align: center;
      line-height: 120%;
    }

    .verifyBtn {
      height: 35px;
      padding: 5px 10px;
      background: white;
      color: #62bfae;
      font-size: 16px;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "L-Regular";
      transition: all 0.2s linear;
      border-radius: 0.35vh;

      &:hover {
        text-decoration: none;
        background: rgba(255, 255, 255, 0.753);
        color: #62bfae;
      }
    }
  }
}

.payoutDetails {
  &__card {
    width: 584px;
    height: 289px;
    background: white;
    border-radius: 11px;
    padding: 7% 13%;

    p {
      margin: 0;
    }

    .firstRow {
      padding-bottom: 30px;
      &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;

        .labelName {
          font-size: 16px;
          font-family: "L-SBold";
          color: #9097a2;
          text-transform: uppercase;
        }
      }

      &__nameData {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #62bfae;
        padding-bottom: 8px;

        .dataText {
          font-size: 30px;
          font-family: "L-Medium";
          color: #9097a2;
          text-transform: capitalize;
          line-height: 120%;
        }
      }
    }

    .secondRow {
      .accountNumber {
        font-size: 16px;
        font-family: "L-SBold";
        color: #9097a2;
        text-transform: uppercase;
        padding-bottom: 10px;
      }

      .numberDigit {
        font-size: 27px;
        font-family: "L-Light";
        color: #9097a2;
      }
    }
  }
}

.payoutBtn {
  padding-top: 20px;

  .addPayoutBtn {
    width: 300px;
    height: 60px;
    border: none;
    outline: none;
    background: white;
    border-radius: 0.35vh;
    font-size: 24px;
    font-family: "L-Medium";
    color: #62bfae;
    transition: all 0.2s linear;

    &:hover {
      letter-spacing: 1.1px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .therapistPayment {
    min-height: 60vh;
    overflow-y: auto !important;
  }

  .payoutDetails {
    &__card {
      width: 450px;
      height: 230px;

      .firstRow {
        padding-bottom: 30px;
        &__label {
          .labelName {
            font-size: 12px;
          }
        }

        &__nameData {
          .dataText {
            font-size: 24px;
          }
        }
      }

      .secondRow {
        .accountNumber {
          font-size: 12px;

          padding-bottom: 8px;
        }

        .numberDigit {
          font-size: 24px;
        }
      }
    }
  }

  .payoutBtn {
    padding-top: 20px;

    .addPayoutBtn {
      width: 220px;
      height: 45px;
      border: none;
      outline: none;
      background: white;
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .payoutDetails {
    &__card {
      width: 280px;
      height: 150px;

      .firstRow {
        padding-bottom: 15px;
        &__label {
          padding-bottom: 6px;
          .labelName {
            font-size: 10px;
          }
        }

        &__nameData {
          .dataText {
            font-size: 16px;
          }
        }
      }

      .secondRow {
        .accountNumber {
          font-size: 10px;
          padding-bottom: 6px;
        }

        .numberDigit {
          font-size: 16px;
        }
      }
    }
  }

  .errorPopup {
    &__innerDiv {
      width: 280px;
      height: 170px;

      .crossIconDiv {
        .crossIcon {
          width: 18px;
          height: 18px;
        }
      }

      .errorText {
        font-size: 16px;
        font-family: "L-Regular";
        color: #fff;
      }

      .verifyBtn {
        height: 30px;
        font-size: 14px;
      }
    }
  }

  .payoutBtn {
    .addPayoutBtn {
      width: 150px;
      height: 35px;
      font-size: 14px;
    }
  }
}
