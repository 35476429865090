.mainDiv {
  width: 100%;
  padding-top: 80px;
  overflow: hidden;
  height: 100%;

  .Main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 3%;
    padding-right: 10%;
    height: 100%;

    .sidebarDiv {
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .CurrentTabContent {
      margin-left: 5%;
      height: 100%;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .mainDiv {
    width: 100%;
    padding-top: 80px;
    overflow: hidden;
    height: 100%;

    .Main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      height: calc(100% - 80px);

      .sidebarDiv {
        background: white;
        z-index: 1000;
        width: 100%;
        display: block;
        position: fixed;
        bottom: 0;
        justify-content: center;
        align-items: center;
        height: 90px;
        z-index: 200 !important;
      }

      .CurrentTabContent {
        order: 1;
        margin-left: 0%;
        height: 100%;
        overflow: auto;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .mainDiv {
    width: 100%;
    padding-top: 55px;
    overflow: hidden;
    height: 100%;

    .Main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      height: calc(100% - 50px);

      .sidebarDiv {
        width: 100%;
        display: block;
        position: fixed;
        bottom: 0;
        justify-content: center;
        align-items: center;
        height: 60px;
        z-index: 200 !important;
        background: white !important;
      }

      .CurrentTabContent {
        order: 1;
        margin-left: 0%;
        height: 100%;
        padding: 0 10px !important;
        overflow-y: auto;
      }
    }
  }
}
