.Rating {
  line-height: 1;
  font-size: 50px;
}

.sessionOverview {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__innerDiv {
    height: 75vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

    .sessionOverviewTitleDiv {
      width: 100%;
      height: 60px;
      background: #62bfae;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .backArrow {
        width: 5%;
        cursor: pointer;
        margin-left: 15px;
      }

      .sessionOverviewTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 60px;

        font-size: 19px;
        text-transform: uppercase;
        margin: 0;
        font-family: "L-Regular";
        color: white;
        text-align: center;
      }
    }

    .sessionOverviewContent {
      display: flex;
      justify-content: space-between;
      padding-top: 6px;
      height: 100%;
      overflow-y: hidden;

      .firstCol {
        width: 40%;
        overflow-y: hidden;
        height: 100%;
        background: white;
        border-bottom-left-radius: 15px;

        .AppointmentTitle {
          height: 60px;
          font-size: 24px;
          padding: 15px;
          font-family: "L-Regular";
          background: #f3ab8e;
          color: white;
          text-transform: capitalize;
          text-align: center;
          margin: 0;
        }

        .detailsDiv {
          padding: 3% 4% 3% 4%;
          height: 50vh;
          overflow-y: auto;

          .accDec {
            display: flex;
            align-items: flex-end !important;
            justify-content: flex-end !important;
          }

          .firstItemFlex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 2%;

            .cancelBtn {
              .cancelSesBtn {
                background-color: #ff5f5f;
                color: white;
                border: none;
                outline: none;
                padding: 5px 10px;
                border-radius: 0.35vh;
                font-size: 14px;

                &:hover {
                  background-color: #fd4242;
                }
              }
            }

            .sendMsgBtn {
              border: none;
              outline: none;
              background: transparent;
              color: #62bfae;
              font-size: 18px;
              font-family: "L-Regular";

              :hover {
                text-decoration: underline;
              }
            }
          }

          .detailOne {
            display: flex;
            align-items: center;
            padding-bottom: 2%;

            .iconBg {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              background: #eef8f6;
              position: relative;
              margin-right: 10px;

              .sessionIcon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }

              .FontAwesomeIcon {
                font-size: 22px;
                color: #62bfae;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .sessionTitle {
              margin: 0;
              font-size: 20px;
              font-family: "L-Light";
              color: #9097a2;
              text-transform: capitalize;
            }
          }

          .videoLink {
            padding-left: 55px !important;
            line-height: 120%;
          }
        }
      }

      .secondCol {
        height: 100%;
        width: 59%;
        background: white;
        border-bottom-right-radius: 15px;

        .AppointmentTitle {
          display: flex;
          height: 60px;
          align-items: center;
          justify-content: space-evenly;
          flex-wrap: wrap;
          padding: 15px 3%;
          background: #f3ab8e;
          margin: 0;

          .notesTab {
            font-size: 17px;
            font-family: "L-Bold";
            text-transform: uppercase;
            text-align: center;
            color: white;
            outline: none;
            border: none;
            background: transparent;
            background: #f3ab8e;
          }

          .starIcon {
            margin-right: 5px;
            margin-bottom: 4px;
          }
        }

        .notesTab {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .notesDiv {
          padding: 0 3% 3% 3%;
          height: 45vh;
          overflow-y: auto;

          .notesDivContent {
            .sessionOverviewTextArea {
              background: white;
              width: 100%;
              border: 1px solid #a6b3ca;
              border-radius: 9px;
              padding: 10px;
              outline: none;
              font-size: 16px;
              color: #9097a2;
              font-family: "L-Regular";

              &::placeholder {
                color: #9097a29b;
              }
            }

            .ratingModalDiv {
              position: fixed;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background-color: #00000054;
              backdrop-filter: blur(16px);

              .modalDialog {
                width: 100vw !important;
                height: 100vh;
                max-width: 100vw;
                margin: 0;
              }

              .ratingModalCard {
                width: 100vw !important;
                height: 100vh;
                // border-radius: 11px;
                background: #fff;
                position: relative;
              }

              .modalHeader {
                border-bottom: none;
              }
              .closeBtn {
                position: absolute;
                top: 30px;
                right: 60px;
                opacity: 1 !important;
                z-index: 2000;
              }

              .modalBody {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // margin-top: 5%;
                flex-direction: column;
                width: 100%;
                padding: 0;
                height: 100%;
                overflow-y: auto;
              }

              .ratingModalHead {
                // height: 35%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 25px 0 15px 0;
                background-color: #fdf4f3;

                &__imageDiv {
                  .ratingImg {
                    width: 280px;
                  }
                }

                &__topTitle {
                  font-size: 24px;
                  font-family: "L-Bold";
                  margin: 6px 0;
                }

                &__topHeading {
                  font-size: 32px;
                  font-family: "L-Regular";
                  color: #00c5ab;
                  text-align: center;
                  margin: 0;
                  line-height: 100%;
                }

                &__topDesc {
                  width: 50%;
                  text-align: center;
                  font-size: 16px;
                  line-height: 140%;
                  margin: 8px 0;
                }
              }

              .ratingModalBody {
                // height: 65%;
                width: 100%;
                display: flex;
                align-items: center;
                padding: 30px;
                flex-direction: column;

                &__goal {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  padding-bottom: 30px;

                  &__title {
                    color: #6b6e72;
                    text-align: center;
                    font-size: 22px;
                    font-family: "L-Regular";
                    letter-spacing: 0.5px;
                    margin-bottom: 15px;
                  }
                }
              }

              .modalFooter {
                border-top: none;
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .SubmitBtn {
                  width: 175px;
                  height: 40px;
                  background: #62bfae;
                  border: none;
                  outline: none;
                  color: #fff;
                  font-size: 16px;
                  font-family: "L-Regular";
                  text-transform: uppercase;
                  border-radius: 2px;
                  margin-bottom: 0px;
                  transition: all 0.2s linear;

                  &:hover {
                    letter-spacing: 1.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sessionOverviewFooter {
    display: flex;
    justify-content: center;
    width: 100%;

    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .Rating {
    font-size: 40px !important;
  }

  .sessionOverview {
    &__innerDiv {
      .sessionOverviewTitleDiv {
        height: 45px;

        .backArrow {
          width: 5%;
          cursor: pointer;
        }

        .sessionOverviewTitle {
          height: 45px;
          font-size: 16px;
        }
      }

      .sessionOverviewContent {
        padding-top: 3px;

        .firstCol {
          width: 40%;
          height: 100%;
          background: white;
          border-bottom-left-radius: 15px;

          .AppointmentTitle {
            height: 45px;
            font-size: 18px;
            padding: 10px;
          }

          .detailsDiv {
            .firstItemFlex {
              .sendMsgBtn {
                font-size: 16px;
              }
            }

            .detailOne {
              .iconBg {
                width: 30px;
                height: 30px;

                .sessionIcon {
                  width: 18px;
                  height: 18px;
                }

                .FontAwesomeIcon {
                  font-size: 15px;
                }
              }

              .sessionTitle {
                margin: 0;
                font-size: 16px;
              }
            }

            .videoLink {
              padding-left: 43px !important;
            }
          }
        }

        .secondCol {
          .AppointmentTitle {
            height: 45px;
            padding: 15px 4%;

            .notesTab {
              overflow-y: hidden;
              font-size: 12px;
            }

            .starIcon {
              width: 15px;
            }
          }

          .notesDiv {
            .notesDivContent {
              // .ratingModalDiv {
              //   .modalDialog {
              //     max-width: 500px !important;
              //   }

              //   .ratingModalCard {
              //     max-width: 500px !important;
              //     height: 280px;
              //   }

              //   .modalHeader {
              //     border-bottom: none;

              //     .ratingClose {
              //       width: 30px;
              //       height: 30px;
              //     }
              //   }

              //   .modalBody {
              //     margin-top: 4%;
              //   }
              // }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .sessionOverview {
    justify-content: center;
    height: 100%;

    &__innerDiv {
      min-height: 65vh !important;
      margin-bottom: 6%;
      height: 0vh !important;

      .sessionOverviewContent {
        min-height: 80% !important;

        .firstCol {
          .detailsDiv {
            overflow-y: auto;
            height: 45vh;
          }
        }

        .secondCol {
          .notesDiv {
            height: 45vh;
            overflow-y: auto;

            .notesDivContent {
              .ratingModalDiv {
                .ratingModalHead {
                  // height: 35%;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 25px 0 15px 0;
                  background-color: #fdf4f3;

                  &__imageDiv {
                    .ratingImg {
                      width: 200px;
                    }
                  }

                  &__topTitle {
                    font-size: 20px;
                  }

                  &__topHeading {
                    font-size: 28px;
                    padding: 0 10px;
                  }

                  &__topDesc {
                    width: 80%;
                  }
                }

                .ratingModalBody {
                  &__goal {
                    &__title {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sessionOverviewFooter {
      bottom: 1%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .Rating {
    line-height: 1;
  }

  .sessionOverview {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: space-between;

    &__innerDiv {
      height: auto !important;
      min-height: auto !important;
      display: flex;
      flex-direction: column;

      .sessionOverviewTitleDiv {
        height: 50px;

        .sessionOverviewTitle {
          height: 50px;
          font-size: 15px;
          width: 80%;
        }
      }

      .sessionOverviewContent {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        padding-top: 2px;
        height: auto !important;
        padding-bottom: 150px;

        .firstCol {
          width: 100%;
          overflow-y: auto;
          height: auto;
          border-bottom-right-radius: 15px;
          margin-bottom: 5%;

          .AppointmentTitle {
            height: 50px;
            font-size: 17px;
            padding: 12px;
          }

          .detailsDiv {
            padding: 4% 2% 4% 4%;
            height: auto !important;

            .firstItemFlex {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .cancelBtn {
                .cancelSesBtn {
                  padding: 2px 5px;

                  font-size: 12px;

                  &:hover {
                    background-color: #fd4242;
                  }
                }
              }
            }

            .detailOne {
              padding-bottom: 3%;
              .iconBg {
                width: 30px;
                height: 30px;
              }

              .sessionTitle {
                font-size: 16px;
              }
            }
          }
        }

        .secondCol {
          overflow-y: hidden;
          height: auto;
          width: 100%;
          background: white;
          border-bottom-right-radius: 15px;
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          padding-bottom: 30px;

          .AppointmentTitle {
            height: 50px;
            padding: 15px 1%;

            .notesTab {
              font-size: 10px;
            }

            .starIcon {
              margin-right: 5px;
              margin-bottom: 4px;
            }
          }

          .notesTab {
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .notesDiv {
            padding: 5%;
            height: auto;

            .notesDivContent {
              .cardBody {
                padding: 0px;
              }

              .sessionOverviewTextArea {
                background: white;
                width: 100%;
                height: 60%;
                border: 1px solid #a6b3ca;
                border-radius: 15px;
                padding: 10px;
                outline: none;
                font-size: 16px;
                color: #9097a2;
                font-family: "L-Regular";
              }

              .ratingModalDiv {
                .closeBtn {
                  right: 15px;
                }

                .ratingModalHead {
                  // height: 35%;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 25px 0 15px 0;
                  background-color: #fdf4f3;

                  &__imageDiv {
                    .ratingImg {
                      width: 140px;
                    }
                  }

                  &__topTitle {
                    font-size: 16px;
                  }

                  &__topHeading {
                    font-size: 24px;
                    padding: 0 10px;
                  }

                  &__topDesc {
                    width: 90%;
                    font-size: 14px;
                  }
                }

                .ratingModalBody {
                  &__goal {
                    &__title {
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sessionOverviewFooter {
      display: none;
    }
  }
}
