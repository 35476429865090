.welcomeBanner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.664);
  display: flex;
  align-items: center;
  justify-content: center;

  .welcomeBannerDiv {
    width: 600px;
    height: 400px;
    display: flex;
    background: #f9ddd2;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
  }

  .lottieDiv {
    width: 450px;
  }

  .welcomeText {
    position: absolute;
    bottom: 20%;
    font-size: 40px;
    color: black;
    text-align: center;
    font-family: "L-Regular";
    text-transform: uppercase;
  }
}

.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__innerDiv {
    margin-bottom: 5%;
    // height: 75vh !important;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .NewEnquiriesBadge {
      width: 26px;
      height: 26px;
      margin-left: 5px;
      border: 2px solid white;
      background: #ed6b64;
      border-radius: 50%;
      display: flex;

      align-items: center;
      justify-content: center;

      .NewEnquiriesNumber {
        font-size: 14px;
        font-family: "L-Regular";
        color: white;
      }
    }
  }

  .homeFooter {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .welcomeBanner {
    .welcomeBannerDiv {
      height: 350px;
      width: 500px;
    }

    .lottieDiv {
      width: 400px;
    }

    .welcomeText {
      font-size: 30px;
    }
  }

  .home {
    &__innerDiv {
      .NewEnquiriesBadge {
        width: 24px;
        height: 24px;

        .NewEnquiriesNumber {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .welcomeBanner {
    .welcomeBannerDiv {
      height: 400px;
    }

    .welcomeText {
      font-size: 30px;
    }
  }

  .home {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__innerDiv {
      min-height: auto !important;
      margin-bottom: 6%;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .homeFooter {
      display: flex;
      justify-content: center;
      width: 100%;
      bottom: 3%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .welcomeBanner {
    .welcomeBannerDiv {
      height: 200px;
      width: 300px;
    }

    .lottieDiv {
      width: 250px;
    }

    .welcomeText {
      font-size: 20px;
    }
  }

  .home {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .homeFooter {
      display: none;
    }
  }
}
