.login {
  display: flex;
  height: 100vh;
  width: 100%;

  .firstDiv {
    width: 45%;
    padding-top: 4%;
    text-align: center;
    height: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .signup {
      height: 25% !important;
      padding-bottom: 5%;
    }

    .welcomeSec {
      height: 25%;

      .welcome {
        padding-top: 2%;
        color: #9097a2;
        line-height: 100%;
        font-size: 45px;
        font-family: "L-Light";
      }

      .signIn {
        font-size: 22px;
        color: #9097a2;
        font-family: "L-Light";
        line-height: 100%;
      }
    }

    .loginDiv {
      height: 40%;

      .forgetPassword {
        font-size: 21px;
        color: #9097a2;
        margin-bottom: 2%;
        font-family: "L-Medium";

        &:hover {
          color: #79bcae;
          text-decoration: none;
        }
      }
    }

    .loginFooter {
      background-color: #f9ddd2;
      width: 100%;
      margin-top: 7%;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding: 6% 3% 2% 14%;

      .content {
        align-items: flex-start;
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        height: 100%;
        padding-left: 8%;

        .notReg {
          font-size: 29px;
          color: #2d364f;
          font-family: "L-Medium";
        }

        .btnDiv {
          display: flex;
          color: #62bfae;
          justify-content: flex-start;
          padding: 3% 0%;
          width: 100%;

          .joinBtn {
            width: 250px;
            height: 50px;
            border: none;
            outline: none;
            font-size: 20px;
            font-family: "L-Regular";
            background-color: white;
            text-transform: uppercase;
            margin-right: 30px;
            border-radius: 0.35vh;

            transition: all 0.3s linear;
            letter-spacing: 0px;

            .joinLink {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }

            &:hover {
              letter-spacing: 0.5px;
              background-color: #62bfae;
              color: white;
              text-decoration: none;

              .joinLink {
                color: white;
                text-decoration: none;
              }
            }
          }
        }

        .loginFooterLink {
          padding: 3% 10%;
        }
      }
    }

    .alreadyReg {
      font-size: 21px;
      font-family: "L-Medium";
      padding: 2% 0%;
      color: #9097a2;

      .loginLink {
        color: #62bfae;
        padding-left: 10px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .signupFooter {
      margin: auto;
      padding-top: 8%;
      padding-bottom: 3%;
    }
  }

  .secondDiv {
    width: 55%;
    height: 100vh;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .loginVideo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.loginForm {
  padding: 0% 10%;
  width: 100%;

  .emailDiv {
    padding-bottom: 6%;
    width: 70%;
    margin: auto;
    label {
      float: left;
      font-size: 21px;
      color: #9097a2;
      font-family: "L-Medium";
      line-height: 100%;
    }
    input {
      border: none;
      border-bottom: 1px solid #b6c0d1;
      width: 100%;
      height: 40px;
      outline: none;
      font-size: 21px;
      font-family: "L-Light";
    }

    .passwordDiv {
      display: flex;
      width: 100%;
      justify-content: center;
      position: relative;

      .eye {
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .loginBtn {
    width: 250px;
    height: 50px;
    border-radius: 0.35vh;
    background: #49aa98;
    border: none;
    outline: none;
    font-size: 21px;
    color: white;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-family: "L-Regular";
    letter-spacing: 0.5px;
    transition: all 0.2s linear;

    &:hover {
      letter-spacing: 1.5px;
      background: #49aa98c2;
    }
  }
}

.signupForm {
  padding: 0% 10%;
  width: 80%;
  margin: 0 auto;

  .passwordField {
    position: relative;

    .signupEye {
      position: absolute;
      right: 4%;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .formField {
    padding: 2.5% 0%;

    .firstName {
      height: 60px;
      width: 100%;
      border: none;
      background: #f5f8fd;
      padding-left: 25px;
      font-size: 24px;
      outline: none;
      font-family: "L-Light";

      &::placeholder {
        color: #b4c0d3;
      }
    }
  }
  .termsAgreed {
    font-size: 19px;
    font-family: "L-Regular";
    padding: 5% 0%;
    color: #9097a2;

    .formCheck {
      padding: 0;
    }

    .styledCheckbox {
      position: absolute;
      opacity: 0;

      & + .topLabel {
        position: relative;
        cursor: pointer;
        padding: 0;
        -moz-user-select: none;
        user-select: none;
        color: #9097a2;
      }

      & + .topLabel::before {
        content: "";
        margin-right: 10px;
        margin-bottom: 2px;
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        background: #e1e6ed;
      }

      &:checked + .topLabel:before {
        background: #62bfae;
      }

      &:disabled + .topLabel {
        color: #b8b8b8;
        cursor: auto;
      }

      &:disabled + .topLabel:before {
        box-shadow: none;
        background: #ddd;
      }
    }
  }

  .signupBtn {
    background: #62bfae;
    border: none;
    font-size: 20px;
    font-family: "L-Regular";
    width: 250px;
    height: 50px;
    border-radius: 0.35vh;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.5px;
    transition: all 0.2s linear;

    &:hover {
      letter-spacing: 1.5px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .login {
    .firstDiv {
      padding-top: 3%;
      .welcomeSec {
        height: 25%;
        .mindsumLogo {
          width: 220px;
          height: 80px;
        }

        .welcome {
          padding-top: 0%;
          color: #9097a2;
          line-height: 100%;
          font-size: 28px;
          font-family: "L-Light";
        }
        .signIn {
          font-size: 18px;
          color: #9097a2;
          font-family: "L-Light";
          line-height: 100%;
        }
      }

      .loginDiv {
        height: 40%;
        .forgetPassword {
          font-size: 18px;
          color: #9097a2;
          margin-bottom: 3%;
          font-family: "L-Medium";
        }
      }

      .loginFooter {
        padding: 5% 3% 3% 14%;

        .content {
          .notReg {
            font-size: 22px;
          }

          .btnDiv {
            .joinBtn {
              width: 200px;
              height: 40px;
              font-size: 14px;
            }
          }
        }
      }

      .alreadyReg {
        font-size: 18px;
        font-family: "L-Medium";
        padding: 3% 0%;
        color: #9097a2;
      }
    }

    .loginForm {
      padding-top: 1%;
      .emailDiv {
        padding-bottom: 6%;
        label {
          font-size: 18px;
        }

        input {
          height: 35px;
        }
      }

      .passwordDiv {
        .eye {
          width: 25px;
          height: 25px;
        }
      }

      .loginBtn {
        width: 200px;
        height: 40px;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .signupForm {
      padding: 0% 10%;

      .signupEye {
        width: 25px;
        height: 25px;
      }

      .formField {
        .firstName {
          height: 45px;

          border: none;
          background: #f5f8fd;
          padding-left: 20px;
          font-size: 18px;
          outline: none;
          font-family: "L-Light";

          &::placeholder {
            color: #b4c0d3;
          }
        }
      }
      .termsAgreed {
        font-size: 16px;
        font-family: "L-Regular";
        padding: 5% 0%;
        color: #9097a2;
      }

      .signupBtn {
        background: #62bfae;
        border: none;
        font-family: "L-Regular";
        width: 200px;
        height: 40px;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .login {
    height: 100vh;

    .firstDiv {
      width: 100%;
      height: auto;

      .welcomeSec {
        .mindsumLogo {
          width: 300px;
          height: 120px;
        }

        .welcome {
          font-size: 40px;
        }

        .signIn {
          font-size: 30px;
        }
      }

      .loginDiv {
        margin: 10% 0%;
      }

      .loginFooter {
        padding: 5% 3% 3% 14%;

        .loginFooterLink {
          padding: 5% 10%;
          margin: 3% 0%;
          width: 75%;
          display: flex;
          justify-content: center;
        }
      }
    }

    .secondDiv {
      display: none;
    }

    .loginForm {
      .emailDiv {
        padding-bottom: 6%;
        label {
          font-size: 21px;
        }

        input {
          height: 50px;
        }
      }
    }

    .signupForm {
      padding: 5% 10% 2% 10%;

      .formField {
        padding: 2% 0%;
        .firstName {
          height: 50px;

          font-size: 22px;
        }
      }
      .termsAgreed {
        font-size: 18px;

        .checkBox {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .login {
    height: auto !important;
    .firstDiv {
      width: 100%;
      padding-top: 6%;

      height: auto;

      .welcomeSec {
        .mindsumLogo {
          width: 50%;
        }

        .welcome {
          font-size: 24px;
        }
      }

      .loginDiv {
        height: auto;
        padding-top: 8%;
        padding-bottom: 15%;
      }

      .loginFooter {
        padding: 8% 3% 8% 3%;
        height: auto;
        margin-top: 10%;
        background-color: #f9ddd2;

        .content {
          padding-left: 0%;
          align-items: center;

          .notReg {
            font-size: 18px;
          }

          .btnDiv {
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100% !important;
            padding: 1% 0%;

            .joinBtn {
              margin: 5px 0px;
              font-size: 14px;

              .joinLink {
                font-size: 14px;
              }
            }
          }

          .loginFooterLink {
            padding: 3% 2%;
          }
        }
      }

      .signupFooter {
        padding-bottom: 5% !important;
        padding-top: 15% !important;
      }
    }

    .secondDiv {
      display: none;
    }
  }

  .loginForm {
    padding: 0% 2%;
    width: 100%;

    .emailDiv {
      padding-bottom: 8%;
      width: 80%;
      margin: auto;
      label {
        float: left;
        font-size: 21px;
        color: #9097a2;
        font-family: "L-Medium";
        line-height: 100%;
      }
      input {
        border: none;
        border-bottom: 1px solid #b6c0d1;
        width: 100%;
        height: 40px;
        outline: none;
        font-size: 21px;
        font-family: "L-Light";
      }

      .passwordDiv {
        display: flex;
        width: 100%;
        justify-content: center;
        position: relative;

        .eye {
          position: absolute;
          right: 2%;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }

  .signupForm {
    width: 100%;
    padding: 5% 10% !important;

    .termsAgreed {
      font-size: 18px;
      padding: 8% 0% !important;
      display: flex;
      align-items: center;

      .checkBox {
        width: 18px;
        height: 18px;
      }

      .labelTxt {
        text-align: left;
        padding-left: 15px;
        font-size: 16px;
      }
    }
  }
}
