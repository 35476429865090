.successPopup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .innerDiv {
    width: 741px;
    min-height: 400px;
    padding: 2% 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 11px;
    position: relative;

    .closeAlert {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;
    }

    img {
      margin-left: -20%;
    }

    .thankyou {
      font-size: 40px;
      color: #548df7;
      font-family: "L-Bold";
      padding: 10px 0;
    }

    .message {
      margin: 0;
      line-height: 130%;
      padding: 0 10% 30px 10%;
      text-align: center;
      color: #9097a2;
      font-size: 22px;
      font-family: "L-Regular";
    }

    .sessionOverviewBtn {
      border: none;
      outline: none;
      background: #62bfae;
      color: white;
      text-transform: uppercase;
      font-family: "L-Regular";
      font-size: 20px;
      padding: 5px 10px;
      border-radius: 0.35vh;
      width: 268px;
      height: 50px;

      &:hover {
        background: #62bfaee0;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .successPopup {
    .innerDiv {
      width: 500px;
      // height: 300px;

      .closeAlert {
        position: absolute;
        top: 5%;
        right: 5%;
        cursor: pointer;

        .crossIcon {
          width: 20px;
          height: 20px;
        }
      }

      .message {
        font-size: 18px;
      }

      .sessionOverviewBtn {
        font-size: 14px;
      }

      .succesPopupSvg {
        width: 200px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .alertDialog {
    .innerDiv {
      width: 300px;
      // height: 200px;

      .errorTxt {
        font-size: 18px;
      }
    }
  }

  .successPopup {
    .innerDiv {
      width: 280px;
      height: 180px;

      .closeAlert {
        position: absolute;
        top: 5%;
        right: 5%;
        cursor: pointer;

        .crossIcon {
          width: 18px;
          height: 18px;
        }
      }

      .message {
        font-size: 12px;
      }

      .sessionOverviewBtn {
        font-size: 12px;
      }

      .succesPopupSvg {
        width: 100px;
      }
    }
  }
}
