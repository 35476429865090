.ProfileImage {
  height: 80px;
  width: 80px;
  object-fit: cover;
  object-position: center;
  background: white;
}

@media only screen and (max-width: 1600px) {
  .ProfileImage {
    height: 80px;
    width: 80px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .ProfileImage {
    height: 60px;
    width: 60px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .ProfileImage {
    height: 70px;
    width: 70px;
  }
}
