.coverBg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fdd9ce;
  z-index: -10;
}

.form2Div {
  width: 75%;
}

.stepOneDiv {
  width: 100%;
  height: 100%;
  background: #fdd9ce;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;

  .mainCard {
    width: 75%;
    height: 100%;
    margin: 5% auto;
    background: #f9f6f4;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 1% 3% 3% 3%;

    .step1Nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3%;
      width: 100%;

      .firstPart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 33%;

        .spanText {
          font-size: 14px;
          font-family: "L-Medium";
          text-transform: uppercase;
          padding-right: 5px;
          margin-bottom: 10px;
        }

        .active {
          font-size: 16px;
          color: #79bcae;
        }
      }

      .secondPart {
        width: 33%;
        display: flex;
        justify-content: center;

        .navLogo {
          .ImgFluid {
            width: 300px;
          }
        }
      }
      .thirdPart {
        width: 33%;
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
      }
    }

    .formDiv {
      padding: 0% 15%;

      .loader {
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &__spin {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          border: 3px solid;
          border-color: #62bfae #62bfae transparent transparent;
          animation: rotation 1s linear infinite;

          &:after,
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border: 3px solid;
            border-color: transparent transparent #f3ab8e #f3ab8e;
            width: 42px - 8px;
            height: 42px - 8px;
            border-radius: 50%;
            animation: rotationBack 0.5s linear infinite;
            transform-origin: center center;
          }
          &:before {
            width: 42px - 16px;
            height: 42px - 16px;
            border-color: #fff #fff transparent transparent;
            animation: rotation 1.5s linear infinite;
          }
          @keyframes rotationBack {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(-360deg);
            }
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }

      .clientAvatar {
        position: relative;
        cursor: pointer;
        transition: all 0.6s linear;

        &:hover {
          .editHover {
            display: block;
          }
        }

        .editHover {
          display: none;
          position: absolute;
          bottom: 7%;
          letter-spacing: 0.4px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 14px;
          font-family: "L-Regular";
          text-transform: uppercase;
          transition: all 0.6s linear;
        }
      }

      .clientName {
        font-size: 36px;
        font-family: "L-Regular";
        text-transform: capitalize;
        line-height: 120%;
      }

      .clientEmail {
        font-size: 22px;
        font-family: "L-Light";
      }

      .aboutYouFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }

    .step1Form {
      padding: 2% 0%;

      .socialLinksRecomm {
        font-size: 15px;
        font-family: "L-Regular";
      }

      .contactDiv {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 1% 0%;

        .contactOne {
          display: flex;
          width: 45%;
          flex-direction: column;
          position: relative;

          .contactInput {
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #707070;
            outline: none;
            font-size: 19px;
            font-family: "L-Light";

            & + .contactLabel {
              color: #000;
              font-family: "L-Light";
              position: relative;
              font-size: 19px;
              text-transform: capitalize;
              top: -34px;
              left: 0px;
              pointer-events: none;
              transition: all 0.25s ease;
            }
            &:focus,
            &:not(:placeholder-shown),
            &:-webkit-autofill {
              background-color: transparent;
              & + .contactLabel {
                top: -50px;
                left: 0px;
                font-size: 14px;
                font-family: "L-Regular";
                color: #000;
              }
            }
          }
        }
      }

      .addressDiv {
        display: flex;
        flex-direction: column;
        padding: 0%;

        .addressInput {
          width: 100%;
          background: transparent;
          border: none;
          border-bottom: 1px solid #707070;
          outline: none;
          font-size: 19px;
          font-family: "L-Light";

          & + .addressLabel {
            color: #000;
            font-family: "L-Light";
            position: relative;
            font-size: 19px;
            top: -34px;
            left: 0px;
            pointer-events: none;
            transition: all 0.25s ease;
          }
          &:focus,
          &:not(:placeholder-shown),
          &:-webkit-autofill {
            background-color: transparent;
            & + .addressLabel {
              top: -50px;
              left: 0px;
              font-size: 14px;
              font-family: "L-Regular";
              color: #000;
            }
          }
        }

        .textAreaLabel {
          font-size: 19px;
          font-family: "L-Regular";
          line-height: 100%;
          letter-spacing: 0.3px;
          margin-bottom: 15px;
        }

        small {
          font-size: 14px;
          padding-left: 5px;
        }

        .textArea {
          background: #f0ebe7;
          border: none;
          border-bottom: 1px solid #707070;
          outline: none;
          font-size: 19px;
          font-family: "L-Light";
          padding: 10px;
          border-bottom: none !important;
        }
      }

      .socialLinks {
        padding-top: 5%;
        .socialLinksText {
          font-size: 19px;
          font-family: "L-Regular";
          margin-bottom: 5px;
        }

        .socialInputDiv {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 10%;
          row-gap: 10px;

          .socialInput {
            width: 100%;
            background: transparent;
            border: none;
            border-bottom: 1px solid #707070;
            height: 50px;
            margin-bottom: 4%;
            outline: none;
            font-size: 19px;
            font-family: "L-Light";
          }
        }
      }

      .step1BtnDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6% 0%;

        .step1Btn {
          font-size: 16px;
          border-radius: 0.35vh;
          font-family: "L-Regular";
          background: #62bfae;
          width: 200px;
          height: 45px;
          border: none;
          color: white;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          transition: all 0.2s linear;
          &:hover {
            letter-spacing: 1.5px;
            background: #62bfaed0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .stepOneDiv {
    height: 100%;
    overflow: scroll;

    .mainCard {
      height: auto;

      .step1Nav {
        .firstPart {
          .spanText {
            font-size: 12px;
            margin-bottom: 5px;
          }

          .active {
            font-size: 14px;
          }
        }

        .secondPart {
          .navLogo {
            .ImgFluid {
              width: 220px;
            }
          }
        }
      }

      .formDiv {
        padding: 0% 12%;

        .clientName {
          font-size: 28px;

          line-height: 100%;
        }

        .clientEmail {
          font-size: 18px;
          font-family: "L-Light";
        }
      }

      .step1Form {
        .contactDiv {
          .contactOne {
            .contactLabel {
              font-size: 16px;
            }

            .contactInput {
              font-size: 18px;

              & + .contactLabel {
                font-size: 18px;
              }
            }
          }
        }

        .addressDiv {
          display: flex;
          flex-direction: column;
          padding: 0%;

          .addressLabel {
            font-size: 17px;
          }

          .addressInput {
            font-size: 18px;

            & + .addressLabel {
              font-size: 18px;
            }
          }

          .textAreaLabel {
            font-size: 14px;
          }

          small {
            font-size: 13px;
            padding-left: 5px;
          }

          .textArea {
            font-size: 18px;
          }
        }

        .socialLinks {
          padding-top: 5%;
          .socialLinksText {
            font-size: 17px;
            font-family: "L-Regular";
            margin-bottom: 5px;
          }

          .socialInputDiv {
            width: 100%;
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 10%;

            .socialInput {
              height: 40px;
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .form2Div {
    width: 100%;
  }

  .stepOneDiv {
    height: 100%;
    padding: 8% 0%;

    .mainCard {
      width: 95%;

      .step1Nav {
        .firstPart {
          .spanText {
            font-size: 8px;
          }

          .active {
            font-size: 10px;
          }
        }

        .secondPart {
          width: 40%;

          .navLogo {
            .ImgFluid {
              width: 200px;
            }
          }
        }
      }

      .formDiv {
        padding: 5% 8%;

        .aboutYouFooter {
          padding-top: 3%;
        }
      }

      .step1Form {
        .contactDiv {
          .contactOne {
            .contactLabel {
              font-size: 14px;
              color: #000;
              font-family: "L-Regular";
              line-height: 100%;
              letter-spacing: 0.3px;
            }

            .errorText {
              margin-top: -20px !important;
            }
          }
        }

        .jobTitleDiv {
          padding-bottom: 3%;
        }

        .addressDiv {
          .addressLabel {
            font-size: 14px;

            small {
              font-size: 14px;
              padding-left: 5px;
            }
          }

          .addressInput {
            width: 100%;
            height: 40px;
            background: transparent;
            border: none;
            border-bottom: 1px solid #707070;
            outline: none;
            font-size: 19px;
            font-family: "L-Light";
          }

          .textArea {
            font-size: 19px;
            font-family: "L-Light";
          }
        }

        .socialLinks {
          .socialLinksText {
            font-size: 19px;
            font-family: "L-Regular";
            margin-bottom: 5px;
          }

          .socialInputDiv {
            .socialInput {
              font-size: 14px;
            }
          }
        }

        .step1BtnDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3% 0%;

          .step1Btn {
            font-size: 16px;
            border-radius: 0.35vh;
            font-family: "L-Regular";
            background: #62bfae;
            width: 200px;
            height: 45px;
            border: none;
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            transition: all 0.2s linear;
            &:hover {
              letter-spacing: 1.5px;
              background: #62bfaed0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .form2Div {
    width: 100%;
  }

  .stepOneDiv {
    width: 100%;
    height: 100%;
    padding: 5% 0%;
    background: #fdd9ce;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    .mainCard {
      width: 95%;
      height: auto;
      margin: 3% auto;
      background: rgba(255, 255, 255, 0.815);
      box-shadow: 0 8px 32px 0 rgba(172, 173, 182, 0.37);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      padding: 1% 1% 6% 1%;

      .step1Nav {
        flex-direction: column;
        justify-content: center;
        padding: 10% 5% 5% 5%;

        .firstPart {
          width: 100%;
          justify-content: center;
          order: 3;
          padding: 3% 0%;

          .spanText {
            margin-bottom: 10px;
            font-size: 8px;
          }

          .active {
            font-size: 10px;
          }
        }

        .secondPart {
          width: 100%;
          order: 1;

          .navLogo {
            .ImgFluid {
              width: 180px;
            }
          }
        }

        .thirdPart {
          order: 2;
          padding: 5% 0%;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .formDiv {
        padding: 2% 3%;

        .clientName {
          font-size: 20px;
        }

        .clientEmail {
          font-size: 16px;
        }

        .aboutYouFooter {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }

      .step1Form {
        padding: 2% 0%;

        .contactDiv {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
          padding: 0%;

          .contactOne {
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 3% 0%;

            .contactLabel {
              font-size: 13px;
            }

            .contactInput {
              height: 30px;
              font-size: 15px;
            }
          }
        }

        .addressDiv {
          .addressLabel {
            font-size: 13px;
          }

          .addressInput {
            height: 30px;
            font-size: 15px;
          }

          .bioTopPadding {
            padding-top: 4% !important;
          }
          .textArea {
            font-size: 15px;
            line-height: 130%;
          }
        }

        .socialLinks {
          padding-top: 20px;
          .socialLinksText {
            font-size: 19px;
          }

          .socialInputDiv {
            width: 100%;

            display: grid;
            grid-template-columns: auto;
            column-gap: 10%;

            .socialInput {
              height: 40px;
              font-size: 14px;
            }
          }
        }

        .step1BtnDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3% 0%;

          .step1Btn {
            font-size: 16px;
            border-radius: 0.35vh;
            font-family: "L-Regular";
            background: #62bfae;
            width: 200px;
            height: 45px;
            border: none;
            color: white;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            transition: all 0.2s linear;
            &:hover {
              letter-spacing: 1.5px;
              background: #62bfaed0;
            }
          }
        }
      }
    }
  }
}
