.adminHome {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &__innerDiv {
    margin-bottom: 5%;
    height: 75vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .gridContainer {
      display: grid;
      overflow-y: auto;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: flex-start;
      gap: 30px;
      padding: 1%;

      &::-webkit-scrollbar {
        display: none !important;
      }

      .gridItem {
        background: white;
        border-radius: 10px;
        border-radius: 18px;
        overflow: hidden;
        height: 276px;
        position: relative;

        &:hover {
          background-color: #2d364f;
          color: #fff;
        }

        &__titleDiv {
          background-color: #62bfae;
          display: flex;
          align-items: center;
          height: 70px;
          padding: 0 5%;
        }

        &__title {
          font-size: 28px;
          font-family: "L-Medium";
          color: white;
          margin-bottom: 0;
          margin-left: 15px;
        }

        &__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5%;

          :nth-child(2) {
            p {
              color: #548df7;
            }
          }

          :nth-child(3) {
            p {
              color: #ff5f5f;
            }
          }

          .contentDiv {
            width: 31.333%;
            flex-wrap: wrap;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__heading {
              order: 2;
              font-size: 16px;
              font-family: "L-Medium";
              text-transform: capitalize;
              line-height: 130%;
              letter-spacing: 0.5px;
            }

            &__data {
              font-size: 28px;
              font-family: "L-Medium";
              text-transform: capitalize;
              margin-bottom: 5px;
            }
          }
        }

        &__link {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 10%;
          left: 50%;
          transform: translateX(-50%);
          font-size: 19px;
          font-family: "L-Medium";

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .footerLink {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1600px) {
  .adminHome {
    &__innerDiv {
      .gridContainer {
        gap: 25px;

        .gridItem {
          height: 220px;

          &__titleDiv {
            height: 50px;

            svg {
              width: 25px;
              height: 25px;
            }
          }

          &__title {
            font-size: 20px;
          }

          &__content {
            .contentDiv {
              &__heading {
                font-size: 12px;
              }

              &__data {
                font-size: 18px;
              }
            }
          }

          &__link {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .adminHome {
    &__innerDiv {
      .gridContainer {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .adminHome {
    &__innerDiv {
      .gridContainer {
        gap: 25px;
        grid-template-columns: 1fr 1fr;

        .gridItem {
          height: 220px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .adminHome {
    height: auto;
    &__innerDiv {
      height: auto;

      .gridContainer {
        gap: 25px;
        grid-template-columns: 1fr;

        .gridItem {
          height: 200px;

          &__titleDiv {
            svg {
              width: 20px;
              height: 20px;
            }
          }

          &__title {
            font-size: 16px;
          }

          &__content {
            .contentDiv {
              &__heading {
                font-size: 12px;
              }

              &__data {
                font-size: 18px;
              }
            }
          }

          &__link {
            font-size: 12px;
          }
        }
      }
    }

    .footerLink {
      display: none;
    }
  }
}
