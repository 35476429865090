.newEnquiries {
  &__innerDiv {
    min-height: 60vh !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .newEnquiries {
    &__innerDiv {
      padding-bottom: 130px;
    }
  }
}
