.chatsSetting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;

  &__innerDiv {
    margin-bottom: 5%;

    .topHead {
      display: flex;
      align-items: center;

      .backArrow {
        margin: 0;
        font-size: 18px;

        svg {
          margin-right: 20px;
          width: 22px;
          height: 22px;
        }
      }
    }

    .chats {
      flex: 1;
      display: flex;
      width: 100%;
      height: 60vh;
      align-items: flex-start;
      justify-content: space-between;
      padding: 2% 0 0 0;

      .chatsFirstCol {
        width: 40%;
        padding: 3% 1%;
        height: 100%;
        background: white;
        border-radius: 10px;
        overflow-y: auto;
      }

      .chatsSecondCol {
        width: 59%;
        padding: 0% 0% 1% 0%;
        height: 100%;
        border-radius: 10px;
        background: white;
        overflow-y: auto;
      }
    }
  }

  .chatsFooter {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .chatsSetting {
    .chats {
      padding: 1% 0;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .chatsSetting {
    height: 100%;
    justify-content: center;
    overflow: hidden;

    &__innerDiv {
      min-height: auto !important;
      margin-bottom: 6%;

      .chats {
        height: 50vh !important;
        overflow-y: hidden;

        .chatsFirstCol {
          height: 50vh !important;
          overflow-y: auto;
        }

        .chatsSecondCol {
          height: 50vh !important;
          overflow-y: auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .chatsSetting {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;

    &__innerDiv {
      height: 70vh;

      .chats {
        flex: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 88%;
        align-items: flex-start;
        justify-content: space-between;
        padding: 2% 0;

        .chatsFirstCol {
          width: 100%;
          padding: 3% 1%;
          height: 100%;
          overflow-y: auto;
          background: white;
          border-radius: 10px;
        }

        .chatsSecondCol {
          position: absolute;
          width: 100%;
          height: 70%;
          overflow-y: auto;
          padding: 0% 0% 2% 0%;
          border-radius: 10px;
          background: white;
        }
      }
    }

    .chatsFooter {
      display: none;
    }
  }
}
