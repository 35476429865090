.createProfile {
  width: 100%;
  display: flex;
  min-height: 100vh;
  overflow: hidden;

  .reduceTopPadding {
    padding-top: 2% !important;
  }

  .firstDiv {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 5%;
    padding-bottom: 3%;
    align-items: center;
    text-align: center;

    .introDiv {
      .name {
        font-size: 45px;
        color: #000;
        font-family: "L-Light";
        text-transform: capitalize;
        padding-top: 2%;
      }

      .intro {
        font-size: 24px;
        font-family: "L-Light";
        padding: 2% 5% 0% 5%;
        text-align: center;
        letter-spacing: 0.5px;
      }

      .proceedText {
        width: 250px;
        height: 50px;
        background: #62bfae;
        font-size: 20px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 2%;
        position: relative;
        border-radius: 0.35vh;

        .proceedBtn {
          width: 100%;
          height: 100%;
          text-transform: uppercase;
          text-align: center;
          margin: auto;
          color: white;
          text-decoration: none;

          letter-spacing: 0.5px;
          transition: all 0.2s linear;

          .proceed {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:hover {
          .proceedBtn {
            letter-spacing: 1.5px;
            background: #76cebeb0;
          }
        }
      }

      .skipNow {
        color: #9097a2;
        font-size: 21px;
        font-family: "L-Regular";
        text-decoration: none;
        background-color: #fff;
        color: #62bfae;
        border: none;
        outline: none;
        height: 40px;
        transition: all 0.3s ease-out;

        &:hover {
          border-bottom: 1.5px solid #62bfae;
          letter-spacing: 1.2px;
        }
      }

      .recommText {
        font-size: 16px;
        color: #9097a2;
        margin: 0;
        padding: 10px 0;
        font-family: "L-Regular";
      }
    }
  }

  .secondDiv {
    width: 45%;

    .heroImg {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .createProfile {
    .firstDiv {
      padding-top: 6%;
      padding-bottom: 3%;

      .logoDiv {
        .mindsumLogo {
          width: 220px;
          height: 80px;
        }
      }

      .introDiv {
        .name {
          font-size: 35px;
        }

        .intro {
          font-size: 18px;

          padding: 2% 15%;
        }

        .proceedText {
          width: 200px;
          height: 40px;
          font-size: 16px;
        }

        .skip {
          color: #9097a2;
          font-size: 18px;
          font-family: "L-SBold";
        }
      }
    }

    .secondDiv {
      width: 45%;

      .heroImg {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .createProfile {
    overflow: scroll;
    flex-direction: column;

    .firstDiv {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
      height: 100vh;

      .introDiv {
        padding: 10% 0%;

        .name {
          font-size: 50px;
        }

        .intro {
          font-size: 20px;
          padding: 2% 10%;
        }
      }

      .createProfileFooter {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 4% 0;
      }
    }

    .secondDiv {
      display: none;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .createProfile {
    overflow: scroll;
    flex-direction: column;

    .firstDiv {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
      height: 100vh;

      .introDiv {
        padding: 10% 0%;

        .name {
          font-size: 30px;
        }

        .intro {
          font-size: 17px;
          padding: 2% 8%;
        }
      }

      .createProfileFooter {
        padding-bottom: 5%;
      }
    }

    .secondDiv {
      display: none;
    }
  }
}
