.newEnquiries__table {
  height: 65vh;

  &::-webkit-scrollbar {
    display: none;
  }
}

.newEnquiriesTable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  padding: 0 2%;
  border-bottom: 1px solid #f9ddd2;
  background: white;
  color: black;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background: #62bfae !important;
    color: white;

    .newEnquiriesTableRow {
      .sessionDate {
        small {
          color: white;
        }
      }

      .sessionType {
        .mySessionVideo {
          fill: #fff !important;
        }

        .sessionTypeSpan {
          color: white !important;
        }
      }
    }

    .newEnquiriesBtn {
      .acceptBtn {
        background: white;
        color: #62bfae;
      }
    }
  }

  .newEnquiriesTableRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    .tableRowDiv {
      width: 20%;
      display: flex;
      justify-content: center;
    }

    .therapist {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 3%;

      .newEnquiriesProfile {
        width: 60px;
        height: 60px;

        img {
          width: 60px;
          height: 60px;
          padding: 2px;
          background: white;
          border: 1px solid #62bfae;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
        }
      }

      &__nameDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 10px;

        .name {
          font-size: 18px;
          font-family: "L-Regular";
          margin-bottom: 0;
          text-transform: capitalize;
        }
      }
    }

    .sessionDate {
      display: flex;
      flex-direction: column;
      align-items: center;

      big {
        font-size: 16px;
        font-family: "L-Regular";
      }
      small {
        font-size: 16px;
        font-family: "L-Regular";
        color: #9097a2;
      }
    }

    .sessionType {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 4%;

      .sessionTypeSpan {
        display: flex;
        align-items: center;
        color: #9097a2;
        font-size: 16px;
        font-family: "L-Regular";
        padding-top: 10px;
        flex-wrap: wrap;

        span {
          padding: 0 3px;
          text-transform: capitalize;
        }
      }
    }

    .sessionDurationDiv {
      justify-content: flex-start;
      padding-left: 4%;
    }
  }

  .newEnquiriesBtn {
    transition: all 0.3s ease;
    display: flex;

    .declineBtn,
    .acceptBtn {
      width: 115px;
      height: 43px;
      background: #ff5f5f;
      border: none;
      outline: none;
      font-size: 16px;
      font-family: "L-Regular";
      color: white;
      text-transform: uppercase;
      border-radius: 0.35vh;
      transition: all 0.2s linear;

      &:hover {
        letter-spacing: 0.6px;
      }
    }

    .acceptBtn {
      background: #62bfae;
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .newEnquiriesTable {
    width: 100%;
    height: 90px;

    .newEnquiriesTableRow {
      .therapist {
        .newEnquiriesProfile {
          width: 60px;
          height: 60px;

          img {
            width: 60px;
            height: 60px;
            padding: 2px;
            background: white;
            border: 1px solid #62bfae;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        &__nameDiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 10px;

          .name {
            font-size: 13px;
            word-wrap: break-word;
          }
        }
      }

      .sessionDate {
        display: flex;
        flex-direction: column;
        align-items: center;

        big {
          font-size: 13px;
          font-family: "L-Regular";
        }
        small {
          font-size: 13px;
          font-family: "L-Regular";
          color: #9097a2;
        }
      }

      .sessionType {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 4%;

        .mySessionVideo {
          width: 25px;
          height: 25px;
        }

        .sessionTypeSpan {
          display: flex;
          align-items: center;
          color: #9097a2;
          font-size: 16px;
          font-family: "L-Regular";
          padding-top: 10px;
          flex-wrap: wrap;

          span {
            padding: 0 3px;
            text-transform: capitalize;
          }
        }
      }
    }

    .newEnquiriesBtn {
      display: flex;

      .declineBtn,
      .acceptBtn {
        width: 90px;
        height: 35px;
        font-size: 14px;
      }

      .acceptBtn {
        background: #62bfae;
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1380px) {
  .newEnquiriesTable {
    .newEnquiriesBtn {
      flex-direction: column;
      align-items: center;

      .acceptBtn {
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .newEnquiriesTable {
    width: 100%;
    height: 90px;

    .newEnquiriesTableRow {
      .therapist {
        .newEnquiriesProfile {
          width: 60px;
          height: 60px;
          display: none;

          img {
            width: 60px;
            height: 60px;
            padding: 2px;
            background: white;
            border: 1px solid #62bfae;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
          }
        }

        &__nameDiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 10px;

          .name {
            font-size: 13px;
          }
        }
      }

      .sessionDate {
        display: flex;
        flex-direction: column;
        align-items: center;

        big {
          font-size: 13px;
          font-family: "L-Regular";
        }
        small {
          font-size: 13px;
          font-family: "L-Regular";
          color: #9097a2;
        }
      }

      .sessionType {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 4%;

        .mySessionVideo {
          width: 25px;
          height: 25px;
        }

        .sessionTypeSpan {
          display: flex;
          align-items: center;
          color: #9097a2;
          font-size: 16px;
          font-family: "L-Regular";
          padding-top: 10px;
          flex-wrap: wrap;

          span {
            padding: 0 3px;
            text-transform: capitalize;
          }
        }
      }
    }

    .newEnquiriesBtn {
      display: flex;
      flex-direction: column;
      align-items: center;

      .declineBtn,
      .acceptBtn {
        width: 90px;
        height: 35px;
        font-size: 14px;
      }

      .acceptBtn {
        background: #62bfae;
        margin-left: 0px;
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .newEnquiriesTable {
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 5% 0;

    .newEnquiriesTableRow {
      width: 100%;

      .tableRowDiv {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .therapist {
        .newEnquiriesProfile {
          display: none;
        }

        &__nameDiv {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 10px;

          .name {
            font-size: 13px;
          }
        }
      }

      .sessionDate {
        display: flex;
        flex-direction: column;
        align-items: center;

        big {
          font-size: 13px;
          font-family: "L-Regular";
        }
        small {
          font-size: 13px;
          font-family: "L-Regular";
          color: #9097a2;
        }
      }

      .sessionType {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-left: 0%;
        padding-top: 5px;

        .mySessionVideo {
          width: 25px;
          height: 25px;
        }

        .sessionTypeSpan {
          display: flex;
          align-items: center;
          color: #9097a2;
          font-size: 12px;
          font-family: "L-Regular";
          padding-top: 5px;
          flex-wrap: wrap;

          span {
            padding: 0 3px;
            text-transform: capitalize;
          }
        }
      }

      .sessionDurationDiv {
        display: none;
      }
    }

    .newEnquiriesBtn {
      display: flex;
      flex-direction: row;
      margin: 10px 0;

      .declineBtn,
      .acceptBtn {
        width: 90px;
        height: 35px;
        font-size: 14px;
      }

      .acceptBtn {
        background: #62bfae;
        margin-left: 10px;
      }
    }
  }
}
