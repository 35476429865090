.timeInputDiv {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  &__innerDiv {
    width: 500px;
    min-height: 500px;
    max-height: 600px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 16px;
    position: relative;

    .topText {
      margin: 0;
      font-size: 18px;
      font-family: "L-Regular";
      padding-bottom: 2%;
      text-align: center;
    }

    .timeCalendar {
      border: 1px solid rgb(179, 177, 177) !important;
    }

    .firstInputDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40%;
      height: 60%;
      margin: 10px 0;

      .inputLabel {
        margin: 0;
      }
    }

    .errorMessage {
      color: #ff5f5f;
      margin: 0;
    }

    .btnDiv {
      padding: 5% 0;

      button {
        width: 80px;
        height: 30px;
        margin: 0 10px;
        font-size: 14px;
        background: white;
        color: rgb(128, 126, 126);
        border: 1px solid rgb(128, 126, 126);
        outline: none;
        border-radius: 2px;
      }

      :first-child {
        background: #62bfae;
        color: white;
        border: none;
      }
    }

    .loader {
      margin-top: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &__spin {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 3px solid;
        border-color: #62bfae #62bfae transparent transparent;
        animation: rotation 1s linear infinite;

        &:after,
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          border: 3px solid;
          border-color: transparent transparent #f3ab8e #f3ab8e;
          width: 42px - 8px;
          height: 42px - 8px;
          border-radius: 50%;
          animation: rotationBack 0.5s linear infinite;
          transform-origin: center center;
        }
        &:before {
          width: 42px - 16px;
          height: 42px - 16px;
          border-color: #fff #fff transparent transparent;
          animation: rotation 1.5s linear infinite;
        }
        @keyframes rotationBack {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-360deg);
          }
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .timeInputDiv {
    &__innerDiv {
      width: 300px;
      min-height: 450px;

      .topText {
        margin: 0;
        font-size: 18px;
        font-family: "L-Regular";
        padding-bottom: 2%;
      }

      .firstInputDiv {
        justify-content: center;

        .inputLabel {
          margin: 0;
        }
      }

      .errorMessage {
        color: #ff5f5f;
        margin: 0;
      }

      .btnDiv {
        padding: 5% 0;

        button {
          width: 80px;
          height: 30px;
          margin: 0 10px;
          font-size: 14px;
          background: white;
          outline: none;
        }

        :first-child {
          background: #62bfae;
          color: white;
          border: none;
        }
      }
    }
  }
}
