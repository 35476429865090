.resetPasswordLogo {
  height: 80px;
  padding: 25px;
}

.resetPassword {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  padding-bottom: 3%;

  .topComp {
    padding-top: 12%;
    .rpHeader {
      font-size: 40px;
      margin-bottom: 30px;
      text-align: center;
    }

    .svgImgDiv2 {
      display: flex;
      align-items: center;
      justify-content: center;

      flex-direction: column;

      .rptext {
        text-align: center;
        margin-bottom: 10px;
        font-size: 30px;
        font-family: "L-Regular";
        color: #2d364f;
        line-height: 130%;
      }

      .rptext2 {
        font-size: 26px !important;
        font-family: "L-Light";
      }
    }
  }
}

.resetPasswordForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0%;

  .resetPasswordField {
    position: relative;
    width: 500px;
    height: 60px;
    margin: 1.5% 0%;

    .resetPasswordInput {
      width: 100%;
      height: 100%;
      border: none;
      background: #f5f8fd;
      padding-left: 10px;
      font-size: 26px;
      outline: none;
      font-family: "L-Light";
      margin-bottom: 30px;
      color: black;
    }
    .signupEye {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 3%;
      cursor: pointer;
    }
  }

  .rpBtn {
    background: #62bfae;
    border: none;
    font-size: 20px;
    font-family: "L-Regular";
    width: 250px;
    height: 50px;
    margin: 2% 0%;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.5px;
    transition: all 0.2s linear;

    &:hover {
      letter-spacing: 1.5px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .resetPassword {
    width: 100%;
    position: relative;

    .topComp {
      padding-top: 6%;
      .rpHeader {
        font-size: 28px;
        margin-bottom: 30px;
        text-align: center;
      }

      .svgImgDiv2 {
        .svgImg {
          width: 200px;
          height: 200px;
        }

        .rptext {
          margin-bottom: 10px;
          font-size: 22px;
          line-height: 110%;
        }

        .rptext2 {
          font-size: 18px !important;
        }
      }
    }
  }

  .resetPasswordForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3% 0%;

    .resetPasswordField {
      position: relative;
      width: 500px;
      height: 45px;
      margin: 1.5% 0%;

      .resetPasswordInput {
        width: 100%;
        height: 100%;
        border: none;
        background: #f5f8fd;
        padding-left: 10px;
        font-size: 18px;
        outline: none;
        font-family: "L-Light";
        margin-bottom: 30px;
        color: black;
      }
      .signupEye {
        width: 24px;
        height: 24px;
      }
    }

    .rpBtn {
      font-size: 16px;
      width: 200px;
      height: 40px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .resetPassword {
    padding-bottom: 5%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .resetPassword {
    justify-content: space-between !important;

    width: 100%;
    position: relative;
    padding: 0% 5% 5% 5%;

    .topComp {
      .rpHeader {
        margin-bottom: 30px;
        margin-top: 10px;
        font-size: 22px;
      }

      .svgImgDiv2 {
        .svgImg {
          width: 130px;
          height: 130px;
          margin: 10px 0px;
        }
        .rptext {
          font-size: 18px;
        }

        .rptext2 {
          font-size: 12px;
        }
      }
    }

    .bottomComp {
      padding-bottom: 5%;
    }
  }

  .resetPasswordForm {
    padding: 3% 0%;

    .resetPasswordField {
      width: 300px;
    }
  }
}
