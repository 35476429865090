.UpcomingSessions {
  &__innerDiv {
    width: 100%;
    overflow-y: hidden;

    .upcomingSesParent {
      overflow-y: auto;
      height: 70vh;

      &::-webkit-scrollbar {
        display: none;
      }

      .upcomingSesList {
        min-height: 290px;
      }
    }

    .upcomingSesTitle {
      margin: 2% 3%;
      font-size: 21px;
      font-family: "L-Medium";
      background: #62bfae;
      color: white;
      padding: 15px 3%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-bottom: 0px;
    }
  }
}

.UpcomingSessionsList {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftArrrow {
    width: 2%;
    cursor: pointer;
  }

  .sliderDiv {
    width: 96%;
    height: 100%;
    position: relative;
  }

  .rightArrrow {
    width: 2%;
    cursor: pointer;
  }
}

.upcomingSesBottom {
  padding: 30px 3% 2% 3%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  flex-wrap: wrap;

  .greenBg {
    .pathOne {
      fill: #62bfae;
    }

    .pathTwo {
      fill: #fff;
    }

    &:hover {
      background: #62bfae;

      .footerLinkContent {
        .pathOne {
          fill: #fff;
        }

        .pathTwo {
          fill: #62bfae;
        }

        .footerLinkText {
          color: white;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  .redBg {
    .bookPathOne {
      fill: #ff5f5f;
    }

    .bookPathTwo {
      fill: #ff5f5f;
    }

    &:hover {
      background: #ff5f5f;

      .footerLinkContent {
        .bookPathOne {
          fill: #fff;
        }

        .bookPathTwo {
          fill: #fff;
        }

        .footerLinkText {
          color: white;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  .blueBg {
    .knowledgeRect {
      fill: #548df7;
    }

    .firstLine,
    .secondLine,
    .thirdLine {
      fill: #fff;
    }

    &:hover {
      background: #548df7;

      .footerLinkContent {
        .knowledgeRect {
          fill: #fff;
        }

        .firstLine,
        .secondLine,
        .thirdLine {
          fill: #548df7;
        }

        .footerLinkText {
          color: white;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  .footerLinkDiv {
    width: 100%;
    border-radius: 15px;
    background: white;
    height: 140px;

    .footerLinkContent {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px 20px 20px 30px;
      text-decoration: none;

      .footerLinkText {
        font-size: 21px;
        margin: 0;
        padding: 5px 0;
        font-family: "L-Medium";
        color: #9ca7b7;
        text-decoration: none;
        transition: all 0.2s linear;
      }
    }
  }
}

.verifyEmailMsg {
  margin: 0 3%;
  padding: 8px 0;
  text-align: center;
  font-size: 19px;
  font-family: "L-Medium";
  color: #ff5f5f;
  background-color: #dfd5dd;
  border: 2px solid #e7b7bd;
  border-radius: 31px;
}

.noSession {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 32% 2fr;
  padding: 0 3%;
  min-height: 380px;
  gap: 30px;
  color: #9097a2;
  margin-top: 2%;

  &__firstCol {
    width: 100%;
    background: white;
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    flex-direction: column;

    .UpcomingHeading {
      font-size: 21px;
      font-family: "L-Medium";
      background: #62bfae;
      color: white;
      padding: 10px 5%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-bottom: 0px;
    }

    .upcomingNoSession {
      font-size: 19px;
      font-family: "L-Regular";
      line-height: 180%;
      margin: 6%;
    }

    .viewTherapists {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 19px;
      font-family: "L-Medium";
      color: #62bfae;
      transition: all 0.3s ease-out;

      &:hover {
        text-decoration: none;
        letter-spacing: 1.2px;
      }
    }
  }

  &__secondCol {
    width: 100%;
    // display: none;
    background: white;
    padding-bottom: 2%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    .recommendedTherapist {
      font-size: 21px;
      font-family: "L-Medium";
      text-align: center;
      background: #548df7;
      padding: 10px 3%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      color: white;
    }

    .recommInstructionDiv {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 1% 3% 1% 3%;
    }

    .recommInstruction {
      // padding: 1% 5% 1% 7%;
      width: 70%;
      font-size: 13px;
      font-family: "L-Regular";
    }

    .completeProfileText {
      font-size: 20px;
      font-family: "L-Regular";
    }

    .recommViewMore {
      font-size: 16px;
      color: #62bfae;
    }
  }
}

// Recommendation results

.SearchResult {
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: white;

  box-shadow: 5px 0 15px #f5f5f5;
  -moz-box-shadow: 5px 0 15px #f5f5f5;
  -webkit-box-shadow: 5px 0 15px #f5f5f5;
  -o-box-shadow: 5px 0 15px #f5f5f5;

  padding: 1% 3%;
  margin: 1% 1%;

  & p {
    margin: 0;
  }

  &__img {
    height: 67px;
    width: 67px;
    border-radius: 50%;
    border: 1px solid #62bfae;
    padding: 1px;
    object-position: center;
    object-fit: cover;
  }

  &__detail {
    height: 100%;
    flex: 1;
    margin-left: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;
    // justify-content: center;

    .left {
      width: 75%;

      .first {
        &__namecity {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #9097a2;

          &_name {
            font-size: 16px;
            font-family: "L-Medium";
            text-transform: capitalize;
          }

          &_city {
            font-size: 13px;
            font-family: "L-Regular";
            text-transform: capitalize;
          }
        }

        &__jobtitle {
          color: #9097a2;
          font-size: 12px;
          font-family: "L-Light";
        }
      }

      .jobTitle {
        font-size: 12px;
        font-family: "L-Light";
        text-transform: capitalize;
      }

      .second {
        color: #9097a2;
        line-height: 90%;
        &__heading {
          font-size: 12px;
          font-family: "L-SBold";
        }

        &__type {
          font-size: 12px;
          font-family: "L-Light";
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: #9097a2;
      &__city {
        font-size: 13px;
        font-family: "L-Regular";
        text-transform: capitalize;
      }

      &__ratingText {
        font-size: 13px;
        font-family: "L-Light";
      }

      &__ratingValue {
        margin: 0;
        display: flex;
        align-items: center;

        .rating {
          font-size: 13px;
          font-family: "L-Medium";
          padding-left: 5px;
          color: #62bfae;
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.SearchResult:hover {
  background-color: #62bfae;
  color: white;

  .first__namecity_name,
  .first__namecity_city,
  .right__city,
  .first__jobtitle,
  .right__ratingText,
  .rating,
  .second {
    color: white !important;
  }
}

@media only screen and (max-width: 1600px) {
  .UpcomingSessions {
    &__innerDiv {
      width: 100%;

      .upcomingSesParent {
        overflow-y: auto;
        overflow-x: hidden;
      }

      .upcomingSesTitle {
        margin: 1% 3%;
        font-size: 18px;
        font-family: "L-Medium";
        background: #62bfae;
        color: white;
        padding: 10px 3%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin-bottom: 0px;
      }
    }
  }

  .UpcomingSessionsList {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftArrrow {
      width: 2%;
      cursor: pointer;

      .leftA {
        width: 25px;
        height: 25px;
      }
    }

    .sliderDiv {
      width: 96%;
      height: 100%;
      position: relative;
    }

    .rightArrrow {
      width: 2%;
      cursor: pointer;

      .rightA {
        width: 25px;
        height: 25px;
      }
    }
  }

  .noSession {
    grid-template-columns: 31% 2fr;
    padding: 0 3%;
    // height: 300px !important;
    min-height: 300px;
    margin-top: 2%;

    &__firstCol {
      .UpcomingHeading {
        font-size: 18px;
        margin-bottom: 0px;
      }

      .upcomingNoSession {
        font-size: 15px;

        line-height: 160%;
        margin: 5% 7%;
      }

      .viewTherapists {
        font-size: 16px;
      }
    }

    &__secondCol {
      width: 100%;

      .recommendedTherapist {
        font-size: 18px;
      }
    }
  }

  // Recommendation results

  .SearchResult {
    height: 90px;

    & p {
      margin: 0;
    }

    &__img {
      height: 53px;
      width: 53px;
    }

    &__detail {
      .left {
        .first {
          &__namecity {
            &_name {
              font-size: 14px;
            }

            &_city {
              font-size: 11px;
            }
          }

          &__jobtitle {
            font-size: 11px;
          }
        }

        .jobTitle {
          font-size: 11px;
        }

        .second {
          color: #9097a2;
          &__heading {
            font-size: 11px;
          }

          &__type {
            font-size: 11px;
          }
        }
      }

      .right {
        &__city {
          font-size: 11px;
        }

        &__ratingText {
          font-size: 11px;
        }

        &__ratingValue {
          .rating {
            font-size: 11px;
          }
        }
      }
    }
  }

  .upcomingSesBottom {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
    flex-wrap: wrap;
    align-items: center;

    .footerLinkDiv {
      height: 100px;

      .footerLinkContent {
        padding: 20px;

        svg {
          width: 25px;
          height: 25px;
        }

        .footerLinkText {
          font-size: 16px;
          margin: 0;
          padding: 5px 0;
          font-family: "L-Medium";
          color: #9ca7b7;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .UpcomingSessions {
    &__innerDiv {
      width: 100%;
      height: 60vh;
      overflow-y: auto;

      .upcomingSesParent {
        overflow-y: auto;
        height: auto;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .upcomingSesTitle {
        margin: 2% 1%;

        padding: 15px 3%;

        margin-bottom: 0px;
      }

      .noSession {
        grid-template-columns: 35% 2fr;

        padding: 0;
        // height: 300px !important;
        margin-top: 2%;

        &__firstCol {
          .UpcomingHeading {
            font-size: 18px;
            margin-bottom: 0px;
          }

          .upcomingNoSession {
            font-size: 15px;
            line-height: 140%;
            margin: 5% 7%;
          }

          .viewTherapists {
            font-size: 16px;
          }
        }

        &__secondCol {
          width: 100%;

          .recommendedTherapist {
            font-size: 18px;
          }
        }
      }
    }
  }

  .UpcomingSessionsList {
    .leftArrrow {
      display: none;
    }

    .sliderDiv {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .rightArrrow {
      display: none;
    }
  }

  .upcomingSesBottom {
    padding: 5% 0%;
    width: 100%;
    grid-template-columns: 48% 48%;

    .footerLinkDiv {
      width: 100%;
      height: 140px;

      .footerLinkContent {
        .footerLinkText {
          font-size: 21px;

          padding: 5px 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .UpcomingSessions {
    .upcomingSesParent {
      height: 70vh;
      overflow-y: auto;
    }

    &__innerDiv {
      width: 100%;
      height: 100%;
      padding-bottom: 130px;
      padding-top: 2%;

      .upcomingSesParent {
        height: auto;
      }

      .upcomingSesTitle {
        margin: 2% 0%;
        font-size: 18px;
        text-align: center;

        padding: 15px 3%;

        margin-bottom: 0px;
      }
    }
  }

  .UpcomingSessionsList {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .leftArrrow {
      display: none;
    }

    .sliderDiv {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .rightArrrow {
      display: none;
    }
  }

  .noSession {
    grid-template-columns: 100%;
    padding: 0;
    height: auto !important;
    margin-top: 2%;
    margin-bottom: 10%;

    &__firstCol {
      height: auto !important;

      .UpcomingHeading {
        font-size: 18px;
        margin-bottom: 0px;
      }

      .upcomingNoSession {
        font-size: 13px;
        line-height: 140%;
        margin: 5% 7%;
        text-align: center;
        padding-bottom: 20%;
      }

      .viewTherapists {
        font-size: 18px;
      }
    }

    &__secondCol {
      width: 100%;

      .recommendedTherapist {
        font-size: 18px;
      }

      .recommInstructionDiv {
        flex-direction: column;
        align-items: center;
        padding: 1% 3% 4% 3%;
      }

      .recommInstruction {
        // padding: 1% 5% 1% 7%;
        width: 100%;
        font-size: 13px;
        font-family: "L-Regular";
        // text-align: center;
      }

      .recommViewMore {
        font-size: 18px;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #62bfae;
      }
    }
  }

  // Recommendation results

  .SearchResult {
    height: 120px;

    & p {
      margin: 0;
    }

    &__img {
      height: 53px;
      width: 53px;
    }

    &__detail {
      .left {
        .first {
          &__namecity {
            &_name {
              font-size: 14px;
            }

            &_city {
              font-size: 11px;
            }
          }

          &__jobtitle {
            font-size: 11px;
          }
        }

        .jobTitle {
          font-size: 11px;
        }

        .second {
          line-height: 80%;
          &__heading {
            font-size: 11px;
          }

          &__type {
            font-size: 11px;
          }
        }
      }

      .right {
        &__city {
          font-size: 11px;
        }

        &__ratingText {
          font-size: 11px;
        }

        &__ratingValue {
          .rating {
            font-size: 10px;
            text-align: end;
          }
        }
      }
    }
  }

  .upcomingSesBottom {
    padding: 5% 0%;
    margin-top: 3%;
    width: 100%;
    grid-template-columns: 100%;

    .greenBg {
      &:hover {
        background: #62bfae;

        .footerLinkContent {
          .message {
            stroke: #fff;
          }

          .footerLinkText {
            color: white;
            letter-spacing: 1.2px;
          }
        }
      }
    }

    .redBg {
      &:hover {
        background: #ff5f5f;

        .footerLinkContent {
          .bookIcon {
            stroke: #fff;
          }

          .footerLinkText {
            color: white;
            letter-spacing: 1.2px;
          }
        }
      }
    }

    .blueBg {
      &:hover {
        background: #548df7;

        .footerLinkContent {
          .knowledgeIcon {
            stroke: #fff;
          }

          .footerLinkText {
            color: white;
            letter-spacing: 1.2px;
          }
        }
      }
    }

    .footerLinkDiv {
      width: 100%;
      border-radius: 15px;
      background: white;
      height: 120px;

      .footerLinkContent {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 20px;

        .message,
        .knowledgeIcon,
        .bookIcon {
          width: 25px;
        }

        .footerLinkText {
          font-size: 16px;
          padding: 5px 0;
        }
      }
    }
  }
}
