.addPaymentDiv {
  padding: 1% 10%;
  overflow: hidden;
  margin: 1% 0;

  .cpDiv {
    .addCardBtn {
      background-color: white;
      width: 412px;
      height: 227px;
      border-radius: 15px;
      outline: none;
      border: none;
      transition: all 0.1s linear;

      &:hover {
        background: #ff5f5f;

        .addCardInner {
          .addCardText {
            color: white;
            letter-spacing: 2px;
          }

          .addCardDiv {
            background: white;

            .addCardIcon {
              stroke: #ff5f5f;
              width: 28px;
              height: 28px;
            }
          }
        }
      }

      .addCardInner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 15px;

        .addCardDiv {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #ff5f5f;
          position: relative;
          margin-bottom: 15px;

          .addCardIcon {
            width: 21px;
            height: 21px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            object-position: center;
            stroke: #fff;
            transition: all 0.2s linear;
          }
        }

        .addCardText {
          font-size: 30px;
          font-family: "L-Light";
          color: #9ca7b7;
          letter-spacing: 0.6px;
          transition: all 0.2s linear;
        }
      }
    }
  }
}

.cardItemDiv {
  background-color: white;
  width: 412px;
  height: 227px;
  border-radius: 15px;
  padding: 3% 8%;

  &:hover {
    &:nth-child(odd) {
      background: linear-gradient(120deg, #62beb0, #618eee);
    }

    &:nth-child(even) {
      background: linear-gradient(120deg, #e9ac92, #ed6d65);
    }

    .cardItemTop {
      border-bottom: 1px solid #fff;

      .defaultText {
        color: white;
      }

      .hideOnHover {
        display: none;
      }

      .hoveredVisaImg {
        display: block;
      }
    }

    .cardItemCenter {
      .expiryDate {
        color: white;
      }
    }

    .cardNumber {
      color: white;
    }

    .cardItemBottom {
      .cardItemBtn {
        color: white;
      }
    }
  }

  .cardItemTop {
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #63a799;
    height: 40px;
    padding-bottom: 10px;

    .defaultText {
      font-size: 30px;
      line-height: 100%;
      font-family: "L-Regular";
      color: #9097a2;
      float: left;
    }

    .cardTypeImg {
      float: right;
    }

    .hoveredVisaImg {
      display: none;
    }
  }

  .cardItemCenter {
    padding: 3% 0%;

    .expiryDate {
      float: right;
      font-size: 24px;
      font-family: "L-Medium";
      color: #9097a2;
    }
  }

  .cardNumber {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2% 0%;
    letter-spacing: 1px;
    font-size: 27px;
    font-family: "L-Light";
    color: #9097a2;
    margin-bottom: 0px !important;
  }

  .cardItemBottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cardItemBtn {
      background: transparent;
      border: none;
      outline: none;
      color: #79bcae;
      font-size: 20px;
      font-family: "L-Regular";
    }
  }
}

.paymentMethodList {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  justify-content: center;
  max-height: 55vh;

  overflow-y: auto;
  border-top: 2px solid white;
  padding: 3% 0%;
  width: 90%;
  margin: auto;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

@media only screen and (max-width: 1700px) {
  .addPaymentDiv {
    .cpDiv {
      .addCardBtn {
        width: 300px;
        height: 180px;

        .addCardInner {
          .addCardDiv {
            width: 45px;
            height: 45px;
          }

          .addCardText {
            font-size: 22px;
          }
        }
      }
    }
  }

  .cardItemDiv {
    width: 300px;
    height: 180px;

    .cardItemTop {
      height: 30px;

      .defaultText {
        font-size: 22px;
      }

      .cardTypeImg {
        width: 50px;
      }
    }

    .cardItemCenter {
      .expiryDate {
        font-size: 20px;
      }
    }

    .cardNumber {
      width: 100%;
      padding: 2% 0%;
      letter-spacing: 1px;
      font-size: 22px;
      margin-bottom: 0px !important;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cardItemBottom {
      .cardItemBtn {
        background: transparent;
        border: none;
        outline: none;
        color: #79bcae;
        font-size: 16px;
        font-family: "L-Regular";
      }
    }
  }

  .paymentMethodList {
    gap: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .paymentMethodList {
    grid-template-columns: auto !important;
  }
}

@media only screen and (max-width: 1250px) {
  .paymentMethodList {
    grid-template-columns: auto auto;
    width: 100%;
  }

  .addPaymentDiv {
    padding: 1% 5%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1075px) {
  .addPaymentDiv {
    padding: 2% 0%;
    overflow: hidden;

    .cpDiv {
      .addCardBtn {
        border-radius: 15px;
      }
    }
  }

  .cardItemDiv {
    background-color: white;
    border-radius: 15px;
    padding: 3% 8%;
  }

  .paymentMethodList {
    max-height: 40vh !important;

    overflow-y: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  &::-webkit-scrollbar {
    display: none;
  }
  .addPaymentDiv {
    padding: 3% 0;
    margin: 0;

    .cpDiv {
      .addCardBtn {
        width: 280px;
        height: 160px;

        .addCardInner {
          .addCardDiv {
            width: 45px;
            height: 45px;
          }

          .addCardText {
            font-size: 22px;
          }
        }
      }
    }
  }

  .cardItemDiv {
    width: 280px;
    height: 160px;

    .cardItemTop {
      height: 26px;

      .defaultText {
        font-size: 18px;
      }

      .cardTypeImg {
        width: 50px;
      }
    }

    .cardItemCenter {
      padding: 1%;
      .expiryDate {
        font-size: 18px;
      }
    }

    .cardNumber {
      width: 100%;
      padding: 2% 0%;
      letter-spacing: 1px;
      font-size: 20px;
      margin-bottom: 0px !important;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cardItemBottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cardItemBtn {
        background: transparent;
        border: none;
        outline: none;
        color: #79bcae;
        font-size: 16px;
        font-family: "L-Regular";
      }
    }
  }

  .paymentMethodList {
    grid-template-columns: auto;
    max-height: none;
    height: auto;
    width: 100%;
    justify-content: center;
    overflow-y: auto;
  }
}
