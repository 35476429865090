.balance {
  background: #62bfae;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  padding: 2%;
  border-bottom: 1px solid white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
      font-size: 24px;
      font-family: "L-Regular";
      color: white;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .balanceDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .amountDiv {
      .amount {
        font-size: 60px;
        font-family: "L-Medium";
        color: white;
        text-transform: uppercase;
        margin: 10px 0;
      }
    }

    .payoutBtnDiv {
      .payoutBtn {
        background: #e0f2ef;
        border: 1px solid #62bfae;
        border-radius: 0.35vh;
        border: none;
        color: #62bfae;
        font-size: 16px;
        font-family: "L-SBold";
        text-transform: uppercase;
        padding: 5px 10px;
        width: 120px;
        height: 43px;

        &:hover {
          background: #62bfae;
          color: white;
          border: 1px solid white;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .balance {
    &__header {
      &__title {
        font-size: 19px;
      }
    }

    .balanceDiv {
      .amountDiv {
        .amount {
          font-size: 50px;
        }
      }

      .payoutBtnDiv {
        .payoutBtn {
          width: 100px;
          height: 35px;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .balance {
    padding: 5% 0 10% 0;

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;

      &__title {
        font-size: 21px;
        font-family: "L-Medium";
      }
    }

    .balanceDiv {
      .amountDiv {
        .amount {
          font-size: 50px;
          font-family: "L-Light";
        }
      }

      .payoutBtnDiv {
        .payoutBtn {
          font-size: 14px;
          padding: 5px 10px;
          width: 100px;
          height: 35px;
        }
      }
    }
  }
}
