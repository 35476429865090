@import "src/assets/scss/variables";

.SidebarItem {
  text-align: center;
  padding: 8px 0px;
}

.SidebarLink {
  color: #828993;
  text-decoration: none !important;
  display: block;
  padding: 0.5rem 0;
  font-size: 11px;
  font-family: "L-Regular";
  white-space: pre-line;
}

.SidebarLink:hover {
  .SidebarItemTitle {
    color: #3c3c3c;
    cursor: pointer;
  }
}

.SidebarLink.active,
.SidebarLink:active {
  border-right: 2px solid #62bfae;

  .SidebarItemTitle {
    color: #62bfae;
    font-size: 13px;
    font-family: "L-SBold";
  }

  .SidebarItemIcon {
    fill: #62bfae;
  }
}

.SidebarItemIcon {
  width: 35px;
  height: 35px;
}

@media only screen and (max-width: 1600px) {
  .SidebarItem {
    padding: 8px 0px;

    .SidebarLink {
      text-decoration: none !important;
      display: block;
      padding: 0.3rem 0;
      font-size: 11px;
      font-family: "L-Regular";
    }

    .SidebarLink.active,
    .SidebarLink:active {
      .SidebarItemTitle {
        font-size: 11px;
      }
    }
    .SidebarItemIcon {
      width: 25px;
      height: 25px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .SidebarItem {
    text-align: center;
    padding: 15px 0px;

    .SidebarItemIcon {
      width: 30px;
      height: 30px;
    }

    .SidebarLink {
      text-decoration: none !important;
      display: block;
      padding: 0.5rem 0;
      font-size: 14px;
      font-family: "L-Regular";
      white-space: nowrap;
    }

    .SidebarLink.active,
    .SidebarLink:active {
      .SidebarItemTitle {
        font-size: 14px;
      }
    }
  }

  .SidebarLink:hover {
    .SidebarItemTitle {
      color: #3c3c3c;
      cursor: pointer;
    }
  }

  .SidebarLink.active,
  .SidebarLink:active {
    border-bottom: 2px solid #62bfae;
    border-right: none;
    .SidebarItemTitle {
      color: #62bfae;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .SidebarItem {
    text-align: center;
    padding: 5px 0px;

    .SidebarItemIcon {
      width: 22px;
      height: 22px;
    }

    .SidebarLink {
      padding: 0.1rem 0;
      font-size: 11px;
      line-height: 100%;
    }
  }

  .SidebarLink:hover {
    .SidebarItemTitle {
      color: #3c3c3c;
      cursor: pointer;
    }
  }

  .SidebarLink.active,
  .SidebarLink:active {
    border-bottom: 2px solid #62bfae;
    border-right: none;

    .SidebarItemTitle {
      color: #62bfae;
      font-size: 9px !important;
    }
  }
}
