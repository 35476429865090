.AutocompleteResultsListRow {
  width: 100%;
  position: absolute;
  margin-top: -1px;
}

.AutocompleteResultsListItem {
  margin-bottom: -1px !important;
  background-color: transparent;
  font-size: 0.8rem;
}

.formDiv2 {
  width: 100%;
  height: auto;
  margin: auto;
  background: white;
  padding: 4% 8%;
  border-radius: 10px;

  .formTwo {
    .title {
      font-size: 36px;
      font-family: "L-Regular";
      text-align: center;
    }

    .subTitle {
      color: #9097a2;
      font-size: 22px;
      text-align: center;
      line-height: 120%;
    }

    .therapyType {
      padding-top: 3%;

      .therapyTypeOptions {
        display: grid;
        grid-template-columns: auto auto auto auto;
        width: 100%;
        align-items: center;
      }

      .styledCheckbox {
        position: absolute;
        opacity: 0;

        & + .topLabel {
          position: relative;
          cursor: pointer;
          padding: 0;
          -moz-user-select: none;
          user-select: none;
          color: #9097a2;
          font-size: 16px;
        }

        & + .topLabel::before {
          content: "";
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          height: 18px;
          background: #e1e6ed;
        }

        &:checked + .topLabel:before {
          background: #62bfae;
        }

        &:checked + .topLabel {
          color: #62bfae;
        }

        &:disabled + .topLabel {
          color: #b8b8b8;
          cursor: auto;
        }

        &:disabled + .topLabel:before {
          box-shadow: none;
          background: #ddd;
        }
      }
    }

    .titleFlex {
      display: flex;
    }

    .listStyle {
      width: 6px;
      height: 6px;
      padding: 2px;
      border-radius: 50%;
      background: black;
      margin-right: 12px;
      margin-top: 12px;
    }
    .labelOne {
      font-size: 19px;
      font-family: "L-Regular";
    }

    .multipleOption {
      width: 80%;
    }

    .therapistInCity {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 20px;
    }

    .subLabel {
      font-size: 12px;
      font-family: "L-SBold";
      text-transform: uppercase;
      vertical-align: middle;
    }

    .selectedOptions {
      width: 80%;
    }

    .needsBtnDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px 0;

      .needNextBtn {
        width: 260px;
        height: 50px;
        background: #62bfae;
        color: white;
        border: none;
        outline: none;
        border-radius: 0.35vh;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 16px;
        font-family: "L-Regular";
        letter-spacing: 0.5px;
        transition: all 0.2s linear;

        &:hover {
          letter-spacing: 1.5px;
          background: #62bfaeca;
        }
      }
    }
  }
}

.formTwoTherapist {
  padding: 5%;

  .therapistTextArea {
    border: none;
    width: 90%;
    background: #f0ebe7;
    margin-left: 15px;
    outline: none;
    padding: 10px;
  }

  .nextBtn {
    margin: 10% auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #62bfae;
    border: none;
    color: white;
    width: 200px;
    height: 40px;
    transition: all 0.2s linear;

    &:hover {
      letter-spacing: 1.2px;
      background: #62bfaece;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .formDiv2 {
    .formTwo {
      .title {
        font-size: 28px;
      }

      .subTitle {
        font-size: 16px;
      }

      .therapyType {
        .styledCheckbox {
          & + .topLabel {
            font-size: 11px;
          }

          & + .topLabel::before {
            width: 16px;
            height: 16px;
          }
        }
      }
      .titleFlex {
        display: flex;
      }

      .listStyle {
        width: 5px;
        height: 5px;
        padding: 2px;
        margin-right: 6px;
        margin-top: 10px;
      }
      .labelOne {
        font-size: 17px;
      }

      .multipleOption {
        width: 80%;
      }

      .therapistInCity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
      }

      .subLabel {
        font-size: 12px;
        font-family: "L-SBold";
        text-transform: uppercase;
        vertical-align: middle;
      }

      .selectedOptions {
        width: 80%;
      }

      .therapyType {
        padding-top: 3%;
      }

      .needsBtnDiv {
        .needNextBtn {
          width: 200px;
          height: 45px;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .formDiv2 {
    width: 95%;

    .formTwo {
      .title {
        font-size: 28px;
      }

      .subTitle {
        font-size: 18px;
      }

      .therapyType {
        padding-top: 3%;

        .therapyTypeOptions {
          grid-template-columns: auto auto;
        }
      }

      .labelOne {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .formDiv2 {
    width: 90%;
    padding: 4% 2%;
    overflow-x: hidden !important;

    .formTwo {
      .title {
        font-size: 24px;
      }

      .subTitle {
        font-size: 16px;
      }

      .selectedOptions {
        width: 80%;
      }

      .therapistInCity {
        padding-right: 5px;
      }

      .controllerDiv {
        width: 100%;
        margin: 0 auto;
      }

      .badgeBg {
        background-color: #62bfae;
        color: white;
        font-family: "L-SBold";
        text-transform: uppercase;
        text-align: center;
        display: inline;
        align-items: center;
        border-radius: 25px;

        .badgeCross {
          object-fit: cover;
          object-position: center;
          width: 12px;
          height: 12px;
          margin-bottom: 25%;
        }
      }

      .therapyType {
        padding-top: 3%;

        .therapyTypeOptions {
          grid-template-columns: auto auto;
        }

        .styledCheckbox {
          & + .topLabel {
            font-size: 14px;
          }
        }
      }

      .labelOne {
        font-size: 16px;
        line-height: 120%;
        margin-left: 5px;
      }

      .multipleOption {
        width: 90%;
      }
      .needsBtnDiv {
        display: flex;
        align-items: center;
        justify-content: center;

        .prevLink,
        .needNextBtn {
          width: 120px;
          height: 35px;
        }
      }
    }
  }
}
