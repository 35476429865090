.ChatDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
  height: 100%;

  .ChatProfile {
    background: #f5d4c7;
    padding: 10px;

    .chatProfileName {
      text-align: center;
      font-size: 26px;
      font-family: "L-Regular";
      margin: 0;

      .goBackBtn {
        display: none;
      }
    }
  }

  .Chat {
    overflow-y: auto;
    height: 80%;
    padding: 1% 3% 5% 3%;
  }

  .ChatInputDiv {
    margin-top: 1%;

    position: relative;
    width: 100%;
    height: 20%;

    .chatInputAbs {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: white;
      padding: 0 3%;

      form {
        .chatTextarea {
          width: 100%;
          border: 1px solid #a6b3ca;
          border-radius: 10px;
          padding: 5px;
          font-size: 16px;
          font-family: "L-Regular";
          color: #9097a2;
          outline: none;
        }

        .chatSendBtn {
          width: 120px;
          height: 40px;
          background: #62bfae;
          outline: none;
          border: none;
          color: white;
          float: right;
          font-size: 20px;
          font-family: "L-Regular";
          border-radius: 0.35vh;

          svg {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .ChatDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow-y: hidden;
    height: 100%;

    .ChatProfile {
      padding: 8px;

      .chatProfileName {
        font-size: 16px;
      }
    }

    .Chat {
      overflow-y: auto;
      height: 80%;
    }

    .ChatInputDiv {
      margin-top: 1%;

      position: relative;
      width: 100%;
      height: 30%;

      .chatInputAbs {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: white;
        padding: 0 3%;

        form {
          .chatTextarea {
            width: 100%;
            border: 1px solid #a6b3ca;
            border-radius: 10px;
            padding: 5px;
            font-size: 16px;
            font-family: "L-Regular";
            color: #9097a2;
            outline: none;
          }

          .chatSendBtn {
            width: 120px;
            height: 40px;
            background: #62bfae;
            outline: none;
            border: none;
            color: white;
            float: right;
            font-size: 20px;
            font-family: "L-Regular";
            border-radius: 0.35vh;

            svg {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .ChatDiv {
    .ChatProfile {
      padding: 8px;

      .chatProfileName {
        font-size: 16px;
      }
    }

    .ChatInputDiv {
      height: 100px;

      .chatInputAbs {
        padding: 1% 3%;

        form {
          .chatTextarea {
            font-size: 15px;
          }

          .chatSendBtn {
            width: 80px;
            height: 30px;
            font-size: 14px;

            svg {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .ChatDiv {
    .ChatProfile {
      padding: 8px;

      .chatProfileName {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .goBackBtn {
          display: block;
          position: absolute;
          left: 5%;

          .sendIcon {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    .Chat {
      padding: 5% 3% 10% 3%;
    }

    .ChatInputDiv {
      height: 15%;

      .chatInputAbs {
        padding: 2% 3%;

        form {
          .chatTextarea {
            font-size: 15px;
          }

          .chatSendBtn {
            width: 75px;
            height: 30px;
            font-size: 15px;

            svg {
              display: none;
            }
          }
        }
      }
    }
  }
}
