.alertDialog {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .innerDiv {
    width: 500px;
    height: 300px;
    background: #f3ab8e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    position: relative;

    .closeAlert {
      position: absolute;
      top: 5%;
      right: 5%;
      cursor: pointer;

      .closeIcon {
        width: 22px;
        height: 22px;
      }
    }

    .errorTxt {
      font-size: 22px;
      font-family: "L-Regular";
      color: #fff;
      text-align: center;
      padding: 20px;
      margin-bottom: 5px;
    }

    .alertSignupContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .alertSignup {
      width: 150px;
      height: 40px;
      background-color: #fff;
      // color: white !important;
      font-size: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      margin: 0 5px;

      .alertSignupBtn {
        color: #f3ab8e;
        width: 160px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-out;

        &:hover {
          letter-spacing: 1.3px;
          text-decoration: none;
        }
      }
    }

    .alertPayment {
      background-color: #fff;
      color: #f3ab8e;
      padding: 5px 8px;
      font-size: 18px;
      font-family: "L-Regular";
      transition: all 0.3s ease-out;

      a {
        text-decoration: none !important;
        color: #f3ab8e !important;
      }

      &:hover {
        letter-spacing: 0.6px;

        a {
          text-decoration: none !important;
        }
      }
    }
  }
}

.alertMessage {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  &__innerDiv {
    width: 500px;
    height: 300px;
    background: #f3ab8e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 11px;
    position: relative;
  }

  &__errorTxt {
    font-size: 24px;
    font-family: "L-Regular";
    color: #fff;
    text-align: center;
    line-height: 120%;
    padding: 20px;
  }

  .btnDiv {
    display: flex;
    align-items: center;
    justify-content: center;

    .yesBtn,
    .noBtn {
      width: 100px;
      height: 40px;
      border: none;
      outline: none;
      background: white;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: "L-Regular";
      text-transform: uppercase;
      margin: 0 15px;
      border: 0.35vh;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .alertDialog {
    .innerDiv {
      min-width: 300px;
      max-width: 90vw;
      height: 250px;

      .closeAlert {
        .closeIcon {
          width: 15px;
          height: 15px;
        }
      }

      .errorTxt {
        font-size: 18px;
        line-height: 120%;
        margin-top: 10px;
      }

      .alertSignupContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      .alertSignup {
        width: 100px;
        height: 35px;
        font-size: 13px;

        .alertSignupBtn {
          width: 100px;
          height: 35px;
        }
      }
    }
  }

  .alertMessage {
    &__innerDiv {
      width: 280px;
      height: 180px;
    }

    &__errorTxt {
      font-size: 16px;
      font-family: "L-Regular";
      color: #fff;
      text-align: center;
      line-height: 120%;
      padding: 10px;
    }

    .btnDiv {
      .yesBtn,
      .noBtn {
        width: 80px;
        height: 30px;

        font-size: 14px;
      }
    }
  }
}
