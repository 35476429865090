.coverBg2 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fdd9ce;
  z-index: -20;
}

.stepThree {
  width: 100%;
  height: 100%;
  background: #fdd9ce;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .stepThreeInner {
    width: 75%;
    height: 100%;
    margin: 5% auto;
    background: rgba(249, 246, 244, 0.8);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2% 3%;

    .step1Nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3%;
      width: 100%;

      .firstPart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 33%;

        .spanText {
          font-size: 14px;
          font-family: "L-Medium";
          text-transform: uppercase;
          padding-right: 5px;
          margin-bottom: 10px;
        }

        .active {
          font-size: 16px;
          color: #79bcae;
        }
      }

      .secondPart {
        width: 33%;
        display: flex;
        justify-content: center;

        .navLogo {
          .ImgFluid {
            width: 300px;
          }
        }
      }
      .thirdPart {
        width: 33%;
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
      }
    }

    .stepThreeContent {
      width: 70%;
      height: 100%;
      margin: 2% auto 5% auto;
      padding: 4% 8%;
      background: white;
      border-radius: 10px;
    }

    .heading {
      font-size: 36px;
      font-family: "L-Regular";
      text-align: center;
      margin-bottom: 10px;
    }

    .subHeading {
      font-size: 22px;
      font-family: "L-Regular";
      color: #9097a2;
      text-align: center;
      margin-bottom: 20px;
    }

    .mRight {
      margin-right: 8px;
    }

    .listingTitle {
      display: flex;
      align-items: flex-start;
      font-size: 19px;
      font-family: "L-Regular";
      margin-bottom: 2px;
      color: black;
    }

    .rowFlex {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 80%;
    }

    .weekDaysLabel {
      width: 20%;
    }

    .selectedTimesDiv {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between;

      .selectedTimes {
        margin: 0;
        color: rgb(66, 65, 65);

        :first-child {
          margin-right: 10px;
          border: 1px solid rgb(131, 129, 129);
          padding: 3px 8px;
          font-size: 13px;
          border-radius: 3px;
          background: rgb(255, 255, 255);
        }

        :nth-child(2) {
          border: 1px solid rgb(124, 123, 123);
          padding: 3px 8px;
          font-size: 13px;
          border-radius: 3px;
          background: rgb(255, 255, 255);
        }
      }
    }

    .availableAddBtn {
      background: transparent;
      font-size: 18px;
      border-radius: 5px;
      margin-left: 30px;
      padding: 5px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;

      .svgIcon {
        width: 100%;
        height: 100%;
      }
    }

    .stepThreeFooter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2%;
    }
  }
}

.therapistPreview {
  display: flex;
  flex-direction: column;

  .topHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #62bfae;

    .aboutYouTitle {
      font-size: 25px;
      font-family: "L-Regular";
      color: #62bfae;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .editLink {
      width: 150px;
      height: 45px;
      background: #62bfae;
      border: none;
      outline: none;
      color: white;
      border-radius: 0.35vh;
      font-size: 20px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      letter-spacing: 0.5px;
      transition: all 0.2s linear;

      &:hover {
        text-decoration: none;
        background: #62bfaeb0;
        letter-spacing: 1.6px;
      }
    }
  }

  .previewContent {
    padding: 2% 18%;

    .previewAbout {
      padding-bottom: 10%;
      .aboutYouContent {
        .profileInfo {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5% 0%;
          flex-direction: column;
          text-align: center;

          .previewNameDiv {
            padding: 10px 0;
            text-align: center;

            .previewName {
              margin: 0;
              font-size: 24px;
              font-family: "L-Regular";
              text-transform: capitalize;
            }
            .previewEmail {
              font-size: 16px;
              padding: 2px 0;
              color: #434344;
            }
          }

          .previewSocialDiv {
            display: flex;

            .socialIconDiv {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background: black;
              position: relative;
              margin: 0 10px;

              .socialIcon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        .profileBioDiv {
          .profileBioTitle {
            font-size: 14px;
            font-family: "L-Regular";
          }

          .profileBio {
            font-size: 19px;
            font-family: "L-Light";
            border-bottom: 1px solid black;
            padding-bottom: 10px;
          }
        }

        .contactInfo {
          padding-top: 5%;
          .contactInfoTitle {
            font-size: 22px;
            text-transform: uppercase;
            color: #62bfae;
          }
          .contactFlex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0px;

            .contactFirstDiv {
              width: 45%;
              border-bottom: 1px solid black;

              .contactTitle {
                font-size: 14px;
                font-family: "L-Regular";
                letter-spacing: 0.4px;
              }

              .mobile {
                font-size: 19px;
                font-family: "L-Light";
                padding-bottom: 5px;
                letter-spacing: 0.7px;
              }
            }
          }

          .addressDivFlex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .addressDiv {
              width: 45%;
              padding: 10px 0px;
              .addressTitle {
                font-size: 14px;
                font-family: "L-Regular";
                letter-spacing: 0.4px;
              }

              .address {
                font-size: 19px;
                font-family: "L-Light";
                padding-bottom: 5px;
                border-bottom: 1px solid black;
              }
            }
          }
        }
      }
    }

    .previewNeeds {
      padding-bottom: 10%;

      .yourNeedsContent {
        .listStyleFlex {
          display: flex;

          .listStyle {
            width: 6px;
            height: 6px;
            background: black;
            padding: 2px;
            border-radius: 50%;
            margin-right: 14px;
            margin-top: 12px;
          }

          .listStyleText {
            line-height: 120%;
            margin-bottom: 5px;
          }
        }

        .sessionDiv {
          padding: 2% 0%;

          .therapySession {
            font-size: 19px;
            font-family: "L-Regular";
            padding: 1% 0%;
          }

          .selectedSessions {
            padding-left: 3%;

            .selectedSessionsBg {
              background: #62bfae;
              color: white;
              text-transform: uppercase;
              font-size: 14px;
              font-family: "L-SBold";
            }
          }
        }

        .cityPreferenceDiv {
          display: flex;
          align-items: center;
          padding-bottom: 2%;

          .cityPreference {
            font-size: 19px;
            font-family: "L-Regular";
            padding: 1% 0%;
          }

          .cityPreferenceAns {
            margin-bottom: 0px;
            padding-left: 50px;
            font-size: 19px;
            font-family: "L-Bold";
          }
        }

        .therapySelectDiv {
          padding-bottom: 2%;
          .therapySelect {
            font-size: 19px;
            font-family: "L-Regular";
            padding: 1% 0%;
          }

          .selectedTherapies {
            padding-left: 3%;

            .therapySelectBg {
              background: #62bfae;
              color: white;
              text-transform: uppercase;
              font-size: 14px;
              font-family: "L-SBold";
            }
          }
        }

        .mentalConditionDiv {
          padding-bottom: 3%;
          .mentalCondition {
            font-size: 19px;
            font-family: "L-Regular";
            padding: 1% 0%;
          }

          .mentalConditionSelect {
            padding-left: 3%;

            .mentalConditionBg {
              background: #62bfae;
              color: white;
              text-transform: uppercase;
              font-size: 14px;
              font-family: "L-SBold";
            }
          }
        }

        .financialAssDiv {
          display: flex;
          align-items: center;

          .financialAss {
            font-size: 19px;
            font-family: "L-Regular";
            padding: 1% 0%;
          }

          .financialAssAns {
            margin-bottom: 0px;
            padding-left: 50px;
            font-size: 19px;
            font-family: "L-Bold";
          }
        }
      }
    }

    .previewBtns {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 5%;

      .previewPrevBtn {
        background: #62bfae;
        color: white;
        width: 260px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        border-radius: 0.35vh;
        letter-spacing: 0.5px;
        transition: all 0.2s linear;

        &:hover {
          background: #62bfaedc;
          text-decoration: none;
          letter-spacing: 1.6px;
        }
      }

      .previewCompleteBtn {
        background: #62bfae;
        color: white;
        width: 200px;
        height: 45px;
        border: none;
        outline: none;
        text-transform: uppercase;
        border-radius: 0.35vh;
        letter-spacing: 0.5px;
        transition: all 0.2s linear;

        &:hover {
          background: #62bfaedc;
          text-decoration: none;
          letter-spacing: 1.6px;
        }
      }
    }

    .previewFooter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5%;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .stepThree {
    .stepThreeInner {
      .step1Nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3%;
        width: 100%;

        .firstPart {
          .spanText {
            font-size: 12px;
          }

          .active {
            font-size: 14px;
          }
        }

        .secondPart {
          .navLogo {
            .ImgFluid {
              width: 220px;
            }
          }
        }
      }

      .heading {
        font-size: 24px;

        margin-bottom: 5px;
      }

      .subHeading {
        font-size: 16px;

        margin-bottom: 20px;
      }

      .mRight {
        margin-right: 8px;
      }

      .listingTitle {
        font-size: 17px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .stepThree {
    width: 100%;
    height: 100%;
    background: #fdd9ce;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .stepThreeInner {
      width: 90%;
      height: 100%;
      margin: 5% auto;
      background: rgba(249, 246, 244, 0.8);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      padding: 2% 3%;

      .step1Nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3% 0;
        width: 100%;

        .firstPart {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 33%;

          .spanText {
            font-size: 8px;
            font-family: "L-Medium";
            text-transform: uppercase;
            padding-right: 5px;
            margin-bottom: 10px;
          }

          .active {
            font-size: 10px;
            color: #79bcae;
          }
        }

        .secondPart {
          width: 33%;
          display: flex;
          justify-content: center;

          .navLogo {
            .ImgFluid {
              width: 150px;
            }
          }
        }
        .thirdPart {
          width: 33%;
          display: flex;
          justify-content: flex-end;
          text-transform: uppercase;
        }
      }

      .stepThreeContent {
        width: 90%;
        height: 100%;
        margin: 2% auto 5% auto;
        padding: 4% 8%;
        background: white;
        border-radius: 10px;
      }

      .stepThreeFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .stepThree {
    .stepThreeInner {
      width: 95%;
      height: 100%;
      margin: 5% auto;
      background: rgba(249, 246, 244, 0.8);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.18);
      padding: 2% 3%;

      .step1Nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 3% 0;
        width: 100%;

        .firstPart {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 2% 0;
          order: 3;

          .spanText {
            font-size: 8px;
            font-family: "L-Medium";
            text-transform: uppercase;
            padding-right: 5px;
            margin-bottom: 10px;
          }

          .active {
            font-size: 10px;
            color: #79bcae;
          }
        }

        .secondPart {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 2% 0;

          .navLogo {
            .ImgFluid {
              width: 150px;
            }
          }
        }
        .thirdPart {
          width: 100%;
          padding: 2% 0;
          justify-content: center;
        }
      }

      .stepThreeContent {
        width: 100%;
        height: 100%;
        margin: 2% auto 5% auto;
        padding: 4% 3%;
        background: white;
        border-radius: 10px;
      }

      .rowFlex {
        display: grid;
        grid-template-columns: auto auto;
        width: 90%;
        justify-content: space-between;
      }
      .listingTitle {
        font-size: 15px;
      }

      .weekDaysLabel {
        order: 1;
        font-size: 13px;
      }

      .availableAddBtn {
        order: 2;
      }

      .selectedTimeRange {
        order: 3;
        width: 100%;
      }
      .selectedTimesDiv {
        display: flex;
        margin-bottom: 10px;
        align-items: center;

        .selectedTimes {
          margin: 0;
          color: rgb(66, 65, 65);

          :first-child {
            padding: 3px 5px;
            font-size: 12px;
          }

          :nth-child(2) {
            padding: 3px 5px;
            font-size: 12px;
          }
        }
      }

      .availableAddBtn {
        margin-left: 20px;

        .svgIcon {
          width: 100%;
          height: 100%;
        }
      }

      .stepThreeFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 2%;
      }
    }
  }
}
