@import "src/assets/scss/variables";

.Circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 25px;
  color: #fff;
  text-align: center;
  background: #62bfae;
}

@media (max-width: 991px) {
  .Circle {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}
