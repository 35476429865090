.profileClient {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .ProfileClientInnerDiv {
    padding: 5% 13%;
    height: 75vh !important;
    margin-bottom: 5%;

    .profileClientDiv {
      height: 100%;
      padding: 0 2%;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .profileInput {
      border: none;
      width: 100%;
      background: transparent;
      border-bottom: 1px solid black;
      font-size: 19px;
      font-family: "L-Light";
      outline: none;
    }

    .addInputLabel {
      font-size: 14px;
      font-family: "L-Regular";
      margin-bottom: 8px;
    }
  }

  // &__titleTwo {
  //   display: flex !important;
  //   align-items: center !important;
  //   justify-content: flex-start !important;
  // }

  &__heading {
    margin-bottom: 5px;
  }

  &__title {
    border-bottom: 2px solid #62bfae;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .saveButton {
      display: flex;
      align-items: center;
    }

    .cancelBtn {
      background: #ff5f5f !important;
      color: white;
    }

    .editProfile {
      width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #62bfae;
      color: white;
      font-size: 14px;
      border-radius: 0.35vh;
      text-transform: uppercase;
      font-family: "L-Regular";
      margin-bottom: 5px;
      transition: all 0.2s linear;
      margin-left: 10px;
      outline: none;
      border: none;

      &:hover {
        letter-spacing: 1.2px;
        background: #62bfaec0;
      }
    }
  }
  &__heading {
    font-size: 30px;
    color: #62bfae;
    text-transform: uppercase;
    font-family: "L-Regular";
    display: flex;
    align-items: center;
  }

  .backToAdmin {
    font-size: 16px;
    font-family: "L-Regular";
    margin: 0 15px 0 0;
    color: #62bfae;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .basicInfo {
    margin-bottom: 3%;
    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;

      .loader {
        margin-top: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &__spin {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          border: 3px solid;
          border-color: #62bfae #62bfae transparent transparent;
          animation: rotation 1s linear infinite;

          &:after,
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border: 3px solid;
            border-color: transparent transparent #f3ab8e #f3ab8e;
            width: 42px - 8px;
            height: 42px - 8px;
            border-radius: 50%;
            animation: rotationBack 0.5s linear infinite;
            transform-origin: center center;
          }
          &:before {
            width: 42px - 16px;
            height: 42px - 16px;
            border-color: #fff #fff transparent transparent;
            animation: rotation 1.5s linear infinite;
          }
          @keyframes rotationBack {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(-360deg);
            }
          }

          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }

      .profileImgDiv {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;

        .profileLabel {
          cursor: pointer;
          position: relative;

          &:hover {
            .imageHovered {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              overflow: hidden;
            }
          }

          .imageHovered {
            display: none;
            width: 100%;
            border-radius: 50%;
            height: 100%;
            background: rgba(0, 0, 0, 0.363);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .basicDetail {
        width: 80%;
        display: flex;
        align-items: flex-start;
        border-left: 1px solid black;
        flex-direction: column;
        padding-left: 5%;

        .usernameDiv {
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .profileInputDiv {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .inputWrapper {
              width: 45%;
            }

            input {
              width: 100%;
            }
          }

          .emailWrapper {
            width: 100%;
          }
        }
      }
    }
  }

  .contactInfo {
    margin-bottom: 4%;

    .basicInfo__addressDiv {
      padding-top: 3%;
    }

    .contactDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 3% 0;

      .contactInputDiv {
        width: 45%;
      }
    }

    .addressDivFlex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3%;

      .addressDiv {
        width: 45%;
      }
    }

    .extWebsiteGrid {
      display: grid;
      grid-template-columns: 45% 45%;
      grid-template-rows: auto auto;
      gap: 10px;
      justify-content: space-between;

      .extWebsiteDiv {
        display: flex;
        width: 100%;
        height: 55px;
        align-items: center;
        justify-content: flex-start;

        .socialIcon {
          margin-right: 10px;

          img {
            margin-left: -15px;
          }
        }
      }
    }
  }

  .preferences {
    margin-bottom: 4%;

    &__content {
      padding: 3% 0;

      .mRight {
        margin-right: 8px;
      }

      .listingTitle {
        display: flex;
        align-items: flex-start;
        font-size: 19px;
        font-family: "L-Regular";
        margin-bottom: 2px;

        .defaultSessInfo {
          &:hover {
            small {
              opacity: 1;
            }
          }
          svg {
            width: 22px;
            height: 22px;
            margin-bottom: 1px;
            cursor: pointer;
            margin-right: 5px;
          }
        }
        small {
          font-size: 12px;
          color: rgb(100, 98, 98);
          opacity: 0;
          transition: all 0.2s ease-out;
        }
      }

      .selectedOptions {
        background: #62bfae;
        color: white;
        margin: 5px 1.5%;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 12px;
        font-family: "L-SBold";
        text-transform: uppercase;
      }

      .therapyTypeOptions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        align-items: center;
      }

      .therapyTypeWrapTwo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .typeSessionFee {
          display: flex;
          align-items: center;
          font-family: "L-Regular";

          input {
            width: 150px;
            background-color: #fff;
            border: none;
            outline: none;
            padding: 5px 8px;

            &::placeholder {
              color: rgb(179, 175, 175);
            }
          }

          &_currency {
            background-color: #ffff;
            border-left: 1px solid #f0ebe7;
            padding: 5px 8px;
            color: rgb(116, 97, 83);
            // background-color: #62bfae;
          }

          p {
            margin-bottom: 0px;
            padding-left: 15px;
            font-size: 16px;
            text-transform: uppercase;
          }
        }
      }

      .availabilityOptions {
        display: grid;
        grid-template-columns: 1fr;
      }

      .rowFlex {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80%;
      }

      .weekDaysLabel {
        width: 25%;
      }

      .selectedTimesDiv {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        justify-content: space-between;

        .selectedTimes {
          margin: 0;
          color: rgb(66, 65, 65);
          display: flex;
          justify-content: space-between;

          :first-child {
            margin-right: 10px;
            border: 1px solid rgb(131, 129, 129);
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            font-size: 13px;
            border-radius: 3px;
            background: rgb(255, 255, 255);
          }

          :nth-child(2) {
            border: 1px solid rgb(124, 123, 123);
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            font-size: 13px;
            border-radius: 3px;
            background: rgb(255, 255, 255);
          }
        }
      }

      .availableAddBtn {
        background: transparent;
        font-size: 18px;
        border-radius: 5px;
        margin-left: 30px;
        padding: 5px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;

        .svgIcon {
          width: 100%;
          height: 100%;
        }
      }

      .styledCheckbox {
        opacity: 0;

        & + .topLabel {
          position: relative;
          cursor: pointer;
          padding: 0;
          -moz-user-select: none;
          user-select: none;
          color: black;
          font-size: 15px;
        }

        & + .topLabel::before {
          content: "";
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          background: #fff;
        }

        &:checked + .topLabel:before {
          background: #62bfae;
        }

        &:checked + .topLabel {
          color: #62bfae;
        }

        &:disabled + .topLabel {
          color: #000;
          cursor: auto;
        }

        &:disabled + .topLabel:before {
          box-shadow: none;
        }
      }

      .sessionFeeDiv {
        width: 90%;
        display: flex;
        margin-left: 15px;
        margin-top: 10px;

        .sessionFeeInput {
          background: #f6f5f9;
        }

        .prepend {
          .prependInner {
            background: #62bfae !important;
            color: white;
          }
        }
      }

      .therapistInCity {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2% 0;

        .labelOne {
          display: flex;
          align-items: flex-start;
          padding-right: 5%;
          font-size: 19px;
          font-family: "L-Regular";
        }
      }

      .businessName {
        margin-left: 18px;
        width: 90%;
        margin-top: 6px;
      }

      .specificTherapies {
        padding-bottom: 2%;

        .selectedOptionsDiv {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
        }
      }
    }
  }

  .approveProfile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 5px 0;
  }

  .accountActivate {
    font-size: 16px;
    text-transform: uppercase;
    font-family: "L-Regular";
    padding: 2px 0 10px 0;
    border: none;
    outline: none;
    background: transparent;
    transition: all 0.3s ease-out;

    &:hover {
      letter-spacing: 1.1px;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .profileClient {
    &::-webkit-scrollbar {
      display: none !important;
    }

    .ProfileClientInnerDiv {
      .profileInput {
        font-size: 17px;
      }

      .addInputLabel {
        font-size: 14px;
        font-family: "L-Regular";
        margin-bottom: 8px;
      }
    }

    &__heading {
      font-size: 20px;
    }

    .basicInfo {
      &__content {
        .basicDetail {
          .usernameDiv {
            .socialIcon {
              margin-right: 15px;
            }
          }
        }
      }
    }

    .preferences {
      &__content {
        .listingTitle {
          font-size: 17px;
        }

        .therapistInCity {
          .labelOne {
            font-size: 17px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .profileClient {
    height: 100%;
    justify-content: center;

    .ProfileClientInnerDiv {
      padding: 5% 10%;
      min-height: 65vh;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .profileClient {
    display: flex !important;
    justify-content: flex-start;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;

    .ProfileClientInnerDiv {
      padding: 2% 2% 0% 2%;
      overflow: hidden;
      height: auto !important;
      margin-bottom: 0;

      .profileInput {
        border: none;
        width: 100%;
        background: transparent;
        border-bottom: 1px solid black;
        font-size: 16px;
        font-family: "L-Light";
        outline: none;
      }

      .addInputLabel {
        font-size: 14px;
        font-family: "L-Regular";
        margin-bottom: 8px;
      }
    }
    &__title {
      border-bottom: 2px solid #62bfae;

      .saveButton {
        flex-direction: column;
      }
    }
    &__heading {
      font-size: 16px;
      margin-bottom: 3px;
      color: #62bfae;
      text-transform: uppercase;
      font-family: "L-Regular";
    }

    .basicInfo {
      margin-bottom: 40px;

      &__content {
        flex-direction: column;
        padding-top: 30px;
        padding-bottom: 0;
        .profileImgDiv {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 5%;
        }

        .basicDetail {
          width: 100%;
          display: flex;
          align-items: flex-start;
          border-left: none;
          flex-direction: column;
          padding-top: 3%;
          padding-left: 0;

          .mobRes {
            align-items: flex-start !important;
          }

          .usernameDiv {
            width: 100%;

            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;

            .profileInputDiv {
              width: 100%;
              display: flex;
              height: 90px !important;
              justify-content: space-between;
              margin-bottom: 30px;
              flex-direction: column;

              .inputWrapper {
                width: 100%;
              }

              input {
                width: 100%;
              }
            }

            .socialIcon {
              margin-right: 0px;
              margin-top: 5px;
              margin-left: -10px;
            }
          }
        }
      }
    }

    .contactInfo {
      margin-bottom: 40px;

      .basicInfo__addressDiv {
        padding-top: 6%;
        padding-bottom: 6%;
      }

      .contactDiv {
        flex-direction: column;
        width: 100%;

        .contactInputDiv {
          width: 100%;
          padding-bottom: 20px;
        }
      }

      .addressDivFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3%;

        .addressDiv {
          width: 100%;
          padding-bottom: 20px;
        }
      }

      .extWebsiteGrid {
        grid-template-columns: 100%;

        .extWebsiteDiv {
          height: 45px;
        }
      }
    }

    .preferences {
      &__content {
        padding: 3% 0;
        .listingTitle {
          font-size: 17px;
        }

        .selectedOptions {
          background: #62bfae;
          color: white;
          margin: 5px 5%;
          padding: 5px 10px;
          border-radius: 15px;
          font-size: 11px;
          font-family: "L-SBold";
          text-transform: uppercase;
        }

        .therapyTypeWrapTwo {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .typeSessionFee {
            margin-left: 13px;
            margin-bottom: 10px;
            input {
              width: 100px;
              padding: 3px 5px;
              font-size: 14px;
            }

            &_currency {
              padding: 3px 5px;
              font-size: 14px;
            }
          }
        }

        .rowFlex {
          display: grid;
          grid-template-columns: auto auto;
          width: 90%;
          justify-content: space-between;
        }

        .weekDaysLabel {
          order: 1;
        }

        .availableAddBtn {
          order: 2;
          margin-left: 10px;

          .svgIcon {
            width: 100%;
            height: 100%;
          }
        }

        .selectedTimeRange {
          order: 3;
          width: 100%;
        }
        .selectedTimesDiv {
          display: flex;
          margin-bottom: 10px;
          align-items: center;

          .selectedTimes {
            margin: 0;
            color: rgb(66, 65, 65);

            :first-child {
              width: 80px;
              font-size: 12px;
            }

            :nth-child(2) {
              width: 80px;
              font-size: 12px;
            }
          }
        }

        // .availableAddBtn {
        //   margin-left: 20px;

        //   .svgIcon {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }

        .mobileRes {
          grid-template-columns: repeat(1, 1fr);
          margin: 2% 0;
        }

        .styledCheckbox {
          & + .topLabel {
            font-size: 14px;
          }
        }

        .therapistInCity {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 2% 0;

          .labelOne {
            font-size: 17px;
            margin-top: 10px;
          }
        }

        .specificTherapies {
          padding-bottom: 2%;

          .selectedOptionsDiv {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
          }
        }
      }
    }

    &__footer {
      display: none;
    }
  }
}
