.topFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goBack {
  margin: 10px;
  width: 30px;
  cursor: pointer;
}

.topTabs {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  height: 70px;

  .navPills {
    .navPillsList {
      margin: 0 20px;
      color: white;

      .navLink {
        color: white;
        font-size: 20px;
        font-family: "L-Regular";
        text-transform: uppercase;
        padding: 10px 5px;

        &:hover {
          // color: #62bfae !important;
          text-decoration: none;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}

.reportDetails {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__innerDiv {
    height: 75vh !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

    .selectDates {
      padding: 2% 0 0 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .to {
        margin: 0;
      }

      .GetReports {
        border: none;
        outline: none;
        background: #62bfae;
        color: white;
        font-size: 16px;
        font-family: "L-Regular";
        text-transform: uppercase;
        height: 40px;
        border: 1px solid transparent;
        border-radius: 0.35vh;
        margin: 0 10px;
        transition: all 0.2s linear;

        &:hover {
          background: white;
          color: #62bfae;
        }
      }

      .firstCalender,
      .secondCalender {
        background: rgb(246, 243, 243);
        line-height: 1.7rem;
        font-size: 12px;
      }

      .firstInput,
      .secondInput {
        margin: 0 25px;
        border: 1px solid #62bfae;
        height: 40px;
        padding: 0 10px;
        outline: none;
      }
    }

    .contentDiv {
      height: 65vh;
      margin-top: 20px;
      overflow-y: hidden;

      .contentHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3%;
        background: #62bfae;
        color: white;
        font-size: 17px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        text-transform: uppercase;
        font-family: "L-Bold";
        height: 60px;
        border-bottom: 1px solid white;
        span {
          display: flex;
          justify-content: center;
          width: 25%;
        }
      }

      .contentBody {
        background: white;

        // padding-bottom: 4%;
        border-radius: 23px;
        overflow: hidden;

        .contentRow {
          .singleContent {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 120px;
            background: #fff;
            border-bottom: 1px solid #f9ddd2;
            padding: 0 3%;
            color: black;
            cursor: pointer;

            &:hover {
              text-decoration: none;
              background: #62bfae;
              color: #fff;
            }

            p {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
              font-size: 18px;
              font-family: "L-Regular";
              text-transform: capitalize;
            }

            .client {
              display: flex;
              align-items: center;
              justify-content: center;
              .profile_imageDiv {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
                border: 1px solid #62bfae;

                .profile_image {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 50%;
                  border: 1px solid #fff;
                }
              }

              .firstName {
                font-size: 18px;
                font-family: "L-Regular";
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }

  .ReportFooterLink {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5%;
  }
}

@media only screen and (max-width: 1600px) {
  .goBack {
    margin: 10px;
    width: 30px;
    cursor: pointer;
  }

  .topTabs {
    height: 50px;

    .navPills {
      .navPillsList {
        .navLink {
          font-size: 14px;
        }
      }
    }
  }

  .reportDetails {
    &__innerDiv {
      .contentDiv {
        .contentHead {
          font-size: 13px;
          height: 45px;
        }

        .contentBody {
          background: white;
          overflow-y: auto;

          .contentRow {
            .singleContent {
              height: 90px;

              p {
                font-size: 14px;
              }

              .client {
                .firstName {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .ReportFooterLink {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 5%;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .reportDetails {
    &__innerDiv {
      height: 70vh !important;
    }

    .ReportFooterLink {
      bottom: 3%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .topFlex {
    // flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-left: 2%;
    margin-bottom: 2%;
  }

  .goBack {
    margin: 0;
    width: 20px;
    margin-right: 10px;

    .backIcon {
      width: 15px;
      height: 15px;
    }
  }

  .topTabs {
    height: auto;
    justify-content: flex-end;

    .navPills {
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 97%;
      .navPillsList {
        margin: 5px 3px;
        .navLink {
          font-size: 10px;
          padding: 5px 3px;
        }
      }
    }
  }

  .reportDetails {
    justify-content: flex-start !important;
    height: auto;

    &__innerDiv {
      height: 100% !important;
      display: flex;
      flex-direction: column;
      margin-bottom: 5%;
      padding-bottom: 80px;

      // .topFlex {
      //   display: flex;
      //   align-items: flex-start;
      //   justify-content: space-between;
      //   flex-direction: column;
      // }

      // .goBack {
      //   margin: 0 0 10px 8px;
      //   width: 20px;
      //   cursor: pointer;

      //   .backIcon {
      //     width: 15px;
      //   }
      // }

      // .topTabs {
      //   width: 100%;

      //   .navPills {
      //     width: 100%;
      //     justify-content: space-between;

      //     .navPillsList {
      //       margin: 0 3px;
      //       color: black;

      //       .navLink {
      //         color: black;
      //         padding: 5px 2px;
      //         font-size: 14px;
      //       }
      //     }
      //   }
      // }

      .selectDates {
        flex-direction: column;
        padding-top: 20px;

        .firstCalender,
        .secondCalender {
          width: 100%;
          padding: 0 5px;
          display: flex;
          line-height: 1.5rem;
          margin-bottom: 0px;
          align-items: center;
          justify-content: center;
        }

        .firstInput,
        .secondInput {
          margin: 15px 0;
          border: 1px solid #62bfae;
          height: 40px;
          padding: 0 10px;
          outline: none;
          width: 200px;
        }

        .GetReports {
          margin: 20px 0 20px 0;
        }
      }

      .contentDiv {
        height: 100%;
        margin-top: 20px;
        overflow-y: auto;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .contentHead {
          padding: 0 2%;
          font-size: 11px;

          span {
            display: flex;
            justify-content: center;
            width: 25%;
          }
        }

        .contentBody {
          background: white;
          overflow-y: auto;
          height: 1;
          padding-bottom: 4%;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          .contentRow {
            .singleContent {
              padding: 0 2%;

              p {
                font-size: 11px;
                align-items: flex-start !important;
                text-align: center;
              }

              .client {
                display: flex;
                align-items: center;
                justify-content: center;

                .profile_imageDiv {
                  display: none;
                }

                .firstName {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }

    .ReportFooterLink {
      display: none;
    }
  }
}
