.search {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 901;
  background-color: #fff;
  display: flex;

  &__close {
    position: absolute;
    top: 25px;
    cursor: pointer;
    z-index: 100;
    width: 45px;
    height: 45px;
    background-color: #62bfae;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3.5%;
    cursor: pointer;

    &:hover {
      .backIcon {
        transform: translateX(-5px);
      }
    }

    .backIcon {
      transition: all 0.3s ease-out;
    }
  }

  .searchContainer {
    width: 75%;
    max-width: 1350px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    order: 2;

    form {
      width: 50%;
      display: flex;
      justify-content: center;
      margin: 10px 10px 10px auto;
    }
    .searchInputDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid #62bfae;
      padding-bottom: 5px;

      &__input {
        flex: 1;
        background-color: transparent;
        outline: none;
        border: none;
        height: auto;
        font-family: "L-Light";
        font-size: 30px;
        color: #62bfae;
        padding: 10px;
        margin: 1% 2% 0 0%;
        // cursor: pointer;
        border-radius: 9px;

        &::placeholder {
          font-family: "L-Light";
          font-size: 30px;
          // color: rgba($color: #62bfae, $alpha: 0.2);
          color: #62bfae;
          &:hover {
            background-color: #b4c0d3;
            color: #fff;
          }
        }
      }
    }

    .publicTherapistLists {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 90vh;
      overflow-y: auto;
      margin-top: 1%;
      padding-bottom: 10%;
      background-color: #f8f8f8;
    }

    .recomProfileListsMain {
      width: 100%;
      height: 100%;
      padding-left: 3%;

      .searchHead {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-right: 5%;
        // max-width: 1000px;
      }

      .loadingText {
        text-align: center;
        font-size: 24px;
        font-family: "L-Medium";
        margin: 10px 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .sortByFilter {
        display: flex;
        align-items: center;
        padding-left: 30px;

        .sortBy {
          border: 1px solid #e6eaea;
          background: #f4f7f6;
          height: 38px;
          display: flex;
          white-space: nowrap;
          align-items: center;
          font-size: 14px;
          font-family: "L-Regular";
          border-right: none;
          padding: 0 5px;
          color: #797f88;
        }

        .resultsCount {
          display: flex;
          padding-left: 15px;
          margin: 0;
          white-space: nowrap;
        }
      }
    }

    .resultCount {
      margin: 10px 0;
      font-size: 19px;
      color: #9097a2;
      font-family: "L-Medium";
      // padding-left: 5%;
      span {
        color: #000;
      }
    }

    .recomProfileLists {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      // height: calc(100vh - 128px);
      overflow-y: auto;
      // border-top: 2px solid #62bfae;
      padding-bottom: 150px;

      .loadMore {
        position: relative;
        width: 370px;
        height: 300px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "L-Regular";

        padding: 30px;
        margin: 2%;
        cursor: pointer;
        background-color: #62bfae;
        border-radius: 9px;

        &__text {
          position: absolute;
          color: #62bfae;
          left: 50%;
          top: 50%;
          font-size: 22px;
          transform: translate(-50%, -50%);
        }
      }
    }

    .nothingResContainer {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 4%;

      .nothingRes {
        width: 70%;
        font-size: 30px;
        margin-top: 35px;
        padding: 0 5%;
        text-align: center;
        color: #202020;
      }
    }

    .noResultContainer {
      display: flex;

      justify-content: center;
      width: 100%;
      margin-top: 3%;
      &__div {
        width: 80%;
        background-color: #ffffff;
        // border-radius: 12px;

        &__txt {
          padding: 1.5% 0;
          margin: 0;
          font-size: 20px;
          font-family: "L-Medium";
        }
      }
    }

    .srContianer {
      margin-top: 3%;
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      // border-radius: 12px;
      height: 100%;
      overflow: hidden;

      &__resdiv {
        background-color: #f8f8f8;
        border-bottom: 2px solid #fff;
        margin-bottom: 3px;
        margin: auto;
        &__txt {
          margin: 0;
          padding: 1.5% 0;
          font-size: 20px;
          font-family: "L-Medium";
          text-align: center;
        }
      }

      &__results {
        width: 100%;
        display: flex;
        // justify-content: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        height: 100%;
        overflow-y: auto;

        padding-bottom: 15%;
        // padding: 2% 2% 15% 3%;
        background: #f8f8f8;
      }
    }
  }

  .searchFilter {
    width: 25%;
    min-width: 430px;
    // display: none;
    order: 1;
    background: #f9ddd2;
    border-right: 2px solid #ffffff;
    padding: 100px 3.5% 2% 3.5%;
    height: 100%;
    overflow-y: auto;
  }
}

.searchChangeBg {
  background-color: #f8f8f8;
}

// Search Recommendation
.searchRecomm {
  &__title {
    margin: 0;
    font-size: 20px;
    font-family: "L-Regular";
    position: relative;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
  }

  &__viewMore {
    font-size: 15px;
    font-family: "L-Bold";
    color: #62bfae;
    text-transform: uppercase;
    text-align: end;
    right: 0;
    position: absolute;
    outline: none;
    background: transparent;
    border: none;
    margin-top: 5px;

    &:hover {
      text-decoration: none;
    }
  }

  &__items {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__buttonDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .viewMore {
      width: 162px;
      height: 41px;
      border-radius: 2px;
      background-color: #62bfae;
      color: #fff;
      font-size: 18px;
      font-family: "L-Regular";
      text-transform: uppercase;
      text-align: center;
      padding: 5px 10px;
      outline: none;
      border: none;
      margin: 0 5px;
    }
  }
}

// Search Filter

.filterDesktop {
  display: block;
}

.SearchFilter {
  .findTherapist {
    &__label {
      font-size: 18px;
      font-family: "L-Regular";
    }

    &__inputDiv {
      border-bottom: 1px solid #000;
      padding-bottom: 6px;
      display: flex;
      align-items: center;
      width: 100%;

      svg {
        width: 20px;
        height: 20px;
      }

      .cityInput {
        font-family: "L-Light";
        outline: none;
        background: transparent;
        border: none;
        // margin-left: 10px;
        width: 90%;

        &::placeholder {
          font-size: 18px;
          font-family: "L-Light";
        }
      }
    }
  }

  &__title {
    margin: 0;
    font-size: 26px;
    font-family: "L-Regular";
    position: relative;
    padding-bottom: 5px;
    padding-top: 20px;
    line-height: 120%;
  }

  &__inputDiv {
    display: none;
  }

  .viewFilters {
    display: none;
  }

  &__content {
    padding: 5% 0;
    // display: none;

    .dropdownFilter {
      padding: 1% 0 2% 0;
      .counsellingArea {
        padding: 4% 0;

        .availCalenderDiv {
          padding: 20px 0 0 0;
          width: 100%;
          display: flex;
          justify-content: center;

          .availCalender {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          .calenderInput {
            width: 100%;
            border: none;
            height: 45px;
            display: flex;
            padding: 0 12px;
            justify-content: center;
            outline: none;
            color: #62bfae;
            font-size: 20px;

            &::placeholder {
              color: #b4c0d3;
            }
          }
        }

        .listingTitle {
          margin: 0;
          font-size: 18px;
          font-family: "L-Regular";
          line-height: 100%;
        }

        .smallText {
          font-size: 14px;
          font-family: "L-Light";
        }

        .therapyTypeOptions {
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 100%;
          align-items: center;
        }

        .styledCheckbox {
          position: absolute;
          opacity: 0;

          & + .topLabel {
            position: relative;
            cursor: pointer;
            padding: 0;
            -moz-user-select: none;
            user-select: none;
            color: #9097a2;
            font-size: 20px;
          }

          & + .topLabel::before {
            content: "";
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            background: #fff;
          }

          &:checked + .topLabel:before {
            background: #62bfae;
          }

          &:checked + .topLabel {
            color: #62bfae;
          }

          &:disabled + .topLabel {
            color: #b8b8b8;
            cursor: auto;
          }

          &:disabled + .topLabel:before {
            box-shadow: none;
            background: #ddd;
          }
        }
      }
    }

    .btnDiv {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 5%;

      .submit {
        width: 134px;
        height: 41px;
        outline: none;
        border: none;
        background: #62bfae;
        color: white;
        font-size: 18px;
        font-family: "L-Regular";
        text-transform: uppercase;
      }

      .clear {
        margin-right: 10px;
        background: #b4c0d3;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .search {
    .searchFilter {
      .searchRecomm {
        img {
          width: 200px;
        }

        .SearchFilter__title {
          font-size: 22px;
        }
      }
    }

    .searchContainer {
      .searchInputDiv {
        &__input {
          padding-bottom: 5px;
        }
      }

      .recomProfileListsMain {
        .loadingText {
          font-size: 18px;
          width: 75%;
        }
      }

      .recomProfileLists {
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        overflow-y: auto;

        .nothingResContainer {
          img {
            width: 290px;
            height: 200px;

            object-position: center;
          }

          .nothingRes {
            width: 70%;
            font-size: 22px;
          }
        }
      }

      .headingDiv {
        img {
          width: 280px;
          height: 150px;
        }

        &__txt {
          font-size: 28px;
        }
      }

      .searchInputDiv {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1.2px solid #62bfae;
        padding-bottom: 2px;

        &__input {
          flex: 1;
          background-color: transparent;
          outline: none;
          border: none;
          height: auto;
          font-family: "L-Light";
          font-size: 22px;
          color: #62bfae;

          &::placeholder {
            font-size: 22px;
          }
        }

        label {
          margin-bottom: 0;
        }

        .searchIcon {
          width: 22px;
          height: 22px;
        }
      }

      .noResultContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 3%;
        &__div {
          width: 80%;
          background-color: #ffffff;
          // border-radius: 12px;

          &__txt {
            padding: 1.5% 0;
            margin: 0;
            font-size: 20px;
            font-family: "L-Medium";
          }
        }
      }

      .srContianer {
        margin-top: 2%;
        background-color: rgba($color: #ffffff, $alpha: 0.8);
        // border-radius: 12px;
        height: 100%;
        overflow: hidden;

        &__resdiv {
          background-color: #f8f8f8;
          border-bottom: 2px solid #fff;
          margin-bottom: 3px;
          margin: auto;
          &__txt {
            margin: 0;
            padding: 1.5% 0;
            font-size: 20px;
            font-family: "L-Medium";
            text-align: center;
          }
        }

        &__results {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          height: 100%;
          overflow-y: auto;
          // padding-bottom: 15%;
          padding: 2% 2% 15% 2%;
          background: #f8f8f8;
        }
      }
    }
  }

  // Search Recommendation
  .searchRecomm {
    &__title {
      font-size: 16px;
    }

    &__viewMore {
      font-size: 10px;
    }

    &__items {
      padding-top: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__buttonDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5%;

      .viewMore {
        width: 130px;
        height: 35px;
        font-size: 14px;
      }
    }
  }

  .SearchFilter {
    &__content {
      padding-top: 1%;

      .dropdownFilter {
        .counsellingArea {
          .listingTitle {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .search {
    // height: calc(100vh - 60px);
    top: 0;
    overflow: hidden;
    bottom: 60px;
    width: 100vw;
    flex-direction: column;

    .searchFilter {
      width: 100%;
      padding: 0;
      height: auto;
      min-height: 90px;
    }

    .searchContainer {
      padding-top: 0 !important;

      .recomProfileLists {
        justify-content: center;

        .loadMore {
          margin: 1%;
        }
      }

      .recomProfileListsMain {
        padding-left: 0;

        .loadingText {
          width: 100%;
        }
      }
    }
  }

  .searchRecomm {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__buttonDiv {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .filterDesktop {
    display: none;
  }

  .SearchFilter {
    .findTherapist {
      &__label {
        font-size: 14px;
        margin-bottom: 2px;
      }

      &__inputDiv {
        // padding-bottom: 2px;
        margin-bottom: 15px;

        svg {
          width: 15px;
          height: 15px;
        }

        .cityInput {
          &::placeholder {
            font-size: 15px;
          }
        }
      }
    }

    &__title {
      font-size: 16px;
      line-height: 100%;
      display: none;
      // padding-left: 10px;
    }

    &__mobSearchDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inputDiv {
      display: block;
      border-bottom: 1px solid #000;
      padding-bottom: 3px;
      width: 70%;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__input {
      font-family: "L-Light";
      outline: none;
      background: transparent;
      border: none;
      margin-left: 5px;

      &::placeholder {
        font-size: 16px;
        font-family: "L-Light";
      }
    }

    .viewFilters {
      display: block;
      font-size: 22px;
      color: #62bfae;
      margin: 0 !important;
      padding-bottom: 10px;
      position: absolute;
      top: 20px;
      right: 5%;

      // padding-left: 10px;

      svg {
        margin-left: 10px;
        width: 28px;
      }
    }

    &__content {
      padding: 12px;
      background-color: #f9ddd2;
      position: fixed;
      top: 90px;
      padding-bottom: 100px;
      bottom: 0;
      right: 0;
      overflow-y: auto;
      z-index: 100;
      max-width: 400px;
      width: 100%;
    }
  }

  .searchFilterSecond {
    background-color: #fff !important;
    order: 1 !important;
  }

  .searchContainerSecond {
    height: calc(100% - 100px) !important;
    order: 2 !important;
    width: 100vw !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .search {
    // height: calc(100vh - 90px);
    top: 0;
    bottom: 0;
    overflow-y: hidden;

    .searchContainer {
      // width: 55%;
      padding: 5% 0;
      height: 100%;

      .headingDiv {
        img {
          width: 160px;
          height: 80px;
        }

        &__txt {
          font-size: 22px;
        }
      }

      .recomProfileLists {
        justify-content: center;

        .nothingResContainer {
          img {
            width: 250px;
            height: 160px;
            object-position: center;
          }

          .nothingRes {
            width: 90%;
            font-size: 18px;
          }
        }
      }

      form {
        max-width: 550px;
      }

      .searchInputDiv {
        &__input {
          &::placeholder {
            font-size: 18px;
          }
        }

        .searchIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .searchFilter {
      // width: 45%;
      min-width: 300px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .search {
    height: calc(100vh - 60px);
    top: 0;
    overflow: hidden;
    bottom: 60px;
    width: 100vw;
    flex-direction: column;

    &__close {
      width: 35px;
      height: 35px;
      top: 15px;
    }

    .searchContainer {
      width: 100%;
      order: 1;
      min-height: 200px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0;
      padding: 0% 0 0% 0;
      z-index: -10;

      .publicTherapistLists {
        justify-content: center;
      }

      .recomProfileListsMain {
        width: 100%;
        height: 100%;
        padding-left: 0;
        margin-top: 0;
        padding-bottom: 10%;

        .searchHead {
          display: flex;
          flex-direction: column;
          padding-right: 0;

          .sortByFilter {
            justify-content: end;
            width: 95%;
          }
        }

        .loadingText {
          font-size: 16px;
          margin: 5px 0;
        }
      }

      .resultCount {
        // margin: 0px 0;
        font-size: 16px;
        padding-left: 10px;
      }

      .recomProfileLists {
        display: flex;
        justify-content: center;
        align-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: calc(100vh - 171.5px);
        // height: 95%;
        overflow-y: auto;
        padding-left: 0%;
        margin-top: 1%;
        padding-bottom: 200px;
        padding-top: 1%;
        border-top: 2px solid #fff;

        .nothingResContainer {
          padding-top: 6%;

          img {
            width: 230px;
            height: 130px;
            object-position: center;
          }

          .nothingRes {
            width: 90%;
            font-size: 16px;
          }
        }
      }

      .headingDiv {
        margin-bottom: 2%;
        margin-top: 20px;

        img {
          display: none;
        }

        &__txt {
          font-size: 17px;
          margin-bottom: 0;
        }
      }

      form {
        width: 95%;
        // margin-top: 40px;
        margin: 5px auto 8px auto;
      }

      .searchInputDiv {
        margin-top: 1%;
        width: 95%;
        padding-bottom: 3px;
        border-bottom: 1px solid #62bfae;

        &__input {
          font-size: 18px;
          padding: 5px;

          &::placeholder {
            font-size: 18px;
          }
        }

        .searchIcon {
          width: 20px;
          height: 20px;
        }
      }

      .noResultContainer {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 3%;
        &__div {
          width: 80%;
          background-color: #ffffff;
          // border-radius: 12px;

          &__txt {
            font-size: 16px;
          }
        }
      }

      .srContianer {
        // height: 100% !important;

        &__resdiv {
          background-color: #eff2f8;
          border-bottom: 2px solid #fff;
          margin-bottom: 3px;
          margin: auto;
          &__txt {
            font-size: 16px;
          }
        }

        &__results {
          height: 100% !important;
          padding-bottom: 20%;
        }
      }
    }

    .searchFilter {
      width: 100%;
      min-width: 350px;
      order: 2;
      // padding: 70px 3.5% 2% 3.5%;
      padding: 10px 3.5% 2% 3.5%;

      background-color: #f9ddd2;
      height: auto;
      min-height: 60px;
    }
  }

  // Search Filter

  .searchFilterSecond {
    background-color: #fff !important;
    order: 1 !important;
  }

  .searchContainerSecond {
    height: calc(100% - 100px) !important;
    order: 2 !important;
  }

  .filterDesktop {
    display: none;
  }

  .SearchFilter {
    .findTherapist {
      &__label {
        font-size: 14px;
        margin-bottom: 2px;
      }

      &__inputDiv {
        // padding-bottom: 2px;
        margin-bottom: 15px;

        svg {
          width: 15px;
          height: 15px;
        }

        .cityInput {
          &::placeholder {
            font-size: 15px;
          }
        }
      }
    }

    &__title {
      font-size: 16px;
      line-height: 100%;
      // padding-left: 10px;
    }

    &__mobSearchDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__inputDiv {
      display: block;
      border-bottom: 1px solid #000;
      padding-bottom: 3px;
      width: 70%;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    &__input {
      font-family: "L-Light";
      outline: none;
      background: transparent;
      border: none;
      margin-left: 5px;

      &::placeholder {
        font-size: 16px;
        font-family: "L-Light";
      }
    }

    .viewFilters {
      display: block;
      font-size: 14px;
      color: #62bfae;
      margin: 0;
      padding-bottom: 10px;

      // padding-left: 10px;

      svg {
        margin-left: 10px;
        width: 20px;
      }
    }

    &__content {
      padding: 12px;
      background-color: #f9ddd2;
      position: fixed;
      // left: 0;
      top: 60px;
      padding-bottom: 100px;
      bottom: 0;
      right: 0;
      overflow-y: auto;

      .dropdownFilter {
        padding: 2% 0 2% 0;

        width: 100%;

        .counsellingArea {
          padding: 2% 0;

          .listingTitle {
            font-size: 14px;
          }

          .smallText {
            font-size: 12px;
          }

          .therapyTypeOptions {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            align-items: center;
            margin-left: 0px !important;
            margin-top: 0px !important;
          }

          .filterTherapyType {
            margin-bottom: 0 !important;
          }

          .styledCheckbox {
            position: absolute;
            opacity: 0;

            & + .topLabel {
              position: relative;
              cursor: pointer;
              padding: 0;
              -moz-user-select: none;
              user-select: none;
              color: #9097a2;
              font-size: 12px;
              margin-bottom: 5px;
            }

            & + .topLabel::before {
              content: "";
              margin-right: 10px;
              width: 14px;
              height: 14px;
            }

            &:checked + .topLabel:before {
              background: #62bfae;
            }

            &:checked + .topLabel {
              color: #62bfae;
            }

            &:disabled + .topLabel {
              color: #b8b8b8;
              cursor: auto;
            }

            &:disabled + .topLabel:before {
              box-shadow: none;
              background: #ddd;
            }
          }
        }
      }

      .btnDiv {
        z-index: 903;
        .submit {
          width: 90px;
          height: 30px;
          font-size: 12px;
        }

        .clear {
          margin-right: 10px;
          background: #b4c0d3;
        }
      }
    }
  }

  // Search Recommendation
  .searchRecomm {
    // overflow: auto;
    // padding-top: 20px;
    flex-direction: column;
    // align-items: center;
    padding-bottom: 0px;

    img {
      width: 130px !important;
      height: 40px;
    }

    &__title {
      font-size: 15px;
      border-bottom: 1px solid #000;
      line-height: 120%;
      margin: 0 2%;
    }

    &__viewMore {
      font-size: 12px;
      display: none;
    }

    &__items {
      padding-top: 2%;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    &__buttonDiv {
      margin-top: 10px;
      margin-bottom: 10px;
      .viewMore {
        width: 140px;
        height: 30px;
        font-size: 14px;
        padding: 3px 10px;
      }
    }
  }
}
