.stepThreeForm {
  .mRight {
    margin-right: 8px;
  }

  .listingTitle {
    display: flex;
    align-items: flex-start;
    font-size: 19px;
    font-family: "L-Regular";
    margin-bottom: 2px;
    color: black;

    .defaultSessInfo {
      &:hover {
        small {
          opacity: 1;
        }
      }
      svg {
        width: 22px;
        height: 22px;
        margin-bottom: 1px;
        cursor: pointer;
        margin-right: 5px;
      }
    }
    small {
      font-size: 12px;
      color: rgb(100, 98, 98);
      opacity: 0;
      transition: all 0.2s ease-out;
    }
  }

  .therapyTypeOptions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-items: center;
  }

  .therapyTypeWrapTwo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .typeSessionFee {
      display: flex;
      align-items: center;
      font-family: "L-Regular";

      input {
        width: 150px;
        background-color: #f0ebe7;
        border: none;
        outline: none;
        padding: 5px 8px;

        &::placeholder {
          color: rgb(179, 175, 175);
        }
      }

      &_currency {
        background-color: #f0ebe7;
        border-left: 1px solid #fff;
        padding: 5px 8px;
        color: rgb(136, 135, 135);
        // background-color: #62bfae;
      }

      p {
        margin-bottom: 0px;
        padding-left: 15px;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }

  .styledCheckbox {
    opacity: 0;

    & + .topLabel {
      position: relative;
      cursor: pointer;
      padding: 0;
      -moz-user-select: none;
      user-select: none;
      color: black;
    }

    & + .topLabel::before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      background: #e1e6ed;
    }

    &:checked + .topLabel:before {
      background: #62bfae;
    }

    &:checked + .topLabel {
      color: #62bfae;
    }

    &:disabled + .topLabel {
      color: #000;
      cursor: auto;
    }

    &:disabled + .topLabel:before {
      box-shadow: none;
    }
  }

  .sessionFeeDiv {
    display: flex;
    padding: 10px 0px 10px 18px;
    width: 100%;

    .feeInput {
      width: 100%;
      border: none;
      background: #f0ebe7;
      padding: 5px;
      outline: none;
    }

    .currencyCodeDiv {
      border: none;
      background: #62bfae;
      color: white;
    }
  }

  .businessNameInput {
    width: 100%;
    border: none;
    background: #f0ebe7;
    padding: 5px;
    outline: none;
  }

  .stepThreeNext {
    margin: 10% auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #62bfae;
    border: none;
    color: white;
    width: 200px;
    height: 40px;
    transition: all 0.2s linear;

    &:hover {
      letter-spacing: 1.2px;
      background: #62bfaece;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .stepThreeForm {
    .mRight {
      margin-right: 8px;
    }

    .listingTitle {
      font-size: 17px;
    }

    .styledCheckbox {
      opacity: 0;

      & + .topLabel {
        font-size: 14px;
      }

      & + .topLabel::before {
        width: 16px;
        height: 16px;
      }
    }

    .sessionFeeDiv {
      display: flex;
      padding: 10px 0px 10px 18px;
      width: 100%;

      .feeInput {
        width: 100%;
        border: none;
        background: #f0ebe7;
        padding: 5px;
        outline: none;
      }

      .currencyCodeDiv {
        border: none;
        background: #62bfae;
        color: white;
      }
    }

    .businessNameInput {
      width: 100%;
      border: none;
      background: #f0ebe7;
      padding: 5px;
      outline: none;
    }

    .stepThreeNext {
      margin: 10% auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #62bfae;
      border: none;
      color: white;
      width: 200px;
      height: 40px;
      transition: all 0.2s linear;

      &:hover {
        letter-spacing: 1.2px;
        background: #62bfaece;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .stepThreeForm {
    .heading {
      font-size: 22px;

      margin-bottom: 5px;
    }

    .subHeading {
      font-size: 16px;
      line-height: 120% !important;
      margin-bottom: 20px;
    }

    .mRight {
      margin-right: 8px;
    }

    .listingTitle {
      display: flex;
      align-items: flex-start;
      font-size: 15px;
      font-family: "L-Regular";
      margin-bottom: 2px;
      color: black;
    }

    .twoRow {
      grid-template-columns: repeat(1, 1fr);
    }

    .therapyTypeWrapTwo {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .typeSessionFee {
        margin-left: 13px;
        margin-bottom: 10px;
        input {
          width: 100px;
          padding: 3px 5px;
          font-size: 14px;
        }

        &_currency {
          padding: 3px 5px;
          font-size: 14px;
        }
      }
    }

    .styledCheckbox {
      opacity: 0;

      & + .topLabel {
        font-size: 13px;
      }

      & + .topLabel::before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        background: #e1e6ed;
      }

      &:checked + .topLabel:before {
        background: #62bfae;
      }

      &:checked + .topLabel {
        color: #62bfae;
      }

      &:disabled + .topLabel {
        color: #000;
        cursor: auto;
      }

      &:disabled + .topLabel:before {
        box-shadow: none;
      }
    }

    .sessionFeeDiv {
      display: flex;
      padding: 10px 0px 10px 18px;
      width: 100%;

      .feeInput {
        width: 100%;
        border: none;
        background: #f0ebe7;
        padding: 5px;
        outline: none;
      }

      .currencyCodeDiv {
        border: none;
        background: #62bfae;
        color: white;
      }
    }

    .businessNameInput {
      width: 100%;
      border: none;
      background: #f0ebe7;
      padding: 5px;
      outline: none;
    }

    .stepThreeNext {
      margin: 10% auto 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #62bfae;
      border: none;
      color: white;
      width: 200px;
      height: 40px;
      transition: all 0.2s linear;

      &:hover {
        letter-spacing: 1.2px;
        background: #62bfaece;
      }
    }
  }
}
