// Override default variables before the import
$primary: #62bfad;
$secondary: #ffdcd0;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@import "./assets/scss/fonts.scss";

.btn-primary {
  color: #ffffff;
}
