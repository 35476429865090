.mySessions {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__innerDiv {
    height: 75vh !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;

    .mySessionTab {
      height: 50px;
    }

    .tabContent {
      padding: 2% 0 0 0;
      overflow-y: hidden;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .mySessions {
    &__innerDiv {
      .mySessionTab {
        height: 40px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .mySessions {
    height: 100%;
    justify-content: center;

    &__innerDiv {
      min-height: 60vh !important;
      margin-bottom: 7%;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .mySessions {
    height: 100%;

    justify-content: flex-start !important;

    &__innerDiv {
      height: 70vh !important;

      .tabContent {
        height: 100% !important;
        padding: 4% 0;

        .mobScroll {
          height: 100%;
        }
      }
    }

    &__footer {
      display: none;
    }
  }
}
