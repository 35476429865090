.bookingTermsModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;

  &__content {
    width: 100%;
    height: 100%;
    padding: 100px 0 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;

    .bookingClose {
      margin-bottom: 25px;
      cursor: pointer;
    }

    .bookingTerms {
      width: 950px;
      padding: 0 0 2% 0;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 11px;
      position: relative;

      &__head {
        background-color: #62bfae;
        font-size: 37px;
        font-family: "L-Regular";
        width: 100%;
        text-align: center;
        color: #fff;
        padding: 50px 0;
        border-top-left-radius: 11px;
        border-top-right-radius: 11px;
      }

      &__body {
        padding: 2% 10%;
        width: 100%;

        p {
          color: #797f88;
          font-size: 20px;
          line-height: 160%;
          font-family: "L-Regular";
          margin-bottom: 15px;
          display: flex;
        }
      }

      .acceptBtn {
        width: 268px;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #62bfae;
        color: #fff;
        font-family: "L-Regular";
        font-size: 20px;
        text-transform: uppercase;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.2s ease-out;

        &:hover {
          letter-spacing: 0.8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .bookingTermsModal {
    &__content {
      padding: 50px 0 20px;

      .bookingClose {
        margin-bottom: 20px;
      }

      .bookingTerms {
        width: 750px;
        padding-bottom: 1.5%;

        &__head {
          font-size: 24px;
          padding: 20px 0;
        }

        &__body {
          p {
            font-size: 17px;
            line-height: 140%;
            margin-bottom: 10px;
          }
        }

        .acceptBtn {
          width: 180px;
          height: 45px;
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .bookingTermsModal {
    &__content {
      padding: 150px 0 50px;

      .bookingTerms {
        width: 85vw;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .bookingTermsModal {
    &__content {
      padding: 70px 0 50px;

      .bookingTerms {
        width: 95vw;

        &__head {
          font-size: 20px;
          padding: 15px 0;
        }

        &__body {
          padding: 4% 5%;

          p {
            font-size: 15px;
            line-height: 140%;
            margin-bottom: 10px;
          }
        }

        .acceptBtn {
          width: 160px;
          height: 40px;
          font-size: 14px;
        }
      }
    }
  }
}
