.loader {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.329);

  &__spin {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #62bfae #62bfae transparent transparent;
    animation: rotation 1s linear infinite;

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 3px solid;
      border-color: transparent transparent #f3ab8e #f3ab8e;
      width: 100px - 18px;
      height: 100px - 18px;
      border-radius: 50%;
      animation: rotationBack 0.5s linear infinite;
      transform-origin: center center;
    }
    &:before {
      width: 100px - 36px;
      height: 100px - 36px;
      border-color: #fff #fff transparent transparent;
      animation: rotation 1.5s linear infinite;
    }
    @keyframes rotationBack {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(-360deg);
      }
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.fullPageLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.329);
  display: flex;
  align-items: center;
  justify-content: center;
}
