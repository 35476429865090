.footerLinks {
  display: flex;
  align-items: center;

  .footerLink {
    color: #828993;
    font-size: 16px;
    font-family: "L-Bold";
    padding: 0px 10px;
    text-transform: capitalize;

    &:hover {
      text-decoration: none;
      color: #79bcae;
    }
  }

  .brRight {
    width: 2px;
    height: 10px;
    background-color: #828993;
  }
}

@media only screen and (max-width: 1600px) {
  .footerLinks {
    .footerLink {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .footerLinks {
    .footerLink {
      font-size: 16px;
    }
    .brRight {
      height: 16px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .footerLinks {
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;

    .footerLink {
      line-height: 200%;
      padding: 0px 8px;
    }
  }
}
