.basicStats {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2% 0 5% 0;

  .navPills {
    display: flex;
    justify-content: center;
  }

  .navLink {
    color: black;
    font-family: "L-Regular";
    font-size: 18px;
    padding: 0px !important;
    margin: 5px 15px;
    transition: all 0.2s linear;
    text-decoration: none;

    &:hover {
      letter-spacing: 0.3px;
      color: #62bfae;
      border-bottom: 1px solid #62bfae;
    }
  }

  .statsDiv {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 3% 0;
    z-index: 0 !important;

    .statsFirstDiv {
      width: 412px;
      height: 212px;
      background: white;
      border-radius: 11px;
      margin: 0 15px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 5%;

      .totalSession {
        color: #f3ab8e;
        font-size: 50px;
        font-family: "L-Medium";
        margin: 0;
      }

      .statsLabel {
        font-size: 21px;
        font-family: "L-Medium";
        color: #9097a2;
        text-transform: capitalize;
        padding: 5px 0;
      }
    }
  }

  .reportsBtnDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    .viewDetailsBtn {
      width: 160px;
      height: 45px;
      font-size: 16px;
      background: #62bfae;
      color: white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.35vh;
      cursor: pointer;
      transition: all 0.2s linear;

      &:hover {
        text-decoration: none;
        letter-spacing: 1.2px;
      }
    }
  }

  .selectDates {
    padding: 5% 0 2% 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999 !important;

    .to {
      margin: 0;
    }

    .GetReports {
      border: none;
      outline: none;
      background: #62bfae;
      color: white;
      font-size: 16px;
      font-family: "L-Regular";
      text-transform: uppercase;
      height: 40px;
      border: 1px solid transparent;
      border-radius: 0.35vh;
      margin: 0 10px;
      transition: all 0.2s linear;

      &:hover {
        background: white;
        color: #62bfae;
      }
    }

    .firstCalender,
    .secondCalender {
      background: rgb(246, 243, 243);
      line-height: 1.7rem;
      font-size: 12px;
    }

    .firstInput,
    .secondInput {
      margin: 0 25px;
      border: 1px solid #62bfae;
      height: 40px;
      padding: 0 10px;
      outline: none;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .basicStats {
    .navLink {
      font-size: 16px;
    }

    .statsDiv {
      .statsFirstDiv {
        width: 312px;
        height: 150px;
        background: white;
        border-radius: 11px;
        margin: 0 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 5%;

        .totalSession {
          font-size: 40px;
        }

        .statsLabel {
          font-size: 18px;
        }
      }
    }

    .selectDates {
      padding: 5% 0 0.8% 0;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .basicStats {
    margin-top: 50px;

    .removePadding {
      padding: 0 5px !important;
    }
    .navPills {
      display: flex;
      width: 100%;
      justify-content: space-between !important;
    }

    .navLink {
      font-size: 13px;
      margin: 5px 5px 15px 5px;
    }

    .statsDiv {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .statsFirstDiv {
        width: 300px;
        height: 120px;
        margin: 15px 0px;
        padding-left: 10%;

        .totalSession {
          font-size: 40px;
        }

        .statsLabel {
          font-size: 18px;
        }
      }
    }

    .reportsBtnDiv {
      margin-bottom: 30px;
      margin-top: 50px;
    }

    .selectDates {
      flex-direction: column;

      .firstCalender,
      .secondCalender {
        width: 100%;
        padding: 0 5px;
        display: flex;
        line-height: 1.5rem;
        margin-bottom: 0px;
        align-items: center;
        justify-content: center;
      }

      .firstInput,
      .secondInput {
        margin: 15px 0;
        border: 1px solid #62bfae;
        height: 40px;
        padding: 0 10px;
        outline: none;
        width: 200px;
      }

      .GetReports {
        margin: 30px 0 20px 0;
      }
    }
  }
}
