// index page

.parentSection {
  position: relative;
  height: 200vh;
  background: #000;

  .firstSection {
    height: 100vh;
    background: #000 !important;
    z-index: 40;
    position: relative;
    width: 100%;

    .videoDiv {
      // position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      opacity: 0.3;

      .beatStigmaVid {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .secondSection {
    position: fixed;
    top: 0;
    height: 100%;
    overflow-y: auto !important;
    width: 100%;

    z-index: 2;
  }
}

.stigmaDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  height: 100%;
  z-index: 40;

  // position: fixed;

  &_title {
    font-size: 200px;
    color: #f9ddd2;
    font-family: "L-Bold";
    margin: 0;
  }

  &_subTitle {
    width: 80%;
    font-size: 60px;
    font-family: "L-Light";
    color: #f9ddd2;
    margin: 0;
    padding: 1% 0 3% 0;
  }

  &_scrollDown {
    margin-top: 6%;

    .scrollDownText {
      font-size: 30px;
      font-family: "L-Light";
      color: #5dffe1;
      padding: 10% 0;
      letter-spacing: 1.2px;
      text-transform: capitalize;
    }

    svg {
      width: 50px;
      height: 50px;
      animation: scrollArrow 2s infinite ease-in forwards;

      @keyframes scrollArrow {
        0% {
          transform: translateY(30px);
        }

        50% {
          transform: translateY(0px);
        }
        100% {
          transform: translateY(30px);
        }
      }
    }
  }
}

// Post Listing

.listsDiv {
  height: 100%;
  width: 100%;
  background-color: #fff;

  overflow-y: auto;

  &_header {
    background: white;
    width: 100%;
    height: 120px;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    padding: 0 8%;
    z-index: 1;

    .mindsumLogo {
      width: 235px;
      height: 51px;
    }

    .postButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 262px;
      border-radius: 2px;
      height: 58px;
      background: #62bfae;
      color: white;
      font-size: 16px;
      font-family: "L-Regular";
      border: none;
      outline: none;
      letter-spacing: 0.3px;
      transition: all 0.2s linear;
      white-space: nowrap;
      text-transform: capitalize;

      &:hover {
        text-decoration: none;
        letter-spacing: 0.8px;
      }
    }
  }

  &_mainSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(45deg, #f3ab8e85, #62bfae85, #548df785);
    animation: Gradient 20s ease infinite;
    background-size: 300%;
    padding: 120px 6% 0 6%;
    overflow-y: auto;
    overflow-x: hidden;

    @keyframes Gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    .welcomeHeading {
      font-size: 80px;
      color: #fff;
      padding: 2% 0;
      font-family: "L-Bold";
      margin: 0;
      text-align: center;
      line-height: 100%;
    }

    .cardDiv {
      height: 100%;
      overflow-y: auto !important;
      overflow-x: hidden;
      padding-bottom: 100px;
      padding-top: 30px;
      column-count: 3;
      grid-row: auto;
      grid-column-gap: 0;
      column-gap: 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .footerDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 3%;
    }

    .getSupportDiv {
      position: fixed;
      right: 5%;
      bottom: 5%;
      cursor: pointer;

      &:hover {
        .innerText {
          letter-spacing: 0.8px;
        }
      }

      .redBg {
        position: relative;

        svg {
          width: 170px;
          height: 170px;
        }
      }

      .innerText {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        text-align: center;
        font-size: 30px;
        font-family: "L-Bold";
        line-height: 100%;
        color: white;
        text-decoration: none;
        transition: all 0.2s linear;
      }
    }
  }
}

// Card

.card {
  width: 430px;
  padding: 20px;
  background: white;
  display: inline-block;
  margin: 0 50px 80px 50px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s linear;
  overflow-wrap: break-word;

  &:hover {
    background: #2f3032 !important;

    .answer {
      color: white;
    }
  }

  .topBorder {
    width: 140px;
    margin: 5px 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5px;

    &__left {
      width: 50%;
      height: 5px;
    }

    &__right {
      width: 50%;
      height: 5px;
    }
  }

  .question {
    font-size: 40px;
    font-family: "L-Bold";
    color: "#2f3032";
  }

  .user {
    width: 100%;
    display: flex;

    justify-content: flex-end;
    align-items: center;
    padding-top: 50px;
    border-bottom: 1px solid #62bfae;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &__left {
      width: 60%;
      display: flex;
      color: #62bfae;
      // align-items: center;
      flex-wrap: wrap;

      .name {
        margin: 3px 0;

        font-family: "L-Regular";
        font-size: 15px;
        border-left: 2px solid #62bfae;
        line-height: 90%;
        padding: 0 7px;

        &:first-child {
          border-left: none;
          padding-left: 0;
        }
      }
    }

    &__right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .socialLinks {
        margin: 0 6px;
      }
    }
  }

  .answer {
    font-size: 16px;
    font-family: "L-Regular";
    white-space: pre-wrap;

    .readMore {
      color: #62bfae;
    }
  }
}

// Card Details

.cardDetails {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  background: rgba(0, 0, 0, 0.651);
  overflow: hidden;
  height: 100vh !important;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  &__innerDiv {
    width: 1171px;
    // height: 803px;
    padding: 4%;
    background: white;
    border-radius: 8px;
    position: relative;
    max-height: 90%;
    overflow-y: auto;
    overflow-wrap: break-word;

    .closeIcon {
      position: absolute;
      cursor: pointer;
      width: 30px;
      height: 30px;
      right: 20px;
      top: 20px;
    }

    .topBorder {
      width: 120px;
      margin: 0 0 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 8px;

      &__left {
        width: 50%;
        height: 8px;
      }

      &__right {
        width: 50%;
        height: 8px;
      }
    }

    .question {
      font-size: 45px;
      font-family: "L-Bold";
      color: #000;
    }

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 50px;
      border-bottom: 1px solid #62bfae;
      padding-bottom: 10px;
      margin-bottom: 10px;

      &__left {
        display: flex;
        color: #62bfae;
        align-items: center;
        flex-wrap: wrap;

        .name {
          margin: 0;
          font-family: "L-Regular";
          font-size: 20px;
          border-left: 3px solid #62bfae;
          line-height: 90%;
          padding: 0 15px;

          &:first-child {
            border-left: none;
            padding-left: 0;
          }
        }
      }

      &__right {
        display: flex;
        align-items: center;

        .socialLinks {
          margin: 0 6px;

          svg {
            width: 21px;
            height: 21px;
          }
        }
      }
    }

    .answer {
      font-size: 19px;
      font-family: "L-Regular";
      white-space: pre-wrap;
    }
  }
}

// Form

.formDiv {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.671);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;

  .hideForm {
    margin-bottom: 1.8%;
    cursor: pointer;
  }

  .form {
    width: 674px;
    // height: 80vh;
    overflow-y: auto;
    background: white;
    border-radius: 12px;
    padding: 2% 4%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .inputDiv {
      width: 100%;
      margin: 2% 0;

      textarea {
        background: #c2cde2;
        color: #919191;
        padding: 10px;
        font-size: 24px;
        font-family: "L-Regular";
      }

      input {
        border-bottom: 1px solid #b6c0d1 !important;
        padding: 1% 0;
        height: 60px;
        font-size: 19px;
        font-family: "L-Regular";
        color: #9097a2;
      }

      input,
      textarea {
        width: 100%;
        outline: none;
        border: none;
      }
    }

    .alertInfo {
      font-size: 16px;
      font-family: "L-Regular";
      color: #ff5f5f;
      text-align: center;
      margin: 2% 0 4% 0;
    }

    .btnDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 2%;

      .submitBtn {
        width: 200px;
        height: 45px;
        color: white;
        background: #62bfae;
        outline: none;
        border: none;
        text-transform: uppercase;
        border-radius: 0.35vh;
        font-size: 20px;
        font-family: "L-Regular";
        transition: all 0.2s linear;

        &:hover {
          letter-spacing: 1.2px;
          background: #62bfaee0;
        }
      }
    }
    .loader {
      margin-top: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &__spin {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 3px solid;
        border-color: #62bfae #62bfae transparent transparent;
        animation: rotation 1s linear infinite;

        &:after,
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          border: 3px solid;
          border-color: transparent transparent #f3ab8e #f3ab8e;
          width: 42px - 8px;
          height: 42px - 8px;
          border-radius: 50%;
          animation: rotationBack 0.5s linear infinite;
          transform-origin: center center;
        }
        &:before {
          width: 42px - 16px;
          height: 42px - 16px;
          border-color: #fff #fff transparent transparent;
          animation: rotation 1.5s linear infinite;
        }
        @keyframes rotationBack {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(-360deg);
          }
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .stigmaDiv {
    &_title {
      font-size: 150px;
    }

    &_subTitle {
      font-size: 50px;
    }

    &_scrollDown {
      margin-top: 2%;

      svg {
        width: 50px;
        height: 50px;
      }
    }
  }

  // Post Listing

  .listsDiv {
    &_header {
      height: 80px;

      .mindsumLogo {
        width: 160px;
        height: 40px;
      }

      .postButton {
        height: 40px;
        font-size: 16px;
      }
    }

    &_mainSection {
      padding: 80px 6% 0 6%;
      .welcomeHeading {
        font-size: 60px;
      }

      .card {
        width: 350px;
        padding: 20px;
        background: white;
        display: inline-block;
        margin: 0 40px 60px 40px;

        .question {
          font-size: 28px;
        }

        .user {
          padding-top: 30px;

          padding-bottom: 8px;
          margin-bottom: 8px;

          &__left {
            display: flex;
            color: #62bfae;
            align-items: center;

            .name {
              font-size: 13px;
              border-left: 2px solid #62bfae;
              padding: 0 5px;
              margin-bottom: 2px;

              &:first-child {
                border-left: none;
                padding-left: 0;
              }
            }
          }

          &__right {
            display: flex;
            align-items: center;

            .socialLinks {
              margin: 0 4px;

              svg {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .answer {
          font-size: 12px;
        }
      }

      .getSupportDiv {
        .redBg {
          svg {
            width: 100px;
            height: 100px;
          }
        }

        .innerText {
          font-size: 20px;
        }
      }
    }
  }

  // Card Details

  .cardDetails {
    &__innerDiv {
      width: 800px;
      // height: 550px;
      padding: 4%;

      .closeIcon {
        width: 20px;
        height: 20px;
        right: 10px;
        top: 10px;
      }

      .question {
        font-size: 36px;
      }

      .user {
        &__left {
          .name {
            margin: 0;
            font-family: "L-Regular";
            font-size: 16px;
            border-left: 2px solid #62bfae;
          }
        }

        &__right {
          display: flex;
          align-items: center;

          .socialLinks {
            margin: 0 6px;

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .answer {
        font-size: 15px;
        font-family: "L-Regular";
      }
    }
  }

  // Form

  .formDiv {
    .hideForm {
      margin-bottom: 1.5%;

      svg {
        width: 30px;
      }
    }

    .form {
      width: 490px;
      .inputDiv {
        width: 100%;
        margin: 2% 0;

        textarea {
          font-size: 17px;
        }

        input {
          padding: 1% 0;
          height: 45px;
          font-size: 14px;
        }
      }

      .alertInfo {
        font-size: 12px;
      }

      .submitBtn {
        width: 200px;
        height: 45px;
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  // Post Listing

  .listsDiv {
    &_mainSection {
      .cardDiv {
        column-count: 2;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .stigmaDiv {
    &_title {
      font-size: 90px;
      line-height: 100%;
      margin-bottom: 15px;
    }

    &_subTitle {
      font-size: 40px;
    }
  }

  // Post Listing

  .listsDiv {
    &_header {
      .topLinks {
        :first-child {
          margin-right: 20px;
        }

        .postButton {
          width: 135px;
          height: 35px;
          font-size: 14px;
        }
      }
    }

    &_mainSection {
      .cardDiv {
        column-count: 2;
        .card {
          padding: 16px;
          width: 310px;
          margin: 0 20px 60px 20px;

          &:hover {
            background: white;
          }

          .question {
            font-size: 26px;
          }

          .user {
            &__left {
              .name {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  // Form

  .formDiv {
    .hideForm {
      margin-bottom: 1.5%;

      svg {
        width: 30px;
      }
    }

    .form {
      .inputDiv {
        width: 100%;
        margin: 2% 0;

        textarea {
          font-size: 17px;
        }

        input {
          padding: 1% 0;
          height: 45px;
          font-size: 14px;
        }
      }

      .alertInfo {
        font-size: 12px;
      }

      .submitBtn {
        width: 200px;
        height: 45px;
        font-size: 18px;
      }
    }
  }

  // Card Details

  .cardDetails {
    &__innerDiv {
      width: 80vw;
      height: auto;
      overflow-y: auto;

      .question {
        font-size: 30px;
      }

      .user {
        &__left {
          .name {
            font-size: 16px;
            padding: 0 8px;
          }
        }

        &__right {
          .socialLinks {
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .answer {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 601px) {
  .stigmaDiv {
    &_title {
      font-size: 45px;
      line-height: 100%;
      margin-bottom: 15px;
    }

    &_subTitle {
      font-size: 20px;
    }

    &_scrollDown {
      padding-top: 10px;

      .scrollDownText {
        font-size: 18px;
      }

      svg {
        width: 25px;
        height: 25px;
      }

      .clickToContinue {
        font-size: 18px;
      }
    }
  }

  // Post Listing

  .listsDiv {
    &_header {
      height: 65px;
      padding: 0 10px;

      .mindsumLogo {
        width: 110px;
        height: 45px;
        // margin-left: 25px;
      }

      .postButton {
        width: 140px;
        height: 30px;
        font-size: 9px;
        background: #62bfae;
      }
    }

    &_mainSection {
      padding: 60px 6% 0 6%;
      .welcomeHeading {
        font-size: 35px;
        padding: 25px 0 15px 0;
      }

      .cardDiv {
        column-count: 1;
        padding-top: 10px;

        .card {
          width: 85vw;
          padding: 16px;
          margin: 0 10px 50px 10px;

          &:hover {
            background: white !important;

            .answer {
              color: black;
            }
          }

          .question {
            font-size: 26px;
          }

          .user {
            &__left {
              .name {
                font-size: 12px;
              }
            }
          }
        }
      }

      .getSupportDiv {
        .redBg {
          svg {
            width: 60px;
            height: 60px;
          }
        }

        .innerText {
          font-size: 11px;
        }
      }
    }
  }

  // Form

  .formDiv {
    .hideForm {
      margin-bottom: 1.5%;

      svg {
        width: 25px;
      }
    }

    .form {
      width: 90vw;

      .inputDiv {
        width: 100%;
        margin: 2% 0;

        textarea {
          font-size: 16px;
          line-height: 130%;
        }

        input {
          padding: 1% 0;
          height: 45px;
          font-size: 16px;
        }
      }

      .alertInfo {
        font-size: 12px;
      }

      .submitBtn {
        width: 160px;
        height: 35px;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }

  // Card Details

  .cardDetails {
    &__innerDiv {
      width: 85vw;
      max-height: 85vh;
      overflow-y: auto;

      .closeIcon {
        svg {
          width: 18px;
          height: 18px;
        }
      }

      .topBorder {
        margin-top: 50px;
        height: 3px;

        &__left {
          height: 3px;
        }

        &__right {
          height: 3px;
        }
      }
      .question {
        font-size: 22px;
      }

      .user {
        padding-top: 30px;
        align-items: flex-start;
        &__left {
          flex-direction: column;
          align-items: flex-start;

          .name {
            font-size: 14px;
            border: none;
            padding: 3px 0;
          }
        }

        &__right {
          flex-wrap: wrap;
          justify-content: flex-end;
          width: 105px;
          .socialLinks {
            margin: 0 4px;
            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .answer {
        font-size: 12px;
      }
    }
  }
}
