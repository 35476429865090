.profileSet {
  height: 65vh;
  width: 100%;
  overflow-y: auto !important;
  margin-top: 30px;
  padding: 0 5% 5% 5%;

  ::-webkit-scrollbar {
    display: none !important;
  }

  .borderBottom {
    border-bottom: 1px solid #62bfae;

    .notification {
      font-size: 24px;
      font-family: "L-Regular";
      color: #62bfae;
      text-transform: uppercase;
    }
  }

  .listDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    li {
      font-size: 19px;
      font-family: "L-Regular";
    }

    .smallText {
      padding-left: 20px;
    }

    .cbFormGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .checkboxDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0px 10px;

        .checkBoxLabel {
          font-size: 19px;
          font-family: "L-Regular";
        }
      }
    }
  }

  .profileSetBtnDiv {
    display: flex;
    justify-content: center;
    width: 100%;

    .profileSetBtn {
      font-size: 24px;
      font-family: "L-Regular";
      color: #ff5f5f;
      text-transform: uppercase;
      background: transparent;
      border: none;
      outline: none;
      margin-top: 25px;
      letter-spacing: 1px;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .profileSet {
    height: 55vh;
    .borderBottom {
      .notification {
        font-size: 18px;
      }
    }

    .listDiv {
      li {
        font-size: 15px;
        font-family: "L-Regular";
      }

      .cbFormGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .checkboxDiv {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0px 10px;

          .checkBoxLabel {
            font-size: 14px;
          }
        }
      }
    }

    .profileSetBtnDiv {
      .profileSetBtn {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .profileSet {
    margin-top: 10px;
    height: auto;
    padding-bottom: 80px;

    .borderBottom {
      .notification {
        font-size: 18px;
      }
    }

    .listDiv {
      padding: 10px 0px;
      li {
        font-size: 15px;
        font-family: "L-Regular";
      }

      .smallText {
        padding-left: 0px;
      }

      .cbFormGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .checkboxDiv {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 0px 10px;
          flex-direction: column;

          .checkBoxLabel {
            font-size: 14px;
          }
        }
      }
    }

    .profileSetBtnDiv {
      .profileSetBtn {
        font-size: 18px;
      }
    }
  }
}
