@font-face {
  font-family: "L-Regular";
  src: url("../fonts/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "L-Light";
  src: url("../fonts/Lato-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "L-Medium";
  src: url("../fonts/Lato-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "L-Bold";
  src: url("../fonts/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "L-SBold";
  src: url("../fonts/Lato-Semibold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "M-Bold";
  src: url("../fonts/Magalie-Bold.ttf");
  font-display: swap;
}
