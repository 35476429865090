.coverBg2 {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fdd9ce;
  z-index: -20;
}

.stepThree {
  width: 100%;
  height: 100%;
  background: #fdd9ce;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .stepThreeInner {
    width: 75%;
    height: 100%;
    margin: 5% auto;
    background: rgba(249, 246, 244, 0.8);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 2% 3%;

    .step1Nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3%;
      width: 100%;

      .firstPart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 33%;

        .spanText {
          font-size: 14px;
          font-family: "L-Medium";
          text-transform: uppercase;
          padding-right: 5px;
          margin-bottom: 10px;
        }

        .active {
          font-size: 16px;
          color: #79bcae;
        }
      }

      .secondPart {
        width: 33%;
        display: flex;
        justify-content: center;

        .navLogo {
          .ImgFluid {
            width: 300px;
          }
        }
      }
      .thirdPart {
        width: 33%;
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
      }
    }

    .stepThreeFooter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2%;
    }
  }
}

.preview {
  width: 100%;
  height: 100%;
  background: #fdd9ce;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 !important;
  }

  .previewMain {
    width: 75%;
    height: auto;
    margin: 5% auto;
    background: #fcf6ee;
    box-shadow: 0 8px 32px 0 rgba(172, 173, 182, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 1% 3% 3% 3%;

    .step1Nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3%;
      width: 100%;

      .firstPart {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 33%;

        .spanText {
          font-size: 14px;
          font-family: "L-Medium";
          text-transform: uppercase;
          padding-right: 5px;
          margin-bottom: 10px;
        }

        .active {
          font-size: 16px;
          color: #79bcae;
        }
      }

      .secondPart {
        width: 33%;
        display: flex;
        justify-content: center;

        .ImgFluid {
          width: 200px;
        }
      }
      .thirdPart {
        width: 33%;
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;
      }
    }
    .previewContent {
      padding: 2% 18%;

      .topHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #62bfae;

        .aboutYouTitle {
          font-size: 25px;
          font-family: "L-Regular";
          color: #62bfae;
          text-transform: uppercase;
          margin-bottom: 5px;
        }
        .editLink {
          width: 150px;
          height: 45px;
          background: #62bfae;
          border: none;
          outline: none;
          color: white;
          border-radius: 0.35vh;
          font-size: 20px;
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          letter-spacing: 0.5px;
          transition: all 0.2s linear;

          &:hover {
            text-decoration: none;
            background: #62bfaeb0;
            letter-spacing: 1.6px;
          }
        }
      }

      .previewAbout {
        padding-bottom: 10%;
        .aboutYouContent {
          .profileInfo {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5% 0%;
            flex-direction: column;
            text-align: center;

            .previewNameDiv {
              padding: 10px 0;
              text-align: center;

              .previewName {
                margin: 0;
                font-size: 24px;
                font-family: "L-Regular";
                text-transform: capitalize;
              }
              .previewEmail {
                font-size: 16px;
                padding: 2px 0;
                color: #434344;
              }
            }

            .previewSocialDiv {
              display: flex;

              .socialIconDiv {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: black;
                position: relative;
                margin: 0 10px;

                .socialIcon {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }

          .profileBioDiv {
            .profileBioTitle {
              font-size: 14px;
              font-family: "L-Regular";
            }

            .profileBio {
              font-size: 19px;
              font-family: "L-Light";
              border-bottom: 1px solid black;
              padding-bottom: 10px;
              white-space: pre-line;
            }
          }

          .contactInfo {
            padding-top: 5%;
            .contactInfoTitle {
              font-size: 22px;
              text-transform: uppercase;
              color: #62bfae;
            }
            .contactFlex {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 0px;

              .contactFirstDiv {
                width: 45%;
                border-bottom: 1px solid black;

                .contactTitle {
                  font-size: 14px;
                  font-family: "L-Regular";
                  letter-spacing: 0.4px;
                }

                .mobile {
                  font-size: 19px;
                  font-family: "L-Light";
                  padding-bottom: 5px;
                  letter-spacing: 0.7px;
                }
              }
            }

            .addressDivFlex {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .addressDiv {
                width: 45%;
                padding: 10px 0px;
                .addressTitle {
                  font-size: 14px;
                  font-family: "L-Regular";
                  letter-spacing: 0.4px;
                }

                .address {
                  font-size: 19px;
                  font-family: "L-Light";
                  padding-bottom: 5px;
                  border-bottom: 1px solid black;
                }
              }
            }
          }
        }
      }

      .previewNeeds {
        padding-bottom: 10%;

        .yourNeedsContent {
          .listStyleFlex {
            display: flex;

            .listStyle {
              width: 6px;
              height: 6px;
              background: black;
              padding: 2px;
              border-radius: 50%;
              margin-right: 14px;
              margin-top: 12px;
            }

            .listStyleText {
              line-height: 120%;
              margin-bottom: 5px;
            }
          }

          .sessionDiv {
            padding: 1% 0%;

            .rowFlex {
              display: flex;
              width: 80%;
              justify-content: space-between;
              align-items: flex-start;
            }

            .selectedTimesDiv {
              display: flex;
              margin-bottom: 10px;
              align-items: center;
              justify-content: space-between;

              .selectedTimes {
                margin: 0;
                color: rgb(66, 65, 65);
                display: flex;
                justify-content: space-between;
                width: 100%;
                text-transform: uppercase;

                :first-child {
                  margin-right: 10px;
                  color: white;
                  width: 90px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 30px;
                  font-size: 13px;
                  border-radius: 3px;
                  background: #62bfae;
                }

                :nth-child(2) {
                  color: white;
                  width: 90px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 30px;
                  font-size: 13px;
                  border-radius: 3px;
                  background: #62bfae;
                }
              }
            }

            .therapySession {
              font-size: 19px;
              font-family: "L-Regular";
              padding: 1% 0%;
            }

            .selectedSessions {
              padding-left: 3%;

              .selectedSessionsBg {
                background: #62bfae;
                color: white;
                text-transform: uppercase;
                font-size: 14px;
                font-family: "L-SBold";
              }
            }
          }

          .cityPreferenceDiv {
            display: flex;
            align-items: center;
            padding-bottom: 2%;

            .cityPreference {
              font-size: 19px;
              font-family: "L-Regular";
              padding: 1% 0%;
            }

            .cityPreferenceAns {
              margin-bottom: 0px;
              padding-left: 50px;
              font-size: 19px;
              font-family: "L-Bold";
            }
          }

          .therapySelectDiv {
            padding-bottom: 2%;
            .therapySelect {
              font-size: 19px;
              font-family: "L-Regular";
              padding: 1% 0%;
            }

            .selectedTherapies {
              padding-left: 3%;
              white-space: pre-line;

              .therapySelectBg {
                background: #62bfae;
                color: white;
                text-transform: uppercase;
                font-size: 14px;
                font-family: "L-SBold";
              }
            }
          }

          .mentalConditionDiv {
            padding-bottom: 3%;
            .mentalCondition {
              font-size: 19px;
              font-family: "L-Regular";
              padding: 1% 0%;
            }

            .mentalConditionSelect {
              padding-left: 3%;

              .mentalConditionBg {
                background: #62bfae;
                color: white;
                text-transform: uppercase;
                font-size: 14px;
                font-family: "L-SBold";
              }
            }
          }

          .financialAssDiv {
            display: flex;
            align-items: center;

            .financialAss {
              font-size: 19px;
              font-family: "L-Regular";
              padding: 1% 0%;
            }

            .financialAssAns {
              margin-bottom: 0px;
              padding-left: 50px;
              font-size: 19px;
              font-family: "L-Bold";
            }
          }
        }
      }

      .previewBtns {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5%;

        .previewPrevBtn {
          background: #62bfae;
          color: white;
          width: 260px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          border-radius: 0.35vh;
          letter-spacing: 0.5px;
          transition: all 0.2s linear;

          &:hover {
            background: #62bfaedc;
            text-decoration: none;
            letter-spacing: 1.6px;
          }
        }

        .previewCompleteBtn {
          background: #62bfae;
          color: white;
          width: 200px;
          height: 45px;
          border: none;
          outline: none;
          text-transform: uppercase;
          border-radius: 0.35vh;
          letter-spacing: 0.5px;
          transition: all 0.2s linear;

          &:hover {
            background: #62bfaedc;
            text-decoration: none;
            letter-spacing: 1.6px;
          }
        }
      }

      .previewFooter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 5%;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .stepThree {
    .stepThreeInner {
      .step1Nav {
        .firstPart {
          .spanText {
            font-size: 12px;
          }

          .active {
            font-size: 14px;
          }
        }

        .secondPart {
          .navLogo {
            .ImgFluid {
              width: 220px;
            }
          }
        }
      }
    }
  }

  .preview {
    .previewMain {
      .previewContent {
        padding: 2% 18%;

        .topHeader {
          .aboutYouTitle {
            font-size: 18px;

            margin-bottom: 0px;
          }
          .editLink {
            width: 100px;
            height: 30px;
            font-size: 16px;
          }
        }

        .previewAbout {
          .aboutYouContent {
            .contactInfo {
              .contactInfoTitle {
                font-size: 17px;
              }
            }
          }
        }

        .previewNeeds {
          .yourNeedsContent {
            .sessionDiv {
              .therapySession {
                font-size: 17px;
              }

              .selectedSessions {
                padding-left: 4%;

                .selectedSessionsBg {
                  font-size: 12px;
                }
              }
            }

            .cityPreferenceDiv {
              .cityPreference {
                font-size: 17px;
              }

              .cityPreferenceAns {
                font-size: 16px;
              }
            }

            .therapySelectDiv {
              .therapySelect {
                font-size: 17px;
              }

              .selectedTherapies {
                padding-left: 4%;

                .therapySelectBg {
                  font-size: 12px;
                }
              }
            }

            .mentalConditionDiv {
              .mentalCondition {
                font-size: 17px;
              }

              .mentalConditionSelect {
                padding-left: 4%;

                .mentalConditionBg {
                  font-size: 12px;
                }
              }
            }

            .financialAssDiv {
              display: flex;
              align-items: center;

              .financialAss {
                font-size: 17px;
              }

              .financialAssAns {
                font-size: 16px;
              }
            }
          }
        }

        .previewBtns {
          .previewCompleteBtn {
            width: 200px;
            height: 45px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .stepThree {
    .stepThreeInner {
      .step1Nav {
        .firstPart {
          .spanText {
            font-size: 8px;
          }

          .active {
            font-size: 10px;
          }
        }

        .secondPart {
          .navLogo {
            .ImgFluid {
              width: 200px;
            }
          }
        }
      }
    }
  }

  .preview {
    .previewMain {
      width: 90%;

      .step1Nav {
        .firstPart {
          .spanText {
            font-size: 8px;
          }

          .active {
            font-size: 10px;
          }
        }

        .secondPart {
          .ImgFluid {
            width: 180px !important;
          }
        }
      }

      .previewContent {
        padding: 5% 5%;

        .topHeader {
          .aboutYouTitle {
            font-size: 20px;
            margin-bottom: 0px;
          }
          .editLink {
            width: 100px;
            height: 30px;
            font-size: 16px;
          }
        }

        .previewAbout {
          padding-bottom: 10%;
          .aboutYouContent {
            .profileBioDiv {
              .profileBio {
                font-size: 16px;
                padding-bottom: 5px;
              }
            }

            .contactInfo {
              .contactInfoTitle {
                font-size: 18px;
              }
              .contactFlex {
                .contactFirstDiv {
                  .contactTitle {
                    font-size: 14px;
                  }

                  .mobile {
                    font-size: 16px;

                    padding-bottom: 5px;
                  }
                }
              }

              .addressDivFlex {
                .addressDiv {
                  .address {
                    font-size: 16px;

                    padding-bottom: 5px;
                  }
                }
              }
            }
          }
        }

        .previewNeeds {
          padding-bottom: 10%;

          .yourNeedsContent {
            .sessionDiv {
              .therapySession {
                font-size: 17px;
              }

              .selectedSessions {
                padding-left: 4%;

                .selectedSessionsBg {
                  font-size: 12px;
                }
              }
            }

            .cityPreferenceDiv {
              .cityPreference {
                font-size: 17px;
              }

              .cityPreferenceAns {
                font-size: 16px;
              }
            }

            .therapySelectDiv {
              .therapySelect {
                font-size: 17px;
              }

              .selectedTherapies {
                padding-left: 4%;

                .therapySelectBg {
                  font-size: 12px;
                }
              }
            }

            .mentalConditionDiv {
              .mentalCondition {
                font-size: 17px;
              }

              .mentalConditionSelect {
                padding-left: 4%;

                .mentalConditionBg {
                  font-size: 12px;
                }
              }
            }

            .financialAssDiv {
              .financialAss {
                font-size: 17px;
              }

              .financialAssAns {
                font-size: 16px;
              }
            }
          }
        }

        .previewBtns {
          .previewPrevBtn {
            width: 150px;
            height: 40px;
          }

          .previewCompleteBtn {
            width: 150px;
            height: 40px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .preview {
    .previewMain {
      width: 90%;

      .step1Nav {
        flex-direction: column;
        justify-content: center;
        padding: 10% 5%;

        .firstPart {
          width: 100%;
          justify-content: center;
          order: 3;
          padding: 3% 0%;

          .spanText {
            margin-bottom: 20px;
            font-size: 10px;
          }

          .active {
            font-size: 12px;
          }
        }

        .secondPart {
          width: 100%;
          order: 1;

          .ImgFluid {
            width: 180px !important;
          }
        }

        .thirdPart {
          order: 2;
          padding: 5% 0%;
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .previewContent {
        padding: 5% 5%;

        .topHeader {
          .aboutYouTitle {
            font-size: 15px;
            margin-bottom: 0px;
          }
          .editLink {
            width: 70px;
            height: 25px;

            font-size: 13px;
          }
        }

        .previewAbout {
          padding-bottom: 10%;

          .previewSocialDiv {
            .socialIconDiv {
              width: 30px !important;
              height: 30px !important;
            }
          }

          .aboutYouContent {
            .profileBioDiv {
              padding-bottom: 20px;

              .profileBioTitle {
                font-size: 13px;
              }

              .profileBio {
                font-size: 14px;
                padding-bottom: 5px;
              }
            }
            .contactInfo {
              .contactInfoTitle {
                font-size: 14px;
              }

              .contactFlex {
                flex-direction: column;

                :nth-child(2) {
                  margin-bottom: 0 !important;
                }

                .contactFirstDiv {
                  width: 100%;
                  margin-bottom: 8%;

                  .contactTitle {
                    font-size: 13px;
                  }

                  .mobile {
                    font-size: 14px;
                    padding-bottom: 5px;
                  }
                }
              }

              .addressDivFlex {
                flex-direction: column;

                .addressDiv {
                  width: 100%;
                  padding: 10px 0px;
                  .addressTitle {
                    font-size: 13px;

                    letter-spacing: 0.4px;
                  }

                  .address {
                    font-size: 14px;

                    padding-bottom: 5px;
                  }
                }
              }
            }
          }
        }

        .previewNeeds {
          padding-bottom: 10%;

          .yourNeedsContent {
            .sessionDiv {
              .rowFlex {
                flex-direction: column;
              }

              .therapySession {
                font-size: 14px;
              }

              .selectedSessions {
                padding-left: 8%;

                .selectedSessionsBg {
                  font-size: 11px;
                }
              }
            }

            .cityPreferenceDiv {
              .cityPreference {
                font-size: 14px;
              }

              .cityPreferenceAns {
                font-size: 14px;
              }
            }

            .therapySelectDiv {
              .therapySelect {
                font-size: 14px;
              }

              .selectedTherapies {
                padding-left: 8%;

                .therapySelectBg {
                  font-size: 11px;
                }
              }
            }

            .mentalConditionDiv {
              .mentalCondition {
                font-size: 14px;
              }

              .mentalConditionSelect {
                padding-left: 8%;

                .mentalConditionBg {
                  font-size: 11px;
                }
              }
            }

            .financialAssDiv {
              .financialAss {
                font-size: 14px;
              }

              .financialAssAns {
                font-size: 14px;
              }
            }
          }
        }

        .previewBtns {
          .previewPrevBtn {
            width: 100px;
            height: 35px;
            font-size: 12px;
          }

          .previewCompleteBtn {
            width: 100px;
            height: 35px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
