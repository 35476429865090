.noMessageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  padding: 20px;

  p {
    margin: 0;
  }

  .noMessageText {
    font-size: 18px;
    font-family: "L-Regular";
    text-align: center;
    color: #9097a2;
    text-transform: uppercase;
    margin: 10px 0px;
  }

  .noMessageSubText {
    text-align: center;
    font-size: 16px;
    font-family: "L-Regular";
    color: #9097a2;
    text-transform: capitalize;
    line-height: 120%;
  }

  .viewTherapist {
    font-size: 16px;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: "L-Medium";
    color: #62bfae;
    border: 1px solid #62bfae;
    padding: 5px 10px;
    transition: all 0.2s linear;
    border-radius: 0.35vh;

    &:hover {
      text-decoration: none;
      background: #62bfae;
      color: white;
    }
  }
}

.chatListItem {
  border: none !important;
  border-bottom: 1px solid #f5d4c7 !important;
  padding: 2%;

  &:hover {
    background: #62bfae;
    color: white;

    .chatListItemDiv {
      .therapistImgDiv {
        .therapistImg {
          border: 1px solid #fff;
          background: #62bfae;
        }
      }

      .chatListProfileRow {
        .chatListProfileRowOne {
          .messageDate {
            color: white;
          }
        }

        .chatListProfileRowTwo {
          color: white;
        }
      }
    }
  }

  .chatListItemDiv {
    width: 100%;
    display: flex;
    align-items: center;

    .therapistImgDiv {
      width: 60px;
      height: 60px;

      .therapistImg {
        width: 60px;
        height: 60px;
        background: white;
        object-fit: cover;
        object-position: center;
        border: 1px solid #62bfae;
        border-radius: 50%;
        padding: 2px;
      }
    }

    .chatListProfileRow {
      display: flex;
      flex-direction: column;
      width: 80%;
      padding-left: 15px;

      .chatListProfileRowOne {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profileName {
          font-size: 20px;
          font-family: "L-Regular";
          text-transform: capitalize;
        }

        .messageDate {
          font-size: 18px;
          font-family: "L-Regular";
          color: #62bfae;
          text-transform: uppercase;
        }
      }

      .chatListProfileRowTwo {
        font-size: 16px;
        font-family: "L-Regular";
        color: #9097a2;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .chatListItem {
    .chatListItemDiv {
      .chatListProfileImg {
        max-width: 60px;
        height: 60px;
      }

      .chatListProfileRow {
        .chatListProfileRowOne {
          .profileName {
            font-size: 16px;
          }

          .messageDate {
            font-size: 14px;
          }
        }

        .chatListProfileRowTwo {
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .chatListItem {
    .chatListItemDiv {
      .chatListProfileRow {
        .chatListProfileRowOne {
          .profileName {
            font-size: 16px;
          }

          .messageDate {
            font-size: 14px;
          }
        }

        .chatListProfileRowTwo {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .chatListItem {
    .chatListItemDiv {
      .chatListProfileRow {
        .chatListProfileRowOne {
          .profileName {
            font-size: 16px;
          }

          .messageDate {
            font-size: 14px;
          }
        }

        .chatListProfileRowTwo {
          font-size: 12px;
        }
      }
    }
  }
}
