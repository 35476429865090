@import "src/assets/scss/variables";

.Alert {
  display: inline-block;
  float: left;
  background-color: $secondary;
  border-color: $secondary;
  padding: 2px 8px !important;
  border-radius: 10px;
  font-size: 16px;
  font-family: "L-Regular";
}

.Alert.Own {
  float: right;
  background-color: $primary;
  border-color: $primary;
  color: #ffffff;
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .Alert {
    display: inline-block;
    float: left;
    background-color: $secondary;
    border-color: $secondary;
    padding: 1px 8px !important;
    border-radius: 10px;
    font-size: 13px;
    font-family: "L-Regular";
  }

  .Alert.Own {
    float: right;
    background-color: $primary;
    border-color: $primary;
    color: #ffffff;
  }
}
