.Tentative {
  background: #9ca7b7;
}
.Tentative:hover {
  background: #9ca7b7ca;
}

.Cancelled {
  background: #ffa88a;
}

.Cancelled:hover {
  background: #ffa78ab9;
}

.Confirmed {
  background: #62bfae;
}

.Confirmed:hover {
  background: #62bfaec4;
}

.UpcomingSessionsListItem {
  display: flex;
  height: 340px;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 3px solid white;
  margin: 0 3.2%;

  .firstCol {
    width: 25%;
    background: white;
    height: 100%;
    border-bottom-left-radius: 15px;
    padding: 5% 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: #9097a2;
    cursor: pointer;

    p {
      margin-bottom: 0;
    }

    .date {
      font-family: "L-Regular";
      text-align: center;
      padding-bottom: 20px;

      &__num {
        font-size: 31px;
      }

      &__month {
        font-size: 18px;
      }
    }

    .time {
      font-size: 18px;
      font-size: "L-Medium";
      text-align: center;
    }

    .duration {
      font-size: 10px;
      font-size: "L-Medium";
      text-transform: capitalize;
    }
  }

  .secondCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 5% 0;
    width: 75%;
    height: 100%;

    color: white !important;
    border-bottom-right-radius: 15px;

    .userBtn {
      background: transparent;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .profileName {
        color: white;
        font-size: 20px;
        padding: 5% 0;
        font-family: "L-Medium";
        margin: 0;
        line-height: 120%;
        text-transform: capitalize;
      }

      .profileImg {
        border: 1px solid #fff;
        padding: 2px;
        border-radius: 50%;
      }

      .viewProfile {
        margin: 0;
        font-size: 13px;
        padding-bottom: 4%;
        color: white;
        font-family: "L-Regular";
        transition: all 0.2s linear;
        cursor: pointer;

        &:hover {
          letter-spacing: 0.5px;
        }
      }
    }

    .awaitingApp {
      width: 80%;
      padding-top: 8%;
      border-top: 1px solid rgba(255, 255, 255, 0.39);
      display: flex;
      justify-content: center;
      align-items: center;

      .cancelledBg {
        background: #f8beab94 !important;
        border: 2px solid #f5dbd2e1 !important;
      }

      .awaitingAppText {
        font-size: 19px;

        font-family: "L-Medium";
        color: white;
        text-align: center;
        background: #b4bdc9;
        padding: 3% 5%;
        border-radius: 100px;
        border: 2px solid #c9d0d9;
      }
    }

    &__footer {
      width: 80%;
      padding: 4% 0;
      border-top: 1px solid rgba(255, 255, 255, 0.39);

      .footerDiv {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
          margin-bottom: 0;
        }

        .videoIconDiv {
          background: white;
          width: 50px;
          height: 50px;
          padding: 10px;
          border-radius: 50%;
          position: relative;
          margin-right: 15px;

          .icon {
            color: #62bfae;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .callSessionTitle {
          font-size: 20px;
          font-family: "L-Regular";
        }

        .viewDetailsDiv {
          font-size: 13px;
          font-family: "L-Light";

          .viewDetails {
            margin-right: 10px;
            cursor: pointer;
          }

          .cancel {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .UpcomingSessionsListItem {
    height: 250px;
    border-top: 2px solid white;

    .firstCol {
      .date {
        font-family: "L-Regular";
        text-align: center;
        padding-bottom: 20px;

        &__num {
          font-size: 26px;
        }

        &__month {
          font-size: 14px;
        }
      }

      .time {
        font-size: 12px;
        font-size: "L-Medium";
      }

      .duration {
        font-size: 10px;
        font-size: "L-Medium";
        text-transform: capitalize;
      }
    }

    .secondCol {
      .userBtn {
        .profileName {
          font-size: 18px;
        }
      }

      .awaitingApp {
        width: 80%;

        .awaitingAppText {
          font-size: 14px;
        }
      }

      &__footer {
        width: 80%;

        .footerDiv {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          p {
            margin-bottom: 0;
          }

          .videoIconDiv {
            background: white;
            width: 35px;
            height: 35px;
            padding: 10px;
            border-radius: 50%;
            position: relative;
            margin-right: 15px;

            .icon {
              width: 20px;
              height: 20px;
              color: #62bfae;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .callSessionTitle {
            font-size: 15px;
          }

          .viewDetailsDiv {
            font-size: 12px;
            font-family: "L-Light";

            .viewDetails {
              margin-right: 10px;
              cursor: pointer;
            }

            .cancel {
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .UpcomingSessionsListItem {
    margin: 0 10px;

    .firstCol {
      .date {
        &__num {
          font-size: 28px;
        }

        &__month {
          font-size: 18px;
        }
      }

      .time {
        font-size: 17px;
      }

      .duration {
        font-size: 10px;
      }
    }

    .secondCol {
      .userBtn {
        .profileName {
          font-size: 20px;

          margin: 0;
        }
      }

      .awaitingApp {
        width: 90%;
        .awaitingAppText {
          font-size: 17px;
        }
      }

      &__footer {
        width: 90%;

        .footerDiv {
          .videoIconDiv {
            width: 45px;
            height: 45px;
          }

          .callSessionTitle {
            font-size: 18px;
          }

          .viewDetailsDiv {
            font-size: 13px;

            .viewDetails {
              margin-right: 8px;
            }

            .cancel {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .UpcomingSessionsListItem {
    border-top: 2px solid white;
    margin: 0 0px;

    .firstCol {
      .date {
        &__num {
          font-size: 26px;
        }

        &__month {
          font-size: 14px;
        }
      }

      .time {
        font-size: 14px;
      }
    }

    .secondCol {
      .userBtn {
        .profileName {
          font-size: 20px;
          margin: 0;
        }
      }

      .awaitingApp {
        width: 100%;

        .awaitingAppText {
          font-size: 14px;
        }
      }

      &__footer {
        .footerDiv {
          justify-content: center;

          .callSessionTitle {
            font-size: 13px;
          }

          .viewDetailsDiv {
            font-size: 11px;

            .viewDetails {
              margin-right: 8px;
            }

            .cancel {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
