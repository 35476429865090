.sessionTable {
  border-radius: 10px;
  background: white;
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 5%;

  &__head {
    &__rowOne {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background: #62bfae;
      color: white;
      text-transform: uppercase;
      font-size: 17px;
      font-family: "L-Bold";
      border-bottom: 1px solid white;

      span {
        width: 20%;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__body {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      display: none;
    }

    .noSessionDiv {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      flex-direction: column;

      .noSessionText {
        font-size: 22px;
        font-family: "L-Regular";
        color: #9097a2;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .bookSessionBtn {
        font-size: 18px;
        text-transform: uppercase;
        font-family: "L-Medium";
        color: #62bfae;
        border: 1px solid #62bfae;
        padding: 10px 15px;
        transition: all 0.2s linear;
        border-radius: 0.35vh;

        &:hover {
          text-decoration: none;
          background: #62bfae;
          color: white;
        }
      }
    }

    &__row {
      overflow-y: auto;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
}

.tableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding: 1%;
  cursor: pointer;
  border-bottom: 1px solid #f9ddd2;
  text-align: left;
  background: white;
  color: black;

  &:hover {
    background: #62bfae;
    color: white;
    border: 1px solid #62bfae;
    transition: all 0.3s ease;

    .therapist {
      .newEnquiriesProfile {
        img {
          border: 1px solid white;
          background: #62bfae;
        }
      }

      .therapistProfileDiv {
        .therapistProfile {
          border: 1px solid #fff;
        }
      }

      &__nameDiv {
        .location {
          color: white;
        }
      }
    }

    .sessionDate {
      small {
        color: white;
      }
    }

    .sessionType {
      .mySessionVideo {
        fill: white;
      }

      .videoCallIcon {
        fill: white;
      }
      .sessionTypeSpan {
        color: white;
      }
    }
  }

  .tableRowDiv {
    width: 20%;
    display: flex;
    justify-content: center;
  }

  .therapist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 3%;

    .newEnquiriesProfile {
      width: 60px;
      height: 60px;

      img {
        width: 60px;
        height: 60px;
        padding: 2px;
        background: white;
        border: 1px solid #62bfae;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }

    .therapistProfileDiv {
      width: 60px;
      height: 60px;
      overflow: hidden;
      border-radius: 50%;

      .therapistProfile {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid #62bfae;
        padding: 2px;
        object-fit: cover;
        object-position: center;
      }
    }

    &__nameDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 10px;

      .name {
        font-size: 18px;
        font-family: "L-Regular";
        margin-bottom: 0;
        text-transform: capitalize;
      }
      .location {
        font-size: 16px;
        font-family: "L-Regular";
        color: #9097a2;
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }
  }

  .treatmentSession {
    font-size: 18px;
    text-transform: capitalize;
    font-family: "L-Regular";
  }

  .sessionDate {
    display: flex;
    flex-direction: column;
    align-items: center;

    big {
      font-size: 16px;
      font-family: "L-Regular";
    }
    small {
      font-size: 16px;
      font-family: "L-Regular";
      color: #9097a2;
    }
  }

  .sessionType {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 6%;

    .videoCallIcon {
      fill: black;
    }

    .sessionTypeSpan {
      display: flex;
      align-items: center;
      color: #9097a2;
      font-size: 16px;
      font-family: "L-Regular";
      padding-top: 10px;
      flex-wrap: wrap;

      span {
        padding: 0 3px;
        text-transform: capitalize;
      }
    }
  }

  .sessionFee {
    font-size: 16px;
    font-family: "L-Regular";
  }
}

.activeContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background: #62bfae;
  color: white;
  border-top: 1px solid white;
  transition: max-height 0.3s linear;

  .activeContentHead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &__One {
    width: 20%;
    padding: 1% 3% 2% 3%;
  }

  &__OneLink {
    color: white;
    font-size: 17px;
    font-family: "L-Bold";

    &:hover {
      color: white;
      text-decoration: none;
      border-bottom: 1px solid white;
    }
  }

  .activeContentBody {
    padding: 0% 3% 5% 3%;
    p {
      font-size: 16px;
      font-family: "L-Regular";
    }
  }
}

@media only screen and (max-width: 1600px) {
  .sessionTable {
    &__head {
      &__rowOne {
        height: 40px;

        font-size: 12px;
      }
    }

    &__body {
      .noSessionDiv {
        .noSessionText {
          font-size: 18px;
          margin-bottom: 15px;
        }

        .bookSessionBtn {
          font-size: 14px;
          padding: 5px 10px;
        }
      }
    }
  }

  .newEnquiriesTable {
    .newEnquiriesBtn {
      .declineBtn,
      .acceptBtn {
        width: 90px;
        height: 35px;
        font-size: 14px;
      }
    }
  }

  .tableRow {
    padding: 1%;
    height: 90px;

    .therapist {
      &__nameDiv {
        .name {
          font-size: 12px;
        }
        .location {
          font-size: 10px;
        }
      }
    }

    .treatmentSession {
      font-size: 12px;
    }

    .sessionDate {
      big {
        font-size: 12px;
      }
      small {
        font-size: 10px;
      }
    }
    .sessionType {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 6%;

      .videoCallIcon {
        fill: black;
      }

      .mySessionVideo {
        width: 25px;
        height: 25px;
      }

      .sessionTypeSpan {
        font-size: 12px;
      }
    }

    .sessionFee {
      font-size: 14px;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .sessionTable {
    height: 60vh;
  }

  .newEnquiriesTable {
    .newEnquiriesBtn {
      .declineBtn,
      .acceptBtn {
        width: 80px;
        height: 30px;
        font-size: 14px;
      }
    }
  }

  .tableRow {
    .therapist {
      .newEnquiriesProfile {
        display: none;
      }

      .therapistProfileDiv {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .sessionTable {
    height: 100% !important;

    &__head {
      height: 40px;

      &__rowOne {
        height: 40px;
        font-size: 10px;
        padding: 0;

        span {
          width: 30%;
        }

        .sessionDuration {
          display: none;
        }

        .sessionStatus {
          display: none;
        }

        .sessionNull {
          display: none;
        }

        .sessionFee {
          display: none;
        }
      }
    }

    &__body {
      height: 100% !important;
      overflow-y: auto !important;
    }
  }

  .newEnquiriesTable {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #f9ddd2;

    .newEnquiriesTableRow {
      border-bottom: none !important;
      width: 100%;

      .newEnquiriesDuration {
        display: none !important;

        .sessionDuration {
          display: none;
        }
      }
    }

    &:hover {
      background: #62bfae;
      color: white;
    }

    .newEnquiriesBtn {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .declineBtn,
      .acceptBtn {
        width: 80px;
        height: 30px;

        font-size: 12px;
      }

      .acceptBtn {
        background: #62bfae;
        margin-left: 15px;
      }
    }
  }

  .tableRow {
    padding: 1%;
    height: 90px;

    .tableRowDiv {
      width: 30%;
      display: flex;
      justify-content: flex-start;
    }

    .therapist {
      padding-left: 3%;

      .newEnquiriesProfile {
        display: none;
      }

      .therapistProfileDiv {
        display: none;
      }

      &__nameDiv {
        .name {
          font-size: 12px;
        }
        .location {
          font-size: 10px;
        }
      }
    }

    .treatmentSession {
      display: none;
      text-align: center;
      font-size: 10px;
    }

    .sessionDate {
      big {
        text-align: center;
        font-size: 11px;
      }
      small {
        text-align: center;
        font-size: 9px;
      }
    }
    .sessionType {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 8%;

      .videoCallIcon {
        fill: black;
        height: 10px;
      }

      .sessionTypeSpan {
        font-size: 11px;
        padding-left: 0;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 7px;

        span {
          padding: 0;
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }

    .sessionFee {
      display: none;
    }
  }
}
