.bookSession {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  &__innerDiv {
    height: 75vh;
    overflow: hidden;
    margin-bottom: 5%;

    .bookSessionContent {
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }

      .headDiv {
        background: #f9ddd2;
        padding: 2% 4%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        position: relative;
        z-index: 10;

        .backNavDiv {
          display: flex;
          align-items: center;

          .backNavText {
            font-size: 19px;
            font-family: "L-Light";
            text-transform: uppercase;
            margin: 0;
            padding-left: 20px;
            cursor: pointer;
          }
        }

        .BasicInfo {
          .BasicInfoDiv {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .profileImageDiv {
              width: 80px;
              height: 80px;
              border: 1px solid #62bfae;
              border-radius: 50%;
              padding: 2px;

              img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
              }
            }

            .nameDiv {
              margin-bottom: 0 !important;
              font-size: 20px;
              font-family: "L-Medium";
              text-transform: capitalize;
              text-align: center;
            }

            .jobTitle {
              margin-bottom: 0 !important;
              font-size: 19px;
              font-family: "L-Light";
              text-transform: capitalize;
              text-align: center;
            }
          }
        }
      }

      .contentDiv {
        padding: 3% 1%;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        .scheduleSession {
          font-size: 20px;
          font-family: "L-SBold";
          color: #62bfae;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 5px;
        }

        .subInst {
          font-size: 18px;
          font-family: "L-Regular";
          color: #9097a2;
          text-align: center;
          margin: 0;
          padding: 0 10%;
        }

        .datePickerDiv {
          padding: 3% 1%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          flex-wrap: wrap;

          .DatePicker {
            height: 290px;
            margin: 0 1%;
            box-shadow: 0px 3px 8px rgba(224, 223, 223, 0.883);
            overflow-y: hidden;
          }

          .timeSlotDiv {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 10px 3px 10px 1px;
            overflow-y: auto;

            .selectTimeMsg {
              padding: 15px;
              text-align: center;
              color: #9097a2;
              font-size: 16px;
              font-family: "L-Regular";
            }

            .timeSlot {
              margin-left: 8px;
              margin-bottom: 8px;
              cursor: pointer;
              color: #b4c0d3;
              font-size: 13px;
              font-family: "L-Regular";
              width: 70px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: transparent;
              border: 1px solid #d9e1ed;
              border-radius: 0.1vh;

              :hover {
                background: #62bfae;
                color: white;
                border-radius: 0.35vh;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
              }
            }
          }

          .sessionTypeDiv {
            width: 240px;
            height: 290px;
            margin: 0 1%;
            background: white;
            box-shadow: 0px 3px 8px rgba(224, 223, 223, 0.883);

            .sessionTypeTitle {
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 48px;
              text-transform: uppercase;
              text-align: center;
              font-family: "L-Regular";
              color: white;
              background: #62bfae;
              margin: 0;
            }

            .sessionTypeOptions {
              padding: 5% 3%;
              display: grid;
              grid-template-columns: auto auto;
              align-items: center;
              justify-content: center;
              gap: 15px;

              .typeOptionDiv {
                width: 105px;
                height: 105px;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                text-align: center;
                flex-direction: column;
                position: relative;

                .typeOption {
                  width: 50px;
                  height: 50px;
                  background: #b4c0d3;
                  border-radius: 50%;
                  margin-bottom: 8px !important;

                  &:hover {
                    background: #62bfae;

                    .sessionType {
                      color: #62bfae;
                    }

                    .sessionFees {
                      color: #62bfae;
                    }
                  }

                  .optionInput {
                    background-color: #b4c0d3;
                    display: none;
                  }

                  .sessionIconDiv {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    position: relative;
                    margin-bottom: 10px;

                    .sessionIcon {
                      position: absolute;
                      left: 50%;
                      top: 50%;
                      width: 30px;
                      height: 30px;
                      transform: translate(-50%, -50%);
                    }
                  }
                }

                .sessionType {
                  font-size: 13px;
                  font-family: "L-Regular";
                  color: #b4c0d3;
                  margin-left: -7px;
                  text-transform: uppercase;
                }

                .sessionFees {
                  font-size: 13px !important;

                  color: #b4c0d3;
                  margin-top: 11px;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }

        .termsConditions {
          display: flex;
          justify-content: center;
          text-align: center;

          p {
            color: #9097a2;
            font-size: 19px;
            font-family: "L-Regular";

            span {
              font-family: "L-SBold";
            }
          }
        }

        .btnDiv {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 15px;
          margin-bottom: 40px;

          .submitBtn {
            width: 200px;
            height: 45px;
            color: white;
            background: #62bfae;
            outline: none;
            border: none;
            text-transform: uppercase;
            border-radius: 0.35vh;
            font-size: 20px;
            font-family: "L-Regular";
            transition: all 0.2s linear;

            &:hover {
              letter-spacing: 1.2px;
              background: #62bfaee0;
            }
          }
        }
        .loader {
          margin-top: 20px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &__spin {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            border: 3px solid;
            border-color: #62bfae #62bfae transparent transparent;
            animation: rotation 1s linear infinite;

            &:after,
            &:before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              border: 3px solid;
              border-color: transparent transparent #f3ab8e #f3ab8e;
              width: 42px - 8px;
              height: 42px - 8px;
              border-radius: 50%;
              animation: rotationBack 0.5s linear infinite;
              transform-origin: center center;
            }
            &:before {
              width: 42px - 16px;
              height: 42px - 16px;
              border-color: #fff #fff transparent transparent;
              animation: rotation 1.5s linear infinite;
            }
            @keyframes rotationBack {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(-360deg);
              }
            }

            @keyframes rotation {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }

  .bookSessionFooter {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    bottom: 4%;
  }
}

@media only screen and (max-width: 1600px) {
  .bookSession {
    &__innerDiv {
      .bookSessionContent {
        .headDiv {
          .backNavDiv {
            display: flex;
            align-items: center;

            .backNavText {
              font-size: 17px;
            }
          }
        }

        .contentDiv {
          .scheduleSession {
            font-size: 18px;
          }

          .datePickerDiv {
            .sessionTypeDiv {
              .sessionTypeTitle {
                font-size: 16px;
              }

              .sessionTypeOptions {
                gap: 10px;

                .typeOptionDiv {
                  .typeOption {
                    width: 45px;
                    height: 45px;

                    .sessionIconDiv {
                      margin-bottom: 8px;

                      .sessionIcon {
                        width: 22px;
                        height: 22px;
                        position: absolute;
                      }
                    }
                  }

                  .sessionType {
                    font-size: 12px;
                    margin-left: -10px;
                  }

                  .sessionFees {
                    margin-top: 10px;
                  }
                }
              }
            }
          }

          .btnDiv {
            .submitBtn {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .bookSession {
    height: 100%;
    justify-content: center;

    &__innerDiv {
      height: 0vh;
      min-height: 60vh !important;
      margin-bottom: 6%;

      .bookSessionContent {
        .headDiv {
          .backNavDiv {
            display: flex;
            align-items: center;

            .backNavText {
              font-size: 17px;
            }
          }
        }

        .contentDiv {
          padding: 3% 1%;
          .scheduleSession {
            font-size: 18px;
          }

          .datePickerDiv {
            .DatePicker {
              margin: 0 1%;
            }

            .sessionTypeDiv {
              margin: 1% 1%;

              .sessionTypeTitle {
                font-size: 16px;
              }

              .sessionTypeOptions {
                label {
                  font-size: 14px;
                }
              }
            }
          }

          .btnDiv {
            .submitBtn {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 731px) and (max-width: 1024px) {
  .bookSession {
    &__innerDiv {
      width: 100% !important;
      min-height: 75vh !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 730px) {
  .bookSession {
    height: auto;
    overflow-y: auto;
    padding-bottom: 110px !important;

    &__innerDiv {
      height: auto;
      margin: auto;
      padding: 0%;

      .bookSessionContent {
        .headDiv {
          padding: 5%;
          border-top-left-radius: 2px !important;

          .backNavDiv {
            display: flex;
            align-items: center;
            padding-bottom: 20px;

            .backIcon {
              width: 12px;
              height: 12px;
            }

            .backNavText {
              font-size: 14px;
            }
          }
        }

        .contentDiv {
          padding: 6% 1%;
          .scheduleSession {
            font-size: 18px;
          }

          .subInst {
            padding: 0 10px;
          }

          .datePickerDiv {
            flex-direction: column;
            .DatePicker {
              margin: 3% 1%;
            }

            .sessionTypeDiv {
              margin: 3% 1%;

              .sessionTypeTitle {
                height: 40px;
                font-size: 14px;
              }

              .sessionTypeOptions {
                label {
                  font-size: 14px;
                }
              }
            }
          }

          .termsConditions {
            display: flex;
            justify-content: center;
            text-align: center;

            p {
              font-size: 16px;
            }
          }

          .btnDiv {
            .submitBtn {
              width: 180px;
              height: 40px;
              font-size: 16px;
            }
          }
        }
      }
    }
    .bookSessionFooter {
      display: none;
    }
  }
}